let polylines_c = [
    {latlngs: [
            ["40.42235297729705","71.02324490944498"],
            ["40.42156270126404","71.02406018393238"],
            ["40.41891048406828","71.02455957149343"],
            ["40.41761977747137","71.02440772981947"],
            ["40.41383917487693","71.02505337037367"],
            ["40.41122711000983","71.02945416423783"],
            ["40.40632705228047","71.03354927892958"],
            ["40.40632639698082","71.03368276833748"],
            ["40.40285318063806","71.03376668034427"],
            ["40.39994728254381","71.033627008114"],
            ["40.39842808632866","71.03401351171348"],
            ["40.39558050863369","71.03496875889799"],
            ["40.39354401872583","71.03570955702537"],
            ["40.39181247716385","71.03638676227315"],
            ["40.38943618648686","71.03720168496392"],
            ["40.3875800719058","71.03786565112787"],
            ["40.38587126365193","71.03844434053698"],
            ["40.38381498579282","71.03926984256947"],
            ["40.3813421059187","71.04011097153069"],
            ["40.37953145933781","71.0407586786916"],
            ["40.37748548414242","71.04155186086203"],
            ["40.37311867923408","71.04312191298536"],
            ["40.36920450488186","71.04448245865254"],
            ["40.36433315220815","71.04622418892485"],
            ["40.36202415613818","71.04705440823042"],
            ["40.35938204307382","71.04813027594301"],
            ["40.35615881686568","71.04935378345472"],
            ["40.35341756201814","71.0498477345693"],
            ["40.35036456640808","71.05128674354506"],
            ["40.34742741452008","71.05311598796307"],
            ["40.3458441326539","71.05364641547774"],
            ["40.34339093991258","71.05389566269689"],
            ["40.34024479434054","71.05461104926096"],
            ["40.33852720446079","71.05506398076787"],
            ["40.33767667814463","71.05536340229401"],
        ]},
    {latlngs: [
            ["40.47945894018073","72.08993201506388"],
            ["40.48193306597602","72.08936621329204"],
            ["40.48369732810325","72.08911443148797"],
            ["40.48367222069533","72.08859742109611"],
            ["40.48390384672282","72.08856129671301"],
            ["40.48391910114408","72.08873690637274"],
        ]},
    {latlngs: [
            ["40.73227044443708","70.84031420832895"],
            ["40.7073892090832","70.8444058264848"],
            ["40.70507414969663","70.8453296050527"],
            ["40.69970881996826","70.84572876408782"],
            ["40.694776970721","70.84622315399916"],
            ["40.69173838922871","70.84717034197115"],
            ["40.68972659004152","70.84828221208721"],
            ["40.68597680633404","70.84884125112573"],
            ["40.6773056885002","70.84986450895416"],
            ["40.6725866639888","70.85188089655996"],
            ["40.66624648271524","70.85790141080818"],
            ["40.66288151538637","70.86000820634024"],
            ["40.66044534439126","70.85915243757816"],
            ["40.65945396322323","70.85857735494723"],
            ["40.65878424318151","70.85920600351889"],
            ["40.65705997664968","70.85825518394478"],
            ["40.65551345347403","70.85778180318322"],
            ["40.65404998253718","70.85824710915182"],
            ["40.65124356890909","70.85978241999885"],
            ["40.64548396511655","70.86341072640269"],
            ["40.64147344776829","70.86623106620451"],
            ["40.63762510939309","70.86854515726814"],
            ["40.63454981574103","70.87034410393362"],
            ["40.63339013359064","70.87099384179851"],
            ["40.62795963954557","70.87458191301089"],
            ["40.6231351550917","70.8782679223247"],
            ["40.62073459525916","70.87932747478429"],
            ["40.61658671654097","70.88162987255716"],
            ["40.61261935556876","70.88499187830752"],
            ["40.59024258212078","70.89005815500836"],
            ["40.58495124625092","70.89242623378009"],
            ["40.5696842223637","70.89445553382299"],
            ["40.56029087727433","70.89693939570844"],
            ["40.55619380898095","70.89714191517848"],
            ["40.55541036566186","70.89058879420514"],
            ["40.55165337462257","70.88778489304838"],
            ["40.54996241004554","70.8848455898364"],
            ["40.54821122102158","70.87990178597978"],
            ["40.54536917477421","70.87206226234393"],
            ["40.54411274240417","70.86742830775584"],
            ["40.54320275398826","70.86156938932703"],
            ["40.54243468323871","70.8601473622419"],
            ["40.5409866158891","70.8594285116732"],
            ["40.53945690235415","70.85850187336867"],
            ["40.5364590984365","70.85776547298799"],
            ["40.53158234704468","70.85589617439079"],
            ["40.52901793282818","70.85515402323661"],
            ["40.52719592023469","70.85463587564321"],
            ["40.52570860881503","70.85613994402307"],
            ["40.52297103940893","70.8588227796718"],
            ["40.51990125074048","70.86054929075625"],
            ["40.51643277301022","70.86287720787702"],
            ["40.51156607267834","70.86695059412361"],
            ["40.50776311589537","70.86901810945714"],
            ["40.50638423073948","70.87212382320148"],
            ["40.50676659651663","70.87731751211474"],
            ["40.50779591559589","70.88091760446837"],
            ["40.50991975724468","70.88476407808817"],
            ["40.51231360350556","70.88674380242907"],
            ["40.51412644592949","70.89102774853878"],
            ["40.5146901091019","70.8914848770043"],
            ["40.51491065144363","70.89282985302698"],
            ["40.51279579966584","70.89616120904232"],
            ["40.50980609753569","70.90021403501434"],
            ["40.50838416200747","70.90221755595434"],
            ["40.50796679457918","70.90396185557238"],
            ["40.50819932961289","70.90559265467854"],
            ["40.50915596287828","70.9088409899453"],
            ["40.50889100911837","70.91078569975102"],
            ["40.50640996256149","70.91460955540656"],
            ["40.50210829930251","70.91651776435948"],
            ["40.50100575976198","70.91780512970952"],
            ["40.49966282505546","70.92035860723691"],
            ["40.49780187030111","70.92367338844936"],
            ["40.497642817098","70.92451586948454"],
            ["40.49605696607025","70.92868981955925"],
            ["40.49322328630789","70.93289944814802"],
            ["40.49127372909843","70.93587550659754"],
            ["40.49126423336763","70.93585519499223"],
            ["40.48962026454141","70.9391242263155"],
            ["40.49045833995264","70.94443670735104"],
            ["40.49036800704105","70.94533103353399"],
            ["40.48727888506136","70.94814347401574"],
            ["40.48551985123216","70.95031820201444"],
            ["40.48507083177849","70.95231215296575"],
            ["40.48509845980285","70.95365534495011"],
            ["40.48506897985957","70.9554992422139"],
            ["40.48480038224693","70.95615067198435"],
            ["40.48312500138154","70.9571930531077"],
            ["40.48261165806041","70.95764331595535"],
            ["40.48261819766876","70.9590876625427"],
            ["40.48261879199519","70.95916795437097"],
            ["40.48286687494723","70.96055515227302"],
            ["40.48296309095169","70.96207562960744"],
            ["40.48194990059437","70.96797445344031"],
            ["40.48080565852148","70.97092663941351"],
            ["40.4804490026315","70.97236847365494"],
            ["40.47730989802701","70.97385835566979"],
            ["40.47728095663101","70.9738715199157"],
            ["40.47487802652602","70.97712771048626"],
            ["40.47484097539459","70.97712931979297"],
            ["40.47480412193469","70.97713092047887"],
            ["40.47409222552113","70.9777065345943"],
            ["40.47407593617748","70.97770657134319"],
            ["40.47125813940277","70.98076461535537"],
            ["40.46663768871311","70.98677402446064"],
            ["40.46250237315183","70.98873998669829"],
            ["40.45958567066875","70.99098180251836"],
            ["40.45772808778455","70.9926935721504"],
            ["40.4544235993125","70.99570105384674"],
            ["40.45419508665922","70.99731620220861"],
            ["40.45400448045461","70.99996051513006"],
            ["40.45384596394364","71.00223046390676"],
            ["40.45348201523944","71.00297915696561"],
            ["40.45132296933993","71.00419609501536"],
            ["40.45037362317033","71.00439745893493"],
            ["40.4490471883961","71.00382769323697"],
            ["40.44900443181435","71.00380453519695"],
            ["40.44835039484982","71.00281238333554"],
            ["40.44456166748906","71.00125466946434"],
            ["40.44123531757345","71.00151722609624"],
            ["40.43638485528768","71.00545790637545"],
            ["40.43438345188477","71.01071283485"],
            ["40.43382111025398","71.01126836365927"],
            ["40.43275170474495","71.01205557571662"],
            ["40.43157277772648","71.01477630793535"],
            ["40.42951854155778","71.01714792991102"],
            ["40.42947608904721","71.01714878598764"],
            ["40.42884120140411","71.01719801995377"],
            ["40.42877227718279","71.01724761529373"],
            ["40.42719019851742","71.0182768061605"],
            ["40.42418643242749","71.01937651249222"],
            ["40.4231552938533","71.01962964921877"],
            ["40.42247544267135","71.02154011089017"],
            ["40.42236024678823","71.02329924255119"],
        ]},
    {latlngs: [
            ["40.42449145019106","72.00408508867326"],
            ["40.42537797330584","72.00241434963182"],
        ]},
    {latlngs: [
            ["40.38821541299276","71.88694995149872"],
            ["40.38819861662752","71.88696264906643"],
            ["40.38874664138672","71.88624540243786"],
            ["40.39066354252019","71.88274449446823"],
            ["40.39622289052327","71.87672616895222"],
            ["40.40122712404628","71.87144287367327"],
            ["40.40667798484844","71.86574908940312"],
            ["40.40981595383786","71.86277210057112"],
            ["40.41449774047889","71.85826301443036"],
            ["40.41715641688752","71.85538012531772"],
            ["40.42210906320698","71.85069466000935"],
            ["40.42846893004906","71.84463689859339"],
            ["40.43236739423936","71.8424844119095"],
            ["40.43778751047707","71.83927686941122"],
            ["40.44480756583899","71.83524948859503"],
            ["40.44800862673456","71.83409710872114"],
            ["40.45189884722031","71.83373741614905"],
            ["40.45706036276829","71.83416301538274"],
            ["40.45844872264026","71.83449668969777"],
            ["40.45907796762285","71.83520239137422"],
        ]},
    {latlngs: [
            ["40.3880580700051","71.88661719486042"],
            ["40.38370627150072","71.89025486675347"],
            ["40.38190578882725","71.89216374125036"],
            ["40.37947997686955","71.89460071881338"],
            ["40.37773453350496","71.89695375865341"],
            ["40.37473491825949","71.89997940193197"],
            ["40.37140791994029","71.90365986467243"],
            ["40.36718411032263","71.90899162981478"],
            ["40.36524438884116","71.91111892709823"],
            ["40.36239592578621","71.91393718860041"],
            ["40.35962183722938","71.91660555673396"],
            ["40.35746377794313","71.91854961084931"],
            ["40.35661789443892","71.91990549313643"],
            ["40.3557703045744","71.92097818710252"],
            ["40.34974816893792","71.92810179124844"],
            ["40.34680095241867","71.93778140694641"],
            ["40.3455415810864","71.94187872092668"],
            ["40.3432480892483","71.94502663355067"],
            ["40.33829207663779","71.94833420371039"],
            ["40.33292449290859","71.94751453348178"],
            ["40.32582668310156","71.94676782447148"],
            ["40.32286730536641","71.9485109576912"],
            ["40.31810015092354","71.95176029033546"],
            ["40.31540411527845","71.95298633564718"],
            ["40.31236602739698","71.95673079437645"],
            ["40.30946793418566","71.96251432227177"],
            ["40.30943761519289","71.96310371835396"],
            ["40.30994871261879","71.96424294629323"],
            ["40.31021904088644","71.96503217230351"],
            ["40.31028018241987","71.96573727137367"],
            ["40.31098030429367","71.96608825861374"],
            ["40.31256182486812","71.96634220435975"],
            ["40.31304685572683","71.96657076209242"],
            ["40.31400099880347","71.96580824201703"],
            ["40.31436947929755","71.96598476423972"],
        ]},
    {latlngs: [
            ["40.31534607552656","71.9530409756445"],
            ["40.31385070518566","71.95404194422888"],
            ["40.31237267165279","71.95428965652093"],
            ["40.31038330558904","71.95338685881318"],
            ["40.30703807469241","71.95272183787974"],
            ["40.30519191896776","71.95251136615845"],
            ["40.30225939878748","71.95240658884025"],
            ["40.30069340674606","71.95272075380331"],
            ["40.29975162616036","71.95283264730236"],
            ["40.29847500004414","71.95351384807093"],
            ["40.29779392900446","71.95365467943471"],
            ["40.29733925002115","71.95389405476271"],
            ["40.29607828042301","71.95408541537635"],
            ["40.29528250584595","71.95410865119217"],
            ["40.29484507540835","71.95437397521006"],
            ["40.29434369752208","71.95519653570294"],
            ["40.29368037387203","71.95614634904841"],
            ["40.29310144012815","71.95709149898595"],
            ["40.29298569762684","71.95783322558697"],
            ["40.29248060301144","71.95828656381325"],
            ["40.29200684866885","71.95896180198042"],
            ["40.29168577529642","71.9595306966241"],
            ["40.29158711782388","71.95989649733086"],
            ["40.29142446311754","71.96013625307245"],
            ["40.2912431349935","71.9604786424504"],
            ["40.29109910558939","71.96066374069282"],
            ["40.29042445359735","71.96147180367078"],
            ["40.29027510890648","71.96168616737573"],
            ["40.29001318615836","71.96229900502983"],
            ["40.28967974518009","71.96272996522393"],
            ["40.28869528657589","71.96336150280251"],
            ["40.28769737223406","71.96409819024819"],
            ["40.28746435363286","71.96486818410769"],
            ["40.28735788539892","71.96591339124595"],
            ["40.28739631222748","71.96643556666426"],
            ["40.2874336791621","71.96698416705709"],
            ["40.28742966452285","71.96699924925856"],
            ["40.2876192531478","71.96767860208463"],
            ["40.28805443377369","71.96843053359768"],
            ["40.28828207350409","71.96952876727569"],
            ["40.28831334615853","71.97010786539023"],
            ["40.28831338299605","71.97011923375018"],
            ["40.28825977373199","71.97087703369317"],
            ["40.28841138288406","71.97201644651105"],
            ["40.28851265673868","71.97211799401684"],
        ]},
    {latlngs: [
            ["40.38072048232556","71.87477034767369"],
            ["40.38051968013531","71.87575119332661"],
            ["40.38092167077546","71.87645768781894"],
            ["40.38127245348638","71.87705746533433"],
            ["40.38233947615372","71.88020347241036"],
            ["40.38150214750024","71.8829614942389"],
            ["40.3806494182347","71.88598405500241"],
            ["40.37944031285757","71.8890607886328"],
            ["40.3774353909669","71.89255461751235"],
            ["40.37613117068464","71.89388394950875"],
            ["40.37491253311572","71.89609984829529"],
            ["40.37271213850868","71.89983672797283"],
            ["40.37108942936975","71.9019648439136"],
            ["40.37012179689131","71.90420342754837"],
            ["40.36965190667453","71.90584418785187"],
        ]},
    {latlngs: [
            ["40.38071866334755","71.87475379217703"],
            ["40.38272646592409","71.87381300279567"],
        ]},
    {latlngs: [
            ["40.34896062615778","71.83758887223271"],
            ["40.34667833474198","71.83832991283012"],
        ]},
    {latlngs: [
            ["40.33096383113162","71.7790773542275"],
            ["40.33266491439694","71.77959498963205"],
        ]},
    {latlngs: [
            ["40.33319602052585","71.76430573353191"],
            ["40.32939337016677","71.76283370934642"],
            ["40.31860214689439","71.75817663921821"],
            ["40.31061051146397","71.75460992053019"],
            ["40.29721023888971","71.74894972704944"],
            ["40.28639395726552","71.74428839795752"],
            ["40.28411714414524","71.74378954009507"],
            ["40.28099718129808","71.74318711113307"],
            ["40.27471259988541","71.74130916497077"],
            ["40.27094872147526","71.73971370113577"],
            ["40.26838895378311","71.73846322602394"],
            ["40.26566775909674","71.73756520934796"],
            ["40.25837090144277","71.73466930542214"],
            ["40.24769353652498","71.72622781480617"],
            ["40.24466717814414","71.7257610815517"],
            ["40.24215992602875","71.7248477662222"],
            ["40.23780383671099","71.72199502061042"],
            ["40.23535463538881","71.72033844779392"],
            ["40.23361267524381","71.71889547825316"],
            ["40.22362924516187","71.71086813862289"],
            ["40.21657689093854","71.70729203174581"],
            ["40.20953553545446","71.70525052573758"],
            ["40.20729534761179","71.70518594881227"],
            ["40.20542652164281","71.70546778933407"],
            ["40.20430548512647","71.70563663467641"],
            ["40.20370094809527","71.70576243719331"],
            ["40.2032064575973","71.70588791890134"],
            ["40.20283475086421","71.70596746104427"],
            ["40.20195678761913","71.70613747221751"],
            ["40.20121031371753","71.70625469222159"],
            ["40.20026558960152","71.70682775982486"],
            ["40.19950436237217","71.70728107535304"],
            ["40.19816933479396","71.70809178492587"],
            ["40.19815131420998","71.70809640534399"],
            ["40.19609816623328","71.70799595355827"],
            ["40.19499478786029","71.70738443527671"],
            ["40.19425838073042","71.70709362120317"],
            ["40.19335961498327","71.70692699710463"],
            ["40.19299032093514","71.70699004443188"],
            ["40.19196222617652","71.70704768776014"],
            ["40.19052370267448","71.70774782657971"],
            ["40.1901341294538","71.70789798924253"],
            ["40.18918909870185","71.70769931070569"],
            ["40.18917910214888","71.70770499747444"],
            ["40.18866937273598","71.70758114964634"],
            ["40.18808389496276","71.70760952254736"],
            ["40.18745998282648","71.70782267560672"],
            ["40.18740284038828","71.70783130614005"],
            ["40.18738022246933","71.70783472225254"],
            ["40.1869752694943","71.70803449944547"],
            ["40.18696004220944","71.7080365590385"],
            ["40.18590708180248","71.70850785057074"],
            ["40.18500396398984","71.70891301931186"],
            ["40.18466975390609","71.70894222418936"],
            ["40.18289496621553","71.70896640727058"],
            ["40.18232820904723","71.70889306359567"],
            ["40.1816946589403","71.7090467298561"],
            ["40.18130343966299","71.70911702273834"],
            ["40.18125076140219","71.7091197389114"],
            ["40.18075386202308","71.70911315755426"],
            ["40.18014199149603","71.7091912334843"],
            ["40.18010726437137","71.7091929573721"],
            ["40.17989036583231","71.7092188773335"],
        ]},
    {latlngs: [
            ["40.18817111673312","71.7069168500784"],
            ["40.18805991107684","71.70761372832892"],
        ]},
    {latlngs: [
            ["40.22893906094001","71.73689516602552"],
            ["40.22952921809512","71.73541848705456"],
            ["40.23008659060363","71.7340186691862"],
            ["40.23133018547955","71.73072141073027"],
            ["40.23191186600159","71.72920564293429"],
            ["40.23233837380086","71.72816720512661"],
            ["40.23287315001821","71.72681004429063"],
            ["40.23373333970361","71.72450415985652"],
            ["40.23418074949238","71.72327730565632"],
            ["40.23446033077835","71.72260016587138"],
            ["40.23458295175699","71.72225659317884"],
            ["40.23480338234851","71.72173623131422"],
            ["40.23538526338333","71.72035646407734"],
        ]},
    {latlngs: [
            ["40.31080269864899","71.75469579255872"],
        ]},
    {latlngs: [
            ["40.33027496736241","71.7631767452831"],
            ["40.32970912738244","71.7654900250979"],
        ]},
    {latlngs: [
            ["40.33734743262706","71.67172270769034"],
            ["40.33825544667222","71.67181460906943"],
            ["40.33902050097511","71.67181838863627"],
            ["40.34022644589127","71.67213894586293"],
            ["40.34128594162932","71.67245959940135"],
            ["40.34296313901957","71.67286742817072"],
            ["40.34457414932721","71.67317040160185"],
            ["40.34580354911009","71.67334318573417"],
            ["40.34854498318465","71.67393280607152"],
            ["40.35009175879913","71.67421478681412"],
            ["40.35185779261398","71.67465296919049"],
            ["40.35327980234842","71.67480698291968"],
            ["40.35971061861306","71.67679719560618"],
            ["40.36495129969308","71.67805820021388"],
            ["40.37016228388431","71.67807777199553"],
            ["40.37613687687038","71.67992378606742"],
            ["40.38418590517409","71.68082709756642"],
            ["40.38725277282787","71.68093894865763"],
            ["40.38914018318038","71.68117825591297"],
            ["40.39993654868225","71.6888480146184"],
            ["40.40085578672813","71.68935490424089"],
            ["40.40359441350222","71.69055790619782"],
            ["40.40549902781843","71.69124145743154"],
            ["40.40610106335208","71.69120534124301"],
            ["40.40825115832757","71.69096336539607"],
            ["40.40959397479048","71.69072014003142"],
            ["40.4109144071348","71.69015451775954"],
            ["40.41185085713126","71.69014376311405"],
            ["40.4136572857571","71.68942472322735"],
            ["40.41372251342738","71.68960164874466"],
            ["40.41371271696048","71.68976783284155"],
        ]},
    {latlngs: [
            ["40.31430269734541","71.52812418276456"],
            ["40.3147675888285","71.52759568475706"],
            ["40.31504136272081","71.52724858577822"],
            ["40.31620346989984","71.52580575955108"],
            ["40.31702698459866","71.5241599283673"],
            ["40.31775496453428","71.52317162064833"],
            ["40.31861177479682","71.52277517452704"],
            ["40.32014947646814","71.52199927619967"],
            ["40.32091490523833","71.52135491659241"],
            ["40.32236224282551","71.52046324020361"],
            ["40.32323584062239","71.51970394624225"],
            ["40.32422143934119","71.51853315467024"],
            ["40.33414243256355","71.50806200181201"],
            ["40.33633553024692","71.50528685799887"],
            ["40.33731000024118","71.50520585057718"],
            ["40.33861552968984","71.50545726585014"],
            ["40.340709308683","71.50513199980099"],
            ["40.3432611150301","71.50564159851241"],
            ["40.34745155570062","71.50785951625234"],
            ["40.35081688603465","71.50953235276923"],
            ["40.35338062269194","71.51114337985155"],
            ["40.35638236317251","71.51245188711361"],
            ["40.35888799381149","71.51286360698624"],
            ["40.36154027813371","71.51203443983769"],
            ["40.36341617413516","71.51203510527972"],
            ["40.36443263598877","71.51214285518829"],
            ["40.36637938148041","71.51201044193206"],
            ["40.36691440503495","71.5120064347548"],
            ["40.36764185201724","71.51238332280948"],
            ["40.36922763987192","71.51296227682461"],
            ["40.37052687164206","71.51350738099229"],
            ["40.37207475537987","71.51399569329675"],
            ["40.37333321387099","71.51445564705784"],
            ["40.37533990485878","71.51495001213472"],
            ["40.37678326030038","71.51530736650638"],
            ["40.37876251670448","71.51595539661243"],
            ["40.38057220231271","71.51655976956057"],
            ["40.38135218465794","71.51681161244012"],
            ["40.38294264282948","71.51747472823078"],
            ["40.38565058479701","71.51879717762689"],
            ["40.38660029481751","71.51914849247279"],
            ["40.38777165838952","71.51914743980977"],
            ["40.38940159355197","71.51908124030196"],
            ["40.39209696956762","71.51911956441208"],
            ["40.39591888322931","71.5194966363447"],
            ["40.40247418967743","71.52022025106156"],
            ["40.40641785133912","71.52079502051544"],
            ["40.41134834343694","71.52082350714907"],
            ["40.41575527022595","71.52109414475811"],
            ["40.4180659007712","71.52092353599299"],
            ["40.41962434121879","71.52186815288185"],
        ]},
    {latlngs: [
            ["40.38582456753299","71.51954032696452"],
            ["40.38587404234046","71.51890251835562"],
        ]},
    {latlngs: [
            ["40.30812453281378","71.49687389790168"],
            ["40.30987996676333","71.49532736565682"],
            ["40.31067843641072","71.49469509627846"],
            ["40.31086695901694","71.49463478702081"],
            ["40.31187308976195","71.49473080035476"],
            ["40.31317625597374","71.49476544726808"],
            ["40.3147494751796","71.49356747190879"],
            ["40.3154203250164","71.49289638454576"],
            ["40.31644915969066","71.49218240018027"],
            ["40.3169257235009","71.49229321779734"],
            ["40.31786802124","71.49298338683664"],
            ["40.31876905030229","71.49302460330145"],
            ["40.31999588363312","71.49258514239283"],
            ["40.32263219384855","71.49122401072052"],
            ["40.32287250043592","71.49117147498814"],
            ["40.32398379436711","71.49136879364843"],
            ["40.3249069608591","71.4916543027859"],
            ["40.32561774460674","71.49179768287654"],
            ["40.32597176747789","71.49174301090738"],
            ["40.32695904489321","71.49114263720821"],
            ["40.32731802200332","71.49091196765019"],
            ["40.32813682495024","71.49036435037064"],
            ["40.32954121899942","71.48887371863643"],
            ["40.33094758747849","71.48779086099961"],
            ["40.33220180112708","71.48726009382632"],
            ["40.33344297392696","71.48699295235772"],
            ["40.33659030107488","71.48655615918864"],
            ["40.34604097750258","71.48576616275275"],
            ["40.35200325820187","71.48475815318839"],
        ]},
    {latlngs: [
            ["40.30603264485488","71.49028041448092"],
            ["40.30416652839308","71.4911645315608"],
            ["40.29827039179013","71.49310392871527"],
            ["40.29629246195665","71.49391388032853"],
            ["40.29465757981452","71.4938093974545"],
            ["40.29224723397585","71.49292850558251"],
            ["40.29126785944074","71.49325188846782"],
            ["40.29016596451621","71.49604577991346"],
            ["40.28740340973842","71.49978351245902"],
            ["40.28700316444164","71.50044536100692"],
            ["40.28637960668175","71.50079505978731"],
            ["40.28584046050605","71.50124911599026"],
            ["40.28383763647507","71.5021450303435"],
            ["40.28221915238773","71.50304211402225"],
            ["40.28158486536273","71.50360034293041"],
            ["40.28156200606725","71.50364163934339"],
            ["40.28096083963556","71.50416484941107"],
            ["40.28094022340527","71.50419245585717"],
            ["40.28001208120472","71.50541731071058"],
            ["40.2799857517486","71.50542314639765"],
            ["40.27913465922794","71.50598460852704"],
            ["40.2784915898103","71.50708065669498"],
            ["40.27790376316564","71.50809990419963"],
            ["40.27736207121102","71.50917513956571"],
            ["40.27704259230269","71.51033152708089"],
            ["40.2767795829317","71.51134633418387"],
            ["40.27596512663966","71.51306874590379"],
            ["40.2751617971715","71.5141149110696"],
            ["40.27473738216983","71.51446880683649"],
            ["40.27428794045257","71.51532913702523"],
            ["40.27397435692551","71.51602042388868"],
            ["40.27365253364069","71.51805267595206"],
            ["40.27396676016939","71.51890893455538"],
            ["40.27451197449271","71.52143944699496"],
            ["40.27470038452195","71.52287611130268"],
            ["40.27507141775097","71.52443399556275"],
        ]},
    {latlngs: [
            ["40.39243167729148","71.24953224246802"],
            ["40.39441629431057","71.2493492548316"],
        ]},
    {latlngs: [
            ["40.33587616636792","71.12454226817614"],
            ["40.33591955694443","71.12482739018321"],
            ["40.33840284775756","71.12490183242248"],
            ["40.33855125965897","71.12622341255573"],
        ]},
    {latlngs: [
            ["40.33183161664834","70.76229668272916"],
            ["40.32978851660633","70.7613381723847"],
        ]},
    {latlngs: [
            ["40.33178022869554","70.76243177767807"],
            ["40.32948580851661","70.76140746000806"],
            ["40.32832797553289","70.76094141189431"],
            ["40.32586351442318","70.75963522869498"],
            ["40.32377692746947","70.75899122718563"],
            ["40.32171113907265","70.75882347076195"],
            ["40.32043703892284","70.75870163654149"],
            ["40.31932723102035","70.75867941384347"],
            ["40.31857827926349","70.75869227522878"],
            ["40.31717581104181","70.75908852440577"],
            ["40.31602972738739","70.75980632586528"],
            ["40.31513033165116","70.76038953043164"],
            ["40.31423776916576","70.76082624165453"],
            ["40.31318782687671","70.76112715739991"],
            ["40.31231306334772","70.76153496477544"],
            ["40.31176262412767","70.76192982125917"],
            ["40.31122685703951","70.76227853205344"],
            ["40.31074339830528","70.762662140125"],
            ["40.3102210708928","70.76300611124752"],
            ["40.30971432129685","70.76337063449381"],
            ["40.30918209347345","70.76359906288312"],
            ["40.30841946236804","70.7639229223393"],
            ["40.30800871623919","70.76405109826202"],
            ["40.30746593577243","70.7637648687904"],
            ["40.30666550920719","70.76312058559081"],
            ["40.30616881011814","70.76259188186495"],
            ["40.30546327244257","70.76202384395243"],
            ["40.30493190694769","70.76165336488323"],
            ["40.30436618713735","70.761175724455"],
            ["40.30390682212518","70.76081876890765"],
            ["40.30352772498842","70.76080290676559"],
            ["40.30326857543347","70.76095590234799"],
            ["40.30279148078202","70.76111569032051"],
            ["40.30209772918786","70.76138997004378"],
            ["40.30151661942259","70.76155053994808"],
            ["40.30107119610331","70.76169189032787"],
            ["40.30008880779791","70.76199644250075"],
            ["40.29933779015459","70.76221164836763"],
            ["40.29876922437414","70.76236570984176"],
            ["40.29807606229934","70.76258543627779"],
            ["40.29754679272089","70.7627404094607"],
            ["40.29702852427504","70.76289438576715"],
            ["40.29649300673498","70.76302545255554"],
            ["40.29585292224542","70.76319949437966"],
            ["40.29541471348059","70.76337033412307"],
            ["40.29503811776517","70.76350965881655"],
            ["40.29477567069389","70.76357523646055"],
            ["40.29430217797638","70.76365566593518"],
            ["40.2940008490838","70.76371081602538"],
            ["40.29371244122782","70.76373027160879"],
            ["40.29336787736923","70.76374564900172"],
            ["40.29294474021456","70.7637811757285"],
            ["40.29249353829803","70.76380788176675"],
            ["40.29210496443393","70.76383516673336"],
            ["40.29195623400609","70.76384109793983"],
            ["40.29174614035836","70.76380195306906"],
            ["40.29160041535599","70.76381810533773"],
            ["40.29152417391934","70.7638904995783"],
            ["40.2913746792884","70.76405413613139"],
            ["40.29122698774279","70.76421656634976"],
            ["40.29109656850844","70.76439407262413"],
            ["40.29094274053936","70.76463718156641"],
            ["40.29081215131419","70.76516743053176"],
            ["40.29073689844713","70.76559958199356"],
            ["40.29052169119802","70.76630074105663"],
            ["40.29029295521169","70.76690140750505"],
            ["40.29020939655408","70.76720278220422"],
            ["40.28974635466041","70.76791047317549"],
            ["40.28931818166814","70.76880536679786"],
            ["40.28858873811021","70.77047279140193"],
            ["40.28744349418842","70.77351578196674"],
            ["40.2861423396088","70.77630220228858"],
            ["40.2848508673309","70.77900150980388"],
            ["40.28434230735965","70.78144465627562"],
            ["40.28452000959788","70.78455613197734"],
            ["40.28538240203947","70.78639708161975"],
            ["40.28557269681554","70.78803681540123"],
            ["40.28580629611377","70.78935012959674"],
        ]},
    {latlngs: [
            ["40.32528256636464","70.58862507641359"],
            ["40.32620068122365","70.58803190624789"],
            ["40.32768712362194","70.58729618258714"],
            ["40.32943794578565","70.58532115663637"],
            ["40.33151034665228","70.58295527847521"],
            ["40.33314386824651","70.58136242072216"],
            ["40.33522935401181","70.58008761151936"],
            ["40.33698310381973","70.57927510310358"],
            ["40.33839642560293","70.57845157720189"],
            ["40.33987434212256","70.57633342707592"],
            ["40.34032154353189","70.57554656452599"],
            ["40.34096524991375","70.57523365030576"],
            ["40.34175940964305","70.57580989130726"],
            ["40.34236976733084","70.5762504950078"],
            ["40.34313350051279","70.57686103991728"],
            ["40.34469547002872","70.57810780869107"],
            ["40.34668793793588","70.57967636445623"],
            ["40.34830568606068","70.5810119387346"],
            ["40.34968109751738","70.58204644859214"],
            ["40.3511013694913","70.5831992546694"],
            ["40.35263032155039","70.58442719235961"],
            ["40.3541448022815","70.58563983352617"],
            ["40.35609034995566","70.5872278721593"],
            ["40.35759087758227","70.58845840070256"],
            ["40.35944581868149","70.58995532908989"],
            ["40.36091532162243","70.59111171800157"],
            ["40.36229916540071","70.59215079543733"],
            ["40.36392001930781","70.59350025775899"],
            ["40.36629605895651","70.59541624889711"],
            ["40.3683442005005","70.59704263514865"],
            ["40.37028239616922","70.59858126507532"],
            ["40.37173221364119","70.59974797243916"],
            ["40.37305936499858","70.60071482561284"],
            ["40.37459949454689","70.60197043868951"],
            ["40.3760057009324","70.60306563733039"],
            ["40.37675357961987","70.60370975891698"],
            ["40.37722735068957","70.60407680105415"],
            ["40.3777697550919","70.60335868161165"],
            ["40.37946621154717","70.60147103017306"],
            ["40.38293898328232","70.59958388762952"],
            ["40.38479415005988","70.5999945618494"],
            ["40.38614819967754","70.60061806876065"],
            ["40.38773746949747","70.60102562602027"],
            ["40.38991599245861","70.60108215067551"],
            ["40.3928688811823","70.60112092074193"],
            ["40.39563969784933","70.60105414036161"],
            ["40.39809123106864","70.60096206162179"],
            ["40.3984470143338","70.60090062815999"],
            ["40.39949007267722","70.60230655785368"],
        ]},
    {latlngs: [
            ["40.3333309905849","70.69674804604092"],
            ["40.33233406260206","70.69690603991513"],
            ["40.33164841764972","70.69701679563816"],
            ["40.33092489484511","70.69709761738376"],
            ["40.33022109060886","70.69720350310384"],
        ]},
    {latlngs: [
            ["40.32924036410692","70.81543867608187"],
            ["40.3290355075377","70.81856171536684"],
            ["40.32880690385024","70.82112280678072"],
            ["40.32836092002762","70.82441076518978"],
            ["40.3280577076463","70.82631181633187"],
        ]},
    {latlngs: [
            ["40.32881100084773","70.821158266918"],
            ["40.32967424775113","70.82116942153429"],
            ["40.33008494347861","70.82119742963749"],
            ["40.33013925080028","70.82101484688317"],
        ]},
    {latlngs: [
            ["40.31369161782922","71.05323186305357"],
            ["40.31376533220881","71.05283221202194"],
            ["40.31384417441134","71.05217494221462"],
            ["40.31390799908106","71.05161953671019"],
            ["40.313922843938","71.05127124954896"],
            ["40.31394870842536","71.0508918300827"],
            ["40.31445984342113","71.05091612039305"],
            ["40.31502573690307","71.05097742576503"],
            ["40.31747049010777","71.05113121109767"],
            ["40.31923450035524","71.05128164085252"],
            ["40.32237710781071","71.0513973829493"],
            ["40.325143840153","71.05148159726821"],
            ["40.32786300460123","71.05149712765947"],
            ["40.33093239848331","71.05170662126187"],
            ["40.33292966283013","71.05179689867251"],
            ["40.33390777250234","71.05186258790519"],
            ["40.33483268890473","71.05190084570225"],
            ["40.33565771041731","71.05168204155865"],
        ]},
    {latlngs: [
            ["40.39010846178042","71.09897792279924"],
            ["40.38915617020514","71.09764800191238"],
            ["40.3881554241937","71.09613249973238"],
            ["40.38686647812431","71.09403827450537"],
            ["40.38600762195368","71.09271499538629"],
            ["40.38563302158153","71.09204891258463"],
            ["40.38391493680016","71.08873176348419"],
            ["40.38188583150271","71.08556304212021"],
            ["40.37999492080338","71.08184761371639"],
            ["40.37774228486543","71.07674727941125"],
            ["40.37498649556775","71.07130600704215"],
            ["40.37164678110176","71.06754421150183"],
            ["40.36848121541873","71.06439209806058"],
            ["40.36771941212486","71.06377938918861"],
            ["40.36773827379663","71.0614973672872"],
            ["40.3650310237579","71.05885618757922"],
            ["40.3632310068992","71.05694985638542"],
            ["40.3605564435596","71.05431572639135"],
            ["40.35822207466084","71.05214746153946"],
            ["40.35576167563729","71.0497022109422"],
            ["40.35572809297953","71.04945179252276"],
        ]},
    {latlngs: [
            ["40.43220670425274","71.01330244159278"],
            ["40.43398785256084","71.01430852658284"],
            ["40.43386163925736","71.01569353366472"],
        ]},
    {latlngs: [
            ["40.48496311114417","70.95577018191176"],
            ["40.48552058938895","70.9574446207283"],
            ["40.48604471956623","70.95780075828065"],
            ["40.48686237159834","70.95775326476945"],
            ["40.48677726942044","70.95740267247166"],
        ]},
    {latlngs: [
            ["40.49966572369696","70.9203458502204"],
            ["40.50054968109928","70.92073408415146"],
        ]},
    {latlngs: [
            ["40.51468825592541","70.89149738727214"],
            ["40.51548239294647","70.89067016948997"],
            ["40.51545355193575","70.88981129841156"],
            ["40.51627166749679","70.88887701009592"],
            ["40.51730551075094","70.88797835925392"],
            ["40.51706477333823","70.88716474858768"],
        ]},
    {latlngs: [
            ["40.51688778736929","70.84929309375407"],
            ["40.51741374024124","70.85087109713442"],
            ["40.51786234381832","70.85436695127245"],
            ["40.518340701177","70.85573025746855"],
            ["40.51877515886299","70.85681327691947"],
            ["40.51929850639259","70.85804819963076"],
            ["40.51979663758187","70.85967845805158"],
            ["40.52000201879367","70.86048925891683"],
        ]},
    {latlngs: [
            ["40.57273813544058","70.89405713026613"],
            ["40.57325625276458","70.89567382666748"],
            ["40.573805252652","70.89722009684232"],
            ["40.57472883358624","70.89953539279543"],
            ["40.57521914654043","70.90128781472771"],
            ["40.57593172138778","70.90306616518031"],
            ["40.5760578203406","70.90332629654779"],
            ["40.57519458054787","70.90393873190062"],
            ["40.57445899566405","70.90441723876943"],
            ["40.57382924373742","70.90469260676154"],
        ]},
    {latlngs: [
            ["40.55620996821553","70.89713964650943"],
            ["40.55506785088625","70.89875695378652"],
            ["40.55392617989764","70.89925344400429"],
        ]},
    {latlngs: [
            ["40.59020435475214","70.8900953804841"],
            ["40.59147926154723","70.89155299298548"],
            ["40.59292430496961","70.89363422955397"],
            ["40.59315066564946","70.89404072329997"],
            ["40.59270869621024","70.89464077125709"],
            ["40.59287459520523","70.89522546852847"],
            ["40.59324490487647","70.896080276608"],
        ]},
    {latlngs: [
            ["40.64728050434248","70.86228443412747"],
            ["40.64830429994217","70.86483224581765"],
            ["40.64918339025531","70.86655519812115"],
            ["40.64978488487866","70.86791218711342"],
            ["40.65028670688336","70.86887393971689"],
            ["40.64999624725295","70.86939683329717"],
        ]},
    {latlngs: [
            ["40.70552442068333","70.86147256194485"],
            ["40.70635810710528","70.86029445357485"],
            ["40.70645788746645","70.85882612915645"],
            ["40.70678089173238","70.85807425634238"],
            ["40.70690508892444","70.85586098496388"],
            ["40.70751210687708","70.85171035055457"],
            ["40.70731014336652","70.84931723832581"],
            ["40.70751640161299","70.84446546206232"],
        ]},
    {latlngs: [
            ["40.394078562446","71.51931924731359"],
            ["40.39558910152452","71.51923797476216"],
            ["40.39697138609328","71.51919685046401"],
            ["40.39960470891203","71.51941030646104"],
            ["40.40345091419983","71.51929320830376"],
            ["40.40670792301665","71.51946832673282"],
            ["40.41001687944215","71.51956940134605"],
            ["40.41347615363471","71.51925524670972"],
            ["40.41813273377971","71.52097306339094"],
        ]},
    {latlngs: [
            ["40.3252454084458","71.58955605921591"],
            ["40.32437071835653","71.58971812270671"],
            ["40.32437047091342","71.58972405564231"],
            ["40.32342556968393","71.58980572184699"],
            ["40.32272765243945","71.58988872937306"],
            ["40.32178776466028","71.59003071363625"],
            ["40.31916922474719","71.59054125307988"],
            ["40.31829848469359","71.59078029725403"],
            ["40.31661054664723","71.59100567760876"],
            ["40.31518380438464","71.59125057907038"],
            ["40.31404352186148","71.59139809358963"],
            ["40.31285105948295","71.59122434216357"],
            ["40.31086960022461","71.59091370365581"],
            ["40.30971662456813","71.59067048743667"],
            ["40.3077052151933","71.59006357275928"],
            ["40.30677011793015","71.58982444470045"],
            ["40.30230865611567","71.58880674020008"],
            ["40.3008188133023","71.58929198388778"],
        ]},
    {latlngs: [
            ["40.31999423438897","71.75877607905622"],
            ["40.31982140421505","71.75912992414777"],
            ["40.31949478151048","71.75979470546471"],
            ["40.31901294954147","71.76072119760299"],
            ["40.31883825745145","71.76165667926819"],
            ["40.3187533572599","71.76252386623349"],
            ["40.31874874780078","71.7625526602866"],
            ["40.31853792649086","71.76393974856488"],
        ]},
    {latlngs: [
            ["40.28865438103378","71.96337974318364"],
            ["40.28770746651487","71.96367782769957"],
            ["40.28609261592849","71.96455675160489"],
            ["40.28466196810089","71.96528925282453"],
            ["40.28344460704553","71.96598436288021"],
            ["40.28235008303886","71.96616838784549"],
            ["40.28159707837861","71.96586450722562"],
            ["40.2811423513178","71.96467904770665"],
            ["40.28095127900699","71.96425360667168"],
        ]},
    {latlngs: [
            ["40.42458379277012","72.00416182797333"],
            ["40.42575321513954","72.00488229880537"],
            ["40.43057009721233","72.00886884221381"],
            ["40.43242433095453","72.01115609209677"],
            ["40.4340346156561","72.01431201842246"],
            ["40.43464102607204","72.0184938900652"],
            ["40.43624742893601","72.02320289201464"],
            ["40.43674278068583","72.02604997715211"],
            ["40.43756772600916","72.02770297446155"],
        ]},
    {latlngs: [
            ["40.47675285287149","72.08354397966254"],
            ["40.47691319811617","72.08273428929076"],
            ["40.47730097540064","72.08148302414259"],
            ["40.47754842552364","72.08000387716456"],
            ["40.47693320847768","72.0775709258314"],
            ["40.47619590169003","72.07693918093376"],
            ["40.47454301256884","72.07609436612636"],
            ["40.47289085879773","72.07478752839215"],
            ["40.47062486049684","72.07309946282834"],
            ["40.46927301687315","72.07174982892546"],
            ["40.46751066668385","72.0702220055383"],
            ["40.46595643625546","72.06864368436925"],
            ["40.46489198809076","72.06748670543013"],
            ["40.46422370175667","72.06588902386234"],
            ["40.46328293458276","72.06363975050675"],
            ["40.46277137309196","72.06218200336005"],
            ["40.46254782410206","72.06068743092908"],
            ["40.46239183057806","72.05884677843726"],
            ["40.46225603659951","72.05799419352732"],
            ["40.46150656706491","72.05732334097031"],
            ["40.45995862834993","72.0561379237579"],
            ["40.45967368830312","72.05582764682291"],
        ]},
    {latlngs: [
            ["40.50898368558917","72.14237034030549"],
            ["40.51064730597601","72.14253704746407"],
            ["40.51220096249046","72.14274004385226"],
            ["40.51486608221694","72.14374190387161"],
            ["40.51723161338611","72.1443404067859"],
            ["40.51860354398266","72.14516298395267"],
            ["40.52033627158733","72.14669994006539"],
            ["40.52225514018446","72.14700267974419"],
            ["40.52464045242625","72.14923263801947"],
        ]},
    {latlngs: [
            ["40.5742956873053","72.19404616327941"],
            ["40.57524904951438","72.1916892245666"],
            ["40.57617903734682","72.18953705136812"],
            ["40.57603816756441","72.18891927985422"],
        ]},
    {latlngs: [
            ["40.33768347747958","71.055360801257"],
            ["40.33693628340119","71.0554917049094"],
            ["40.33650043665585","71.05374725503145"],
            ["40.33584462754072","71.05208988694154"],
            ["40.33407990400666","71.04781557271872"],
            ["40.33278487444058","71.04487483541372"],
            ["40.32949285480465","71.03729894937646"],
            ["40.32643730945646","71.03155486182568"],
            ["40.32466425699855","71.02375270700324"],
            ["40.3230842276201","71.016700838281"],
            ["40.32313774798706","71.01450450375467"],
            ["40.32333934923151","71.0133074700193"],
            ["40.32462361342456","71.01077499124183"],
            ["40.32871799392279","71.00527913441721"],
            ["40.32797382094435","71.00233123371687"],
            ["40.32780268626506","71.00038287611078"],
            ["40.32777637460834","70.99862788639193"],
            ["40.32785179758041","70.99627912112106"],
            ["40.32782550599136","70.99505098167469"],
            ["40.32582529746784","70.98945787876443"],
            ["40.32533835832342","70.98725660841362"],
            ["40.32434727182099","70.98308569167379"],
            ["40.32415550473976","70.97928398470631"],
            ["40.32402047344034","70.97523825357911"],
            ["40.32392035380098","70.96544238254621"],
            ["40.32374882145564","70.96033396903837"],
            ["40.32347161132387","70.95528233242545"],
            ["40.32291612320366","70.94590342600354"],
            ["40.32243742955165","70.94342489225167"],
            ["40.32277887739248","70.93481892983472"],
            ["40.32338581703807","70.92134898861663"],
            ["40.32360862973604","70.91206939231122"],
            ["40.32426888114083","70.88785802699995"],
            ["40.32469839729487","70.87450670415721"],
            ["40.32502113344665","70.8644574722197"],
            ["40.32535651572769","70.85123253034037"],
            ["40.32619003669869","70.84171819693641"],
            ["40.3280537832206","70.8262987089759"],
            ["40.32837154655758","70.82433111597358"],
            ["40.32879961283003","70.82115155002384"],
            ["40.32899885807264","70.81898270532233"],
            ["40.32922955990082","70.81546104369593"],
            ["40.32996277446572","70.81085294345579"],
            ["40.3297457700489","70.79845761969096"],
            ["40.32970654263222","70.78927026724651"],
            ["40.32930361530946","70.78542523018758"],
            ["40.3288957850595","70.78278192375991"],
            ["40.32859435419006","70.78085695304553"],
            ["40.32803551210461","70.77725503452285"],
            ["40.3285242271865","70.7738572987456"],
            ["40.32864779012988","70.77200845469898"],
            ["40.33212018377071","70.76141713863302"],
            ["40.33362043339948","70.75591884524982"],
            ["40.33409335001907","70.75312209276045"],
            ["40.33397274727519","70.74900987490943"],
            ["40.33414261217091","70.73701835976557"],
            ["40.33391474096929","70.73120450488207"],
            ["40.33371076152123","70.72592287212065"],
            ["40.33370084369175","70.72045844301407"],
            ["40.33364209413887","70.71535166976381"],
            ["40.33360667043273","70.70685596888427"],
            ["40.33342936466424","70.70178497847189"],
            ["40.33329496695988","70.69447740679291"],
            ["40.33326137743848","70.68871907991105"],
            ["40.33307046613211","70.68103811588898"],
            ["40.33219996413072","70.67750604099672"],
            ["40.33130122270978","70.67416704907144"],
            ["40.3306978417516","70.66816494708884"],
            ["40.33019444068641","70.66075025802732"],
            ["40.32984757088845","70.65844773892452"],
            ["40.32996695883668","70.65471119662394"],
            ["40.3307990438249","70.64560304658728"],
            ["40.33154738560143","70.63790566579537"],
            ["40.33196183355997","70.63242915499933"],
            ["40.33206507977506","70.63040126394327"],
            ["40.33041975617684","70.6236352434657"],
            ["40.32875660672364","70.61671456868109"],
            ["40.3274171469457","70.61085067779231"],
            ["40.32555868560985","70.60327760204564"],
            ["40.32506245657595","70.59971353305507"],
            ["40.32528102587748","70.58875547500628"],
            ["40.32523676858899","70.58518130885653"],
            ["40.32521843469522","70.58120214316784"],
            ["40.32533042993484","70.57661765505956"],
        ]},
    {latlngs: [
            ["40.33694817615064","71.05549528104149"],
            ["40.33624339017383","71.05575188910856"],
            ["40.33694387975742","71.05956808527665"],
            ["40.33752963477617","71.06620129593846"],
            ["40.33826101533077","71.08348991905071"],
            ["40.33865835145731","71.0920381811639"],
            ["40.33713688304298","71.10319248168793"],
            ["40.33640006401437","71.10757080509893"],
            ["40.33545912653039","71.11929879615938"],
            ["40.33547849854107","71.12450716697576"],
            ["40.33813087057964","71.12460046304518"],
            ["40.34195329149736","71.12472037976853"],
            ["40.34305266540127","71.15278626242443"],
            ["40.34389434238463","71.15863395092502"],
            ["40.36233333684776","71.17548753611997"],
            ["40.36765245455838","71.1827664225777"],
            ["40.37802781319284","71.1949979566633"],
            ["40.38374090261801","71.20113822457125"],
            ["40.39020696511084","71.21657985959489"],
            ["40.39230453118027","71.2255321990506"],
            ["40.39380817919803","71.24196691603703"],
            ["40.39417833955709","71.24573050182552"],
            ["40.39464387736599","71.25275858679247"],
            ["40.39469438139729","71.25349634264865"],
            ["40.39492872712518","71.25395930750214"],
            ["40.39573323862413","71.25664061348539"],
            ["40.39645372856953","71.25629184294371"],
            ["40.39711237126095","71.25840292832591"],
            ["40.39910534052484","71.26211124879876"],
            ["40.40031674344959","71.26394783697339"],
            ["40.40503441622772","71.26404389322722"],
            ["40.40573295143577","71.26449659922065"],
            ["40.41219749576734","71.27048250415633"],
            ["40.41691603627452","71.27503471846303"],
            ["40.41927407851154","71.27717604639278"],
            ["40.42582417156139","71.28393291300333"],
            ["40.42755394601225","71.29043688926161"],
            ["40.42880103566639","71.29598754023056"],
            ["40.43029426915101","71.30013108601158"],
            ["40.43098230606864","71.30331624210308"],
            ["40.43142257190793","71.3055038716975"],
            ["40.4344848388013","71.32025198620418"],
            ["40.43130626773144","71.32389141926711"],
            ["40.42756056764413","71.32772652231117"],
            ["40.42547668736692","71.32960464294598"],
            ["40.42427005660321","71.32895976345952"],
            ["40.42280740159096","71.32853775599095"],
            ["40.41756000355574","71.33572101834156"],
            ["40.41395564579628","71.33879214729403"],
            ["40.41107710198","71.34149305772024"],
            ["40.40634791872159","71.34544899758025"],
            ["40.40329331581373","71.3483615050455"],
            ["40.3993880758831","71.35104348630351"],
            ["40.39854548066559","71.35176814849672"],
            ["40.39772253583536","71.35247685876783"],
            ["40.39021056905699","71.36216334543369"],
            ["40.38970420711198","71.36325710198237"],
            ["40.38840135438715","71.36430931880133"],
            ["40.38008781174066","71.37369866005254"],
            ["40.37822261673622","71.37380648238849"],
            ["40.37693676952569","71.37407657356815"],
            ["40.37608678183335","71.3740767288232"],
            ["40.37464793442054","71.37387607843819"],
            ["40.36509839445215","71.37194636260132"],
            ["40.36103052465838","71.37289763623443"],
            ["40.35691272881185","71.37440352671676"],
            ["40.35331770839187","71.37600726818967"],
            ["40.3525774787127","71.37584105279825"],
            ["40.35082450113728","71.37655526345301"],
            ["40.34832137116463","71.37629695873234"],
            ["40.34750656367645","71.37612618020769"],
            ["40.34627582241169","71.37635975917263"],
            ["40.34485205392764","71.37719777374849"],
            ["40.34333425713867","71.37815364629454"],
            ["40.34202135678525","71.37863416290432"],
            ["40.34166540979574","71.37877005457891"],
            ["40.34093376914008","71.37913501613302"],
            ["40.33972764800933","71.38090721440219"],
            ["40.33924217589964","71.38169180973972"],
            ["40.33821574363613","71.38350220652666"],
            ["40.33785604676361","71.38564437713066"],
            ["40.33733489102967","71.38755849310598"],
            ["40.33659749862227","71.38990840301891"],
            ["40.3353106994844","71.39062474886916"],
            ["40.33483493697096","71.39107529512009"],
            ["40.33327215735378","71.39271252655811"],
            ["40.3332720714718","71.3927328077706"],
            ["40.3332610453085","71.39276042172581"],
            ["40.33226884957826","71.39384583641367"],
            ["40.33224889461786","71.39382080265838"],
            ["40.32832823686208","71.39674743874488"],
            ["40.32276981662259","71.39791533045874"],
            ["40.31970301450287","71.39816254821331"],
            ["40.31463961033416","71.39886345238071"],
            ["40.30994039772038","71.39965538496118"],
            ["40.30706002721781","71.40062501514321"],
            ["40.30630978475004","71.40923210038089"],
            ["40.30559557160134","71.41273268858583"],
            ["40.3050051262418","71.418645237828"],
            ["40.30328483808349","71.44377754065503"],
            ["40.30292620126368","71.44688769520027"],
            ["40.30273256755619","71.44812570232129"],
            ["40.30256551535089","71.4491347100123"],
            ["40.30266736865863","71.44978331198158"],
            ["40.30210834771653","71.45418831893492"],
            ["40.30159757911682","71.45601056930812"],
            ["40.3008582042072","71.45800108767106"],
            ["40.29990302879726","71.46239623330247"],
            ["40.29900353476882","71.46849520807105"],
            ["40.29992831974904","71.47410989310519"],
            ["40.30072448575993","71.47674538410013"],
            ["40.3025892053885","71.48074276004151"],
            ["40.30489050185857","71.48513164929116"],
            ["40.30550598627772","71.48859619721078"],
            ["40.30608133102191","71.49037889168959"],
            ["40.30685661242853","71.49304286011062"],
            ["40.30859221647594","71.49836602329944"],
            ["40.30988630401474","71.50241255792415"],
            ["40.31148844666705","71.50749696125"],
            ["40.31327246600776","71.51081919318773"],
            ["40.31349054137513","71.51178347508059"],
            ["40.31417837902534","71.51625332931164"],
            ["40.31403304895089","71.51789916447878"],
            ["40.31360525616491","71.51969985684862"],
            ["40.3137808925071","71.52124191130426"],
            ["40.31310010210974","71.52250768087427"],
            ["40.31317822128239","71.52388567382572"],
            ["40.31370096059435","71.52529515717053"],
            ["40.31412167849778","71.5274540616046"],
            ["40.31446141322174","71.52872852004374"],
            ["40.31460335732418","71.52904847335343"],
            ["40.314799128915","71.53009130270384"],
            ["40.31501618081678","71.53127850716258"],
            ["40.31523701238812","71.53255164378888"],
            ["40.31551828623588","71.53413444589475"],
            ["40.31576831578281","71.53560603901279"],
            ["40.31604877828647","71.53709633070554"],
            ["40.31604499914238","71.53750693670855"],
            ["40.31603442113082","71.53810882905522"],
            ["40.31614499231338","71.53910746950909"],
            ["40.31621428403788","71.53974761331565"],
            ["40.31642845754269","71.54214627322459"],
            ["40.31655582688426","71.54445895954224"],
            ["40.31670133646654","71.54746112052599"],
            ["40.31680545766863","71.55071805195074"],
            ["40.3168378850447","71.55167987057992"],
            ["40.31687229894175","71.55214718804599"],
            ["40.31715892515184","71.55363130553087"],
            ["40.31740252087815","71.55496583507194"],
            ["40.31775041371834","71.5569850128154"],
            ["40.31806073041979","71.55880616588931"],
            ["40.31834649462305","71.56032430852376"],
            ["40.31863724372312","71.56175379615969"],
            ["40.31900923105741","71.56416054752378"],
            ["40.31916659322138","71.56501167360625"],
            ["40.3196007254602","71.56698560419693"],
            ["40.31987378916","71.56821383672359"],
            ["40.32013192316389","71.56966239904712"],
            ["40.3203757867601","71.57091413622662"],
            ["40.32123246520111","71.57513643427701"],
            ["40.32292217506051","71.58105027975526"],
            ["40.3231335098038","71.58199574929445"],
            ["40.32379451174704","71.58450457718784"],
            ["40.32438110788299","71.58667600490149"],
            ["40.32635378924837","71.59323694970611"],
            ["40.32828748460441","71.5998404212516"],
            ["40.32888181797212","71.60227915193951"],
            ["40.32955967580055","71.60550225199165"],
            ["40.33067112063878","71.60942443647664"],
            ["40.3312052015633","71.61145921882074"],
            ["40.33258437667571","71.61539414566403"],
            ["40.33365761190569","71.61908618226367"],
            ["40.33404540642411","71.62227817166809"],
            ["40.33480600534674","71.62840288785135"],
            ["40.33524097862798","71.63084451820713"],
            ["40.33546938993484","71.63291855077452"],
            ["40.33566434044048","71.63511293273905"],
            ["40.3356781965825","71.63527563028383"],
            ["40.33573964003675","71.63594649285218"],
            ["40.33591368707671","71.63789056761124"],
            ["40.33611723671578","71.63972765461047"],
            ["40.33617497594957","71.64083781543933"],
            ["40.33653167004369","71.64281254551821"],
            ["40.33669036390672","71.64410797746362"],
            ["40.33685990887459","71.64517761832874"],
            ["40.33721730590511","71.64857893377422"],
            ["40.33721358772667","71.64858306595907"],
            ["40.33737071036953","71.65070992093754"],
            ["40.33759853371148","71.65362529683527"],
            ["40.33772478316611","71.65464320603471"],
            ["40.33776953682469","71.65884112720687"],
            ["40.33770882752125","71.66053131442835"],
            ["40.33778793507076","71.66334176805262"],
            ["40.33771724337507","71.66518642248644"],
            ["40.3376167151434","71.66636279610282"],
            ["40.33743685096146","71.66821900430156"],
            ["40.33735519242456","71.67078875970705"],
            ["40.33733716199361","71.67230278336801"],
            ["40.33718989520513","71.67513350353838"],
            ["40.33712927700468","71.67689319058178"],
            ["40.33707677202612","71.67912081774426"],
            ["40.33702022983594","71.68082088025653"],
            ["40.33693007720589","71.68349592132562"],
            ["40.33690139659159","71.68558241302352"],
            ["40.33690366464258","71.68766142736175"],
            ["40.33712236269629","71.6892112414689"],
            ["40.33719606723446","71.69014988408787"],
            ["40.33714824664882","71.69162092991009"],
            ["40.33711533753278","71.6931868260852"],
            ["40.3370552489685","71.69526048808329"],
            ["40.33697534039334","71.69694230326417"],
            ["40.33689436815295","71.69899203454104"],
            ["40.33688361429232","71.70083762131574"],
            ["40.3367949454525","71.70212508192432"],
            ["40.33674874828342","71.70374102154105"],
            ["40.33667572185738","71.70466239526841"],
            ["40.33661997517927","71.70620815961037"],
            ["40.33656058195384","71.70742565796216"],
            ["40.3364671449898","71.70913728881732"],
            ["40.33639666390074","71.71086418035746"],
            ["40.33633457540406","71.71302686572035"],
            ["40.33631837894536","71.71477641573297"],
            ["40.33627897253204","71.71677435436004"],
            ["40.33621211300721","71.71928774333573"],
            ["40.33598778729212","71.72425166106552"],
            ["40.33582829037669","71.72802531159461"],
            ["40.33560062076543","71.73385289193743"],
            ["40.33527176792362","71.74179276266003"],
            ["40.33412035824096","71.75385774459545"],
            ["40.33336712717932","71.76254645820961"],
            ["40.33317647621273","71.76497361018126"],
            ["40.33306191439799","71.76615828342879"],
            ["40.33276171948445","71.77042326855265"],
            ["40.3321034921872","71.77415008618117"],
            ["40.33170569042112","71.77624146178442"],
            ["40.33137004305939","71.77688960732924"],
            ["40.33091674689931","71.77935181565003"],
            ["40.33061773474791","71.78066213011094"],
            ["40.32991585500897","71.7848617193938"],
            ["40.32872908423608","71.79055532503813"],
            ["40.32856709958561","71.7985816144628"],
            ["40.32861488438294","71.80214180208566"],
            ["40.33020139733958","71.80575705101664"],
            ["40.33201639246442","71.81006684261968"],
            ["40.33356629955343","71.81326299580864"],
            ["40.33592036186867","71.81676228342792"],
            ["40.3377012244305","71.8184374897377"],
            ["40.33903701209155","71.81926943477839"],
            ["40.34197485895687","71.82187718141587"],
            ["40.34361121371867","71.82333041936592"],
            ["40.34549150168989","71.82560501138119"],
            ["40.34685387458612","71.82785118835594"],
            ["40.34736088381226","71.82962463074568"],
            ["40.34802113115749","71.83177731612041"],
            ["40.34915622960143","71.83875649936822"],
            ["40.35091382513423","71.84514749614914"],
            ["40.35302448356891","71.85210221638759"],
            ["40.35495225093682","71.85948821569063"],
            ["40.35608945866145","71.8647040590687"],
            ["40.35694003505348","71.86891701085965"],
            ["40.35764686687908","71.87009674639594"],
            ["40.35856933621079","71.87183408397449"],
            ["40.35974323298458","71.87395639819383"],
            ["40.36615351445863","71.87351707759426"],
            ["40.36995173123815","71.872978141034"],
            ["40.37564695707444","71.87305375808992"],
            ["40.37827562217716","71.87354609209171"],
            ["40.38171917529597","71.87523484835106"],
            ["40.38246604427061","71.87659210196379"],
            ["40.3835028752516","71.87835143692995"],
            ["40.38500376534114","71.88065520445903"],
            ["40.38666737068068","71.88355537797275"],
            ["40.38867884852705","71.8880261661934"],
            ["40.38915010949775","71.89157461148741"],
            ["40.38944327404583","71.89423675044033"],
            ["40.39073032594075","71.90376765582641"],
            ["40.39062244476627","71.90587934784436"],
            ["40.39039031365162","71.90762236936962"],
            ["40.39042633308651","71.90824237918436"],
            ["40.39046198105047","71.90926772103417"],
            ["40.3904949438994","71.90982336493016"],
            ["40.39076672319992","71.91268852431803"],
            ["40.39175262363556","71.91587049746708"],
            ["40.39218504744591","71.91737369863897"],
            ["40.39413514783687","71.92329013604132"],
            ["40.39436032579649","71.92447084697314"],
            ["40.39538934361438","71.92888904277984"],
            ["40.39735289551799","71.9367686297615"],
            ["40.39795771869115","71.93963138795854"],
            ["40.39831133740328","71.94261105555536"],
            ["40.3982775932582","71.94523962006826"],
            ["40.39821733314665","71.94677126939555"],
            ["40.39842644214316","71.94810811057071"],
            ["40.39881419958297","71.94976784818223"],
            ["40.39949774247748","71.95354513869412"],
            ["40.39985661074199","71.95523741107272"],
            ["40.40055712977742","71.96071263391863"],
            ["40.40091127433395","71.96438389465841"],
            ["40.40114935452328","71.96844188552454"],
            ["40.40129051034783","71.97251798795867"],
            ["40.40148305724138","71.97874341869259"],
            ["40.40202667516118","71.98432494556276"],
            ["40.40291963253567","71.98985293023807"],
            ["40.40469036837868","71.99463466566864"],
            ["40.4053017696297","71.99649107919065"],
            ["40.40600963296186","71.99840143184649"],
            ["40.40855760919864","72.00139446258305"],
            ["40.41444467024795","72.00214286734787"],
            ["40.41975428501139","72.00133676792848"],
            ["40.42257110566595","72.00233550071972"],
            ["40.42457788457785","72.00415651567698"],
            ["40.42580127697866","72.00490776719167"],
            ["40.43054052398293","72.00885276802772"],
            ["40.43233816996525","72.01110485004902"],
            ["40.43404989817661","72.01432535822974"],
            ["40.43460287199454","72.01828785640637"],
            ["40.43623780046774","72.0231557354911"],
            ["40.43673891903117","72.02604887387628"],
            ["40.43758150826081","72.02772971366775"],
            ["40.44470230839929","72.03427820965528"],
            ["40.44960459762627","72.03731468274457"],
            ["40.45395189884771","72.03988979410651"],
            ["40.45454908538355","72.04130412394233"],
            ["40.4550464160516","72.04385248667522"],
            ["40.45609826766417","72.04753405043573"],
            ["40.45774285320449","72.05151808356972"],
            ["40.45849620897113","72.05430992321995"],
            ["40.45969187255456","72.0558624503625"],
            ["40.45994471489962","72.0561226157114"],
            ["40.46145162595961","72.05729035894394"],
            ["40.46226191305787","72.05799751056662"],
            ["40.46239425987539","72.05888149336862"],
            ["40.46254670899896","72.06069764814634"],
            ["40.46277056557","72.06217551389653"],
            ["40.46327585032563","72.06363224853668"],
            ["40.46405188474106","72.0654768348785"],
            ["40.46488140163915","72.06746812845526"],
            ["40.46604623335558","72.06875359552765"],
            ["40.46753814085238","72.07025959566614"],
            ["40.46943161921878","72.07188791548953"],
            ["40.4706380279199","72.07312185240028"],
            ["40.47221828303751","72.07429077014335"],
            ["40.47451115567078","72.07606122641073"],
            ["40.47616911365648","72.07693457163049"],
            ["40.47692619057827","72.07756969134471"],
            ["40.47752417703054","72.07997904764626"],
            ["40.47729516066907","72.08147183272197"],
            ["40.47690717852039","72.08275496595468"],
            ["40.47675179250992","72.08353686401605"],
            ["40.47692751102089","72.08521548182937"],
            ["40.47736848471153","72.08664207551624"],
            ["40.47787864516835","72.08840678118302"],
            ["40.47876514875134","72.0894364061714"],
            ["40.47980054032681","72.09024751498501"],
            ["40.48002630034014","72.0909797986146"],
            ["40.48029945445136","72.0920515691911"],
            ["40.48077205113771","72.09303576632237"],
            ["40.48108519020387","72.0940905859338"],
            ["40.48129400761263","72.09913339530429"],
            ["40.48129168229086","72.10221186997336"],
            ["40.48221094783289","72.10413026063769"],
            ["40.48357636267924","72.10540841097006"],
            ["40.48491593815532","72.10696087114833"],
            ["40.48602563603827","72.10785134723807"],
            ["40.48668993851725","72.10903471290543"],
            ["40.48727048644948","72.11059739458507"],
            ["40.48784010258812","72.11255983171907"],
            ["40.48870569598938","72.11310703644099"],
            ["40.49198633949022","72.11248977679639"],
            ["40.49400145957977","72.11470732594317"],
            ["40.4940364654801","72.11473909828079"],
            ["40.49462428551966","72.11527826323638"],
            ["40.49540788157351","72.11515001357709"],
            ["40.49560034027785","72.11506693002376"],
            ["40.49618095327298","72.1144998679893"],
            ["40.49712272358423","72.11431114241414"],
            ["40.49789040831128","72.1152932386865"],
            ["40.49788960044048","72.11531487630822"],
            ["40.49829164547721","72.11617331073126"],
            ["40.49831182179459","72.11620583645825"],
            ["40.49835281099031","72.11624905989554"],
            ["40.49872030560407","72.11720361045923"],
            ["40.49958202209059","72.12174860790594"],
            ["40.499836491481","72.12203679605011"],
            ["40.50061282246237","72.12259419945177"],
            ["40.50101801375614","72.12264482773206"],
            ["40.50103584309409","72.12265286927352"],
            ["40.50106844330043","72.12265643882584"],
            ["40.50108297015007","72.12266039322937"],
            ["40.50110137093189","72.12266003830501"],
            ["40.50113859995098","72.12266355715229"],
            ["40.50384340191566","72.12462498070515"],
            ["40.50544368402205","72.1270223953715"],
            ["40.50699377596475","72.12980185450078"],
            ["40.50760690799378","72.13556987497759"],
            ["40.50742484622893","72.14010621532901"],
            ["40.507698375728","72.14192799286123"],
            ["40.50770669328717","72.14193189017378"],
            ["40.50820684428134","72.14214853710955"],
            ["40.50892160741769","72.14233619933648"],
            ["40.51223138953733","72.1427441564004"],
            ["40.5147910981724","72.14373273620018"],
            ["40.51719998361985","72.14431358663462"],
            ["40.51863220152237","72.14519420172283"],
            ["40.52033717223258","72.1467005295425"],
            ["40.52224629336359","72.1470109675936"],
            ["40.52367853026122","72.14832963816667"],
            ["40.5240373106487","72.1486694591551"],
            ["40.52464821766192","72.14924101995142"],
            ["40.52558753024849","72.15066032216201"],
            ["40.53248532217597","72.15829870190281"],
            ["40.53825564920548","72.16516615624735"],
            ["40.5421132888766","72.16991270085475"],
            ["40.5463537483065","72.17322667612675"],
            ["40.55112913719866","72.17468525810818"],
            ["40.5593615436955","72.17646366631438"],
            ["40.56455834579008","72.17878921740238"],
            ["40.56851995033222","72.184677460858"],
            ["40.57429950608753","72.19405453529033"],
        ]},
    {latlngs: [
            ["40.32507520243021","70.77307326760776"],
            ["40.32533436550394","70.77146963597299"],
            ["40.32538024065743","70.77084886940925"],
            ["40.32495841807968","70.7658623834963"],
            ["40.32533901982022","70.76441411748883"],
            ["40.32558864277866","70.7633899669087"],
            ["40.32433821715613","70.7594113653159"],
            ["40.3241523572545","70.75852916226117"],
            ["40.32391060108597","70.75450351268773"],
            ["40.32371714202704","70.7472181215044"],
            ["40.32323511093586","70.73783938902088"],
            ["40.32303029869571","70.72982657188375"],
            ["40.32238019926126","70.72688755763089"],
            ["40.32061231304647","70.72447963772275"],
            ["40.31713544049282","70.71720079513192"],
            ["40.31603107478946","70.70963336756866"],
            ["40.3152009193211","70.70418553364368"],
            ["40.31505190738413","70.69775813459526"],
            ["40.31443821908852","70.69301892692314"],
            ["40.31456424968396","70.69056870886176"],
            ["40.31381634050186","70.68676026689275"],
            ["40.31214978641723","70.68356472357024"],
            ["40.31146293484459","70.67914556126122"],
            ["40.31290488162059","70.67142527762918"],
            ["40.31240651015676","70.66274170471789"],
            ["40.31272214214208","70.64999255539746"],
            ["40.31394512245875","70.63648321045565"],
            ["40.31415885579231","70.62832563848742"],
            ["40.3147224978278","70.62371224472471"],
            ["40.31684287279129","70.61655268680805"],
            ["40.31971691808737","70.6126617798802"],
            ["40.31962851452207","70.60495667467697"],
            ["40.31977361233385","70.59825574076318"],
            ["40.31912083550311","70.59085807227713"],
            ["40.31791042953032","70.57813712425843"],
        ]},
    {latlngs: [
            ["40.32507417943232","70.77307561306421"],
            ["40.32494413538951","70.77379061875779"],
            ["40.32446237466619","70.77638615425427"],
            ["40.32414745462741","70.78069901991988"],
            ["40.32430722795537","70.78385723423229"],
            ["40.32432663926006","70.78757250269874"],
            ["40.32426181747487","70.79037187923764"],
            ["40.32393365448668","70.79349516351138"],
            ["40.3237628703977","70.7976297010228"],
            ["40.32388632458027","70.80582893366481"],
            ["40.32364975846116","70.81568915772603"],
            ["40.32339849821251","70.81973004454213"],
            ["40.3229314178078","70.82395691948112"],
            ["40.32267450685768","70.83036159542644"],
            ["40.32277036868953","70.83363203389246"],
            ["40.32306419399782","70.83909632825474"],
            ["40.32299989947069","70.84426568882012"],
            ["40.32273066523623","70.84867788935668"],
            ["40.32222676150456","70.85309373292205"],
            ["40.32123708209099","70.85966597784048"],
            ["40.32070184484039","70.86393126475778"],
            ["40.31947469597972","70.87239069936344"],
            ["40.31895221534112","70.87654635927831"],
            ["40.31742612454203","70.88733507170083"],
            ["40.31414693640434","70.91222327113996"],
            ["40.31204372183931","70.92665512773117"],
            ["40.31390651137889","70.92980608183582"],
            ["40.31492316137138","70.93549197645612"],
            ["40.31709517436023","70.94534798041568"],
            ["40.31790306614491","70.94829621781626"],
            ["40.31829666627156","70.95177867256653"],
            ["40.31891689115302","70.95797679739835"],
            ["40.31940864508601","70.96025838425057"],
            ["40.31990924244982","70.96326834640078"],
            ["40.31998608041553","70.96431863044376"],
            ["40.32001830948713","70.96527233855031"],
            ["40.32011946541199","70.9660096314867"],
            ["40.32042941649387","70.96866326269402"],
            ["40.3215796275604","70.97444427859611"],
            ["40.32215526079435","70.97843404661552"],
            ["40.32143854576617","70.98191187880069"],
            ["40.32070225993825","70.985848726622"],
            ["40.32040717945552","70.98841786964346"],
            ["40.32062950658011","70.99118540462172"],
            ["40.32076171382771","70.99391783276107"],
            ["40.32075795944503","70.996690886683"],
            ["40.32083081327884","70.99925858003051"],
            ["40.32098995278677","71.00152268578088"],
            ["40.32098516708244","71.0043383576302"],
            ["40.32117896549337","71.0068563196706"],
            ["40.32157064604566","71.00778276864062"],
            ["40.3230168490597","71.01410860576424"],
            ["40.32322868571444","71.0145907921876"],
            ["40.32324053205706","71.01507358296843"],
            ["40.32295501529471","71.01553271753146"],
            ["40.3228375156973","71.01634393382137"],
            ["40.32279139263601","71.01677985374174"],
            ["40.32278927642882","71.01680665014473"],
            ["40.32270884454694","71.01791629605547"],
            ["40.32271651001844","71.01848672228756"],
            ["40.3228627321371","71.01914999748097"],
            ["40.32367661573937","71.02294511488435"],
            ["40.32366516218512","71.0235475767787"],
            ["40.32336495389824","71.02386224477235"],
            ["40.32300124703779","71.02439481481555"],
            ["40.32297300353626","71.02441891948283"],
            ["40.32294406752907","71.02445155839308"],
            ["40.3225896361918","71.02498123631015"],
            ["40.32252760842992","71.02543035150036"],
            ["40.32257125090585","71.02595215130746"],
            ["40.32256740725534","71.02598548881858"],
            ["40.32258302104076","71.0264175229875"],
            ["40.322580154672","71.02644247116199"],
            ["40.32268271788431","71.02716114889817"],
            ["40.32310086557541","71.02918115824389"],
            ["40.32330254978214","71.03175061728038"],
            ["40.32345802430211","71.03307732744459"],
            ["40.32382141005574","71.03496959905162"],
            ["40.32518792841174","71.0377124509606"],
            ["40.32640671324408","71.03987566905745"],
            ["40.32747479284442","71.04164297349406"],
            ["40.3302856536784","71.04797731431016"],
            ["40.33107858890063","71.04991537046203"],
            ["40.33116354061658","71.05053318976196"],
            ["40.33123448173471","71.05158357162618"],
            ["40.33125883347442","71.0536014230033"],
            ["40.33133473043056","71.0551568830723"],
            ["40.33123741576692","71.05602870928287"],
            ["40.33127402037804","71.05688712096811"],
            ["40.33167904677454","71.0573987986857"],
            ["40.33257863397098","71.05830485366816"],
            ["40.33364622899069","71.05922177970096"],
            ["40.33700366755775","71.0622613796012"],
        ]},
    {latlngs: [
            ["40.00924028684384","71.12888114150661"],
            ["40.00859724361284","71.12814471250984"],
        ]},
    {latlngs: [
            ["40.06628496641682","71.09525531057751"],
            ["40.06621274437234","71.09284028099748"],
        ]},
    {latlngs: [
            ["40.33547660903751","71.12451056762853"],
            ["40.33546378265333","71.1249210300731"],
            ["40.33540179117325","71.12548453570261"],
            ["40.33533685460387","71.12619413701523"],
            ["40.33520127350505","71.12752299349648"],
            ["40.33478316365581","71.13178437132818"],
            ["40.33427210741849","71.13761599151864"],
            ["40.33384092190234","71.1421969589603"],
            ["40.33305915211751","71.1506686607466"],
            ["40.33409115850897","71.15974649856062"],
            ["40.33445162054666","71.16258778041883"],
            ["40.33544300907217","71.17177567177906"],
            ["40.33586809616595","71.17623838906205"],
            ["40.33664233752243","71.18303686011832"],
            ["40.33772265040225","71.19307322292465"],
            ["40.33869003076294","71.20240300721903"],
            ["40.33979138850395","71.21284589677728"],
            ["40.34025103386227","71.21754578084341"],
            ["40.34039303087236","71.21950726971284"],
            ["40.3396347050442","71.22278131759508"],
            ["40.33987991779279","71.22367801773105"],
            ["40.3392850548909","71.22590851543212"],
            ["40.33869841570154","71.22793278097831"],
            ["40.33441111784634","71.24620664052941"],
            ["40.33171989802331","71.24927176133346"],
            ["40.33124369484889","71.24948775579493"],
            ["40.31950085444269","71.25017690773275"],
            ["40.31880962790105","71.25095939588603"],
        ]},
    {latlngs: [
            ["40.31922433667972","71.25049352612038"],
            ["40.31896754205693","71.25019575739127"],
            ["40.31518476666592","71.24815347900918"],
            ["40.3026386664192","71.23372924448505"],
            ["40.2641696055032","71.1828002280955"],
            ["40.25455124396672","71.15693824172321"],
            ["40.25427576514525","71.15602466310808"],
            ["40.25348577870153","71.15560813774893"],
            ["40.25231132191853","71.15487334269001"],
            ["40.25131804500637","71.15421745757456"],
            ["40.24993187560942","71.15319034491536"],
            ["40.24908163799842","71.15181280022964"],
            ["40.24852176276498","71.15100956851475"],
            ["40.24717016377897","71.14946296078953"],
            ["40.24622125666631","71.14878099037691"],
            ["40.2453068832201","71.14834426719351"],
            ["40.24406244216751","71.1478392160808"],
            ["40.24325407300966","71.14769464942488"],
            ["40.24215478574318","71.14777587983657"],
            ["40.24177580617315","71.14779862540534"],
            ["40.24129971454769","71.14763734162385"],
            ["40.23900516519909","71.14718969960667"],
            ["40.23771561121113","71.14688244757311"],
            ["40.23626792958817","71.14620689564228"],
            ["40.23533963746083","71.14557555225335"],
            ["40.23448274756405","71.14499474111467"],
            ["40.2273709631904","71.13937297885924"],
            ["40.22409484106656","71.1379864135553"],
            ["40.22096749614598","71.13775504968042"],
            ["40.21816144091769","71.13684974782532"],
            ["40.21563940398998","71.13601967374274"],
            ["40.21231696697225","71.13501231571728"],
            ["40.21039090984413","71.13385185812072"],
            ["40.20851571837504","71.13319847849162"],
            ["40.20567049501231","71.13245231811901"],
            ["40.20332725066706","71.13261318137759"],
            ["40.19927464403513","71.13202725212281"],
            ["40.19695971919478","71.13167724933662"],
            ["40.19125643745989","71.13021235698864"],
            ["40.18534871591453","71.13023497775968"],
            ["40.18478070120582","71.13014587427024"],
            ["40.1748247234888","71.12332738291711"],
            ["40.1539047351791","71.11924828360597"],
            ["40.15321799307662","71.11838219265772"],
            ["40.13717665450158","71.113590614766"],
            ["40.13199433726052","71.11155929653256"],
            ["40.1310747079296","71.11073852777129"],
            ["40.12920267712597","71.1080486892605"],
            ["40.12812537561544","71.10650947183977"],
            ["40.12534089049807","71.10469935049892"],
            ["40.12311046160287","71.10261656052889"],
            ["40.11863086370667","71.10064524739421"],
            ["40.11840475799242","71.10031991170597"],
            ["40.11808651240526","71.10013988250772"],
            ["40.11755662810684","71.09990064889492"],
            ["40.11646480958552","71.09939969889074"],
            ["40.11559920629292","71.0989897898743"],
            ["40.11511818133317","71.09874702683607"],
            ["40.11430250730072","71.09821805885026"],
            ["40.11416433643385","71.09809689858744"],
            ["40.11364138589092","71.09753764560048"],
            ["40.11311153205016","71.09719569401004"],
            ["40.11256774955394","71.09684111167142"],
            ["40.11169828271103","71.09710555334837"],
            ["40.11000395359613","71.09746187468691"],
            ["40.10919460582043","71.09762558563074"],
            ["40.1073103365013","71.09794278910032"],
            ["40.10671171340772","71.09805140457175"],
            ["40.10512873448033","71.09838038037032"],
            ["40.1044701103463","71.09871035341612"],
            ["40.10391008683868","71.10003593065603"],
            ["40.10310613804094","71.10219984117811"],
            ["40.10237644690004","71.10374164469502"],
            ["40.10176573970249","71.10477459135835"],
            ["40.10106820905089","71.10505270160246"],
            ["40.10046613181756","71.10515090902537"],
            ["40.09972138132989","71.10516224228856"],
            ["40.0993488432016","71.10514049663949"],
            ["40.09896522569309","71.10484711476552"],
            ["40.09884701561422","71.10449231075761"],
            ["40.09851364612108","71.10251554214233"],
            ["40.09830908117812","71.10213995961979"],
            ["40.09826061165811","71.10192121818298"],
            ["40.09813345802718","71.10141895436327"],
            ["40.09795779401377","71.10113332381385"],
            ["40.09763505285385","71.10089647250732"],
            ["40.09704289410193","71.10059402542069"],
            ["40.09634071105893","71.10007800822974"],
            ["40.09444849844429","71.09899775094104"],
            ["40.09388351896959","71.09857566419853"],
            ["40.09367535384016","71.0984531011234"],
            ["40.09222176178521","71.09759127823708"],
            ["40.09197831720223","71.09685099806244"],
        ]},
    {latlngs: [
            ["40.0919330466217","71.09690815931815"],
            ["40.09162198543958","71.09548719097741"],
            ["40.09128115470735","71.09518222214986"],
            ["40.090261551829","71.0946452418825"],
            ["40.08920402346163","71.0943170301251"],
            ["40.08785482138144","71.09417659400407"],
            ["40.08696556109923","71.09417668291672"],
            ["40.08579521917205","71.09376031609307"],
            ["40.08357098333243","71.09385737673666"],
            ["40.08230683971998","71.094981133429"],
            ["40.08200256555344","71.09503610202678"],
            ["40.06905649775048","71.09321890809728"],
            ["40.06734790447768","71.09365348391506"],
            ["40.06655996760431","71.09493455378235"],
            ["40.06628457227001","71.09527169909721"],
            ["40.06479691265814","71.09640076913423"],
            ["40.06307341933114","71.09640477011031"],
            ["40.06306754086805","71.09640466661217"],
            ["40.05989588300592","71.09692030457559"],
            ["40.05723718756681","71.09854043978999"],
            ["40.05491186720382","71.10076069478131"],
            ["40.05331685593036","71.10074440556821"],
            ["40.0519824103761","71.10304255134319"],
            ["40.05122021887191","71.10494504012043"],
            ["40.04951470464586","71.10559567787772"],
            ["40.04838889231334","71.10639488620166"],
            ["40.04707982218897","71.10754104138064"],
            ["40.04593074493966","71.1090212219831"],
            ["40.04425311990268","71.11063051202804"],
            ["40.03931771876026","71.1148560894825"],
            ["40.03709981978656","71.11632049941178"],
            ["40.03393380143851","71.11774595929944"],
            ["40.03109126089672","71.11796812053797"],
            ["40.02954903410414","71.1175508476812"],
            ["40.02753687505589","71.11778219717961"],
            ["40.02648782105706","71.1179967575069"],
            ["40.02466004633646","71.11704747509678"],
            ["40.02337871279747","71.11625902860152"],
            ["40.02126819487463","71.11551001919234"],
            ["40.02001046178781","71.11508370024008"],
            ["40.01994558535958","71.1150814746354"],
            ["40.01904734156633","71.11524762446791"],
            ["40.01799595579379","71.11610424610932"],
            ["40.01724337085994","71.11758705312796"],
            ["40.01565193841508","71.1190614759532"],
            ["40.01340142190745","71.12146030415859"],
            ["40.01124216556553","71.12428847249369"],
            ["40.00991155499922","71.12644473913761"],
            ["40.00956252452166","71.127097789327"],
            ["40.00931424242568","71.12826919923941"],
            ["40.00920763334728","71.12930502845875"],
            ["40.00876936079617","71.13151196475795"],
            ["40.00851569446483","71.13254613408978"],
            ["40.00674563711799","71.13734576297652"],
            ["40.00663740249372","71.13743588500995"],
            ["40.00601307582032","71.14001729673781"],
            ["40.00480255077459","71.14263628371751"],
            ["39.99822162465586","71.14118725520322"],
            ["39.9969063863978","71.14680497810799"],
            ["39.99591797328301","71.14876578777572"],
            ["39.99411047122779","71.15290578360832"],
            ["39.99301197327834","71.15501974954992"],
            ["39.99227037714173","71.15796741540332"],
            ["39.99103315297978","71.1607085642387"],
            ["39.9895882740105","71.16323909697145"],
            ["39.98876001710661","71.16418989813187"],
            ["39.98526814168651","71.16773816224433"],
            ["39.98498707848416","71.16727160954626"],
            ["39.9849863660701","71.16580183603821"],
            ["39.98487399310275","71.1649515897815"],
            ["39.98471189929654","71.16393191069825"],
            ["39.98437275508888","71.16326129340443"],
            ["39.98440995583","71.16221414393324"],
            ["39.98463506857864","71.16133375901506"],
            ["39.98571300468502","71.15866959366492"],
            ["39.98331975206701","71.15746621520604"],
            ["39.98221720282749","71.15704801311604"],
            ["39.97708156383737","71.15551585205955"],
            ["39.97618345779095","71.15399989367641"],
            ["39.97553925574623","71.15232335222234"],
            ["39.97476398778932","71.15192048397225"],
            ["39.97319435346261","71.15157337421192"],
        ]},
    {latlngs: [
            ["40.79574571506769","70.84140846109301"],
            ["40.79503533845129","70.8387942409189"],
            ["40.79377906215771","70.83401448047861"],
            ["40.79363778017322","70.8334288617137"],
            ["40.79214295356046","70.82777022419954"],
            ["40.79073166400137","70.82228986816537"],
            ["40.78978363662111","70.81867691155767"],
            ["40.78951030514868","70.81764800335704"],
            ["40.78913038066028","70.81633905675881"],
            ["40.78858499390711","70.81419638320976"],
            ["40.78832816215517","70.81353184887782"],
        ]},
    {latlngs: [
            ["41.09417204249041","70.509986675341"],
            ["41.0941187911434","70.51067072488702"],
            ["41.09407578673706","70.51140121944587"],
            ["41.09406673044876","70.51159979006178"],
            ["41.09410561027062","70.51191664655344"],
            ["41.09398770641558","70.51299728620928"],
            ["41.09387602285547","70.51404961797463"],
            ["41.09370528143724","70.51434371760332"],
            ["41.09355052794039","70.51480800748202"],
            ["41.09338256278071","70.51522978157688"],
            ["41.09314055591855","70.51574799787041"],
            ["41.0929695765341","70.51599293241571"],
            ["41.09265417961384","70.51640801785057"],
            ["41.09246617832847","70.51665330510501"],
            ["41.09224488060034","70.516840300152"],
            ["41.09208843682232","70.51711129118904"],
            ["41.09131349085283","70.51778890860277"],
            ["41.09101729851401","70.51814269975686"],
            ["41.09056039651686","70.5185032330625"],
            ["41.0899334496554","70.51888017504443"],
            ["41.08957484899726","70.51899506116877"],
            ["41.08909492302955","70.5191418781336"],
            ["41.08844437336898","70.51921543647376"],
            ["41.08782790085097","70.51926606484005"],
            ["41.08721737124889","70.51932661432646"],
            ["41.08706804960993","70.51937659238257"],
            ["41.08682224168359","70.51958161301572"],
            ["41.0866575206793","70.51965819718387"],
            ["41.08644254149213","70.51970597659016"],
            ["41.08608348935812","70.51963561611102"],
            ["41.08556549271277","70.5196735139873"],
            ["41.08526142494892","70.51972703751808"],
            ["41.08473284623946","70.51981403968836"],
            ["41.08437372217806","70.51980863144659"],
            ["41.08394903598325","70.5197943289331"],
            ["41.08361028509073","70.51998704971825"],
            ["41.08338302640729","70.52014070068131"],
            ["41.08301177438826","70.52018499021753"],
            ["41.08269764216622","70.52024928009065"],
            ["41.08241403956103","70.52027032017597"],
            ["41.08225696805377","70.52025693617502"],
            ["41.08213498590872","70.52032571820043"],
            ["41.08206747412314","70.5204772346837"],
            ["41.0820085745907","70.52059342407239"],
            ["41.08185213125688","70.52069496952964"],
            ["41.08154889178249","70.52079868909236"],
            ["41.08129057847786","70.52083915417673"],
            ["41.08099693291684","70.52095136280586"],
            ["41.08083604222178","70.52103772890696"],
            ["41.08064314660842","70.52119545310691"],
            ["41.08032833610304","70.52143447654281"],
            ["41.08005313889585","70.52166159817098"],
            ["41.07996176572019","70.52161660823955"],
            ["41.07978675927388","70.5215388736102"],
            ["41.07937953023608","70.52141366784055"],
            ["41.07913180151122","70.52143447987746"],
            ["41.0789720617689","70.52153551245429"],
            ["41.07885176638155","70.52172987017256"],
            ["41.0787708891159","70.52179452694445"],
            ["41.07861240760904","70.52184597861125"],
            ["41.07841272956681","70.5219756962738"],
            ["41.07822068814645","70.52214459771477"],
            ["41.07796671023336","70.52232213313225"],
            ["41.07774612853247","70.52253430303105"],
            ["41.07742935030333","70.5228565509522"],
            ["41.07721376517943","70.52323469250665"],
            ["41.07698125183093","70.52345808800064"],
            ["41.07666249413076","70.52391332597651"],
            ["41.07589720615776","70.52499434018705"],
            ["41.07543168831584","70.5256766417733"],
            ["41.07505987828947","70.52603387922169"],
            ["41.07460313540673","70.52644169940913"],
            ["41.07451287581436","70.52674477172657"],
            ["41.07445929134297","70.52717700944881"],
            ["41.07416876629977","70.52800839935334"],
            ["41.07390756802303","70.52880517259469"],
            ["41.07341295558918","70.52899736574548"],
            ["41.07302245761235","70.52916581291471"],
            ["41.07267889214372","70.52954128451528"],
            ["41.07227168374309","70.53010703091573"],
            ["41.0719966513717","70.53024456405841"],
            ["41.07190054525785","70.53032347087003"],
            ["41.07187579164837","70.53053676243157"],
            ["41.07187876618413","70.53108610752858"],
            ["41.07183238555733","70.53159223191714"],
            ["41.07173844160268","70.53210107000564"],
            ["41.07159139959659","70.53260373475"],
            ["41.07151059011559","70.5326766960942"],
            ["41.07134811443567","70.53269874059919"],
            ["41.07101542285346","70.532801291046"],
            ["41.07101564834729","70.5329966727452"],
            ["41.07125406231345","70.53352351415676"],
            ["41.07193745655047","70.53511991423318"],
            ["41.07235187454123","70.53639044695565"],
            ["41.07239656358242","70.53661740495909"],
            ["41.07228993628067","70.53675088808608"],
            ["41.07213635375412","70.53695750222948"],
            ["41.07201760642345","70.53711557773613"],
            ["41.07200158676574","70.53726008280617"],
            ["41.07208811096059","70.53797296422339"],
            ["41.07214732137182","70.53910246300414"],
            ["41.07198828674372","70.53975031536365"],
            ["41.07185211533341","70.53988416367316"],
            ["41.07150196456671","70.54000930550052"],
            ["41.07122050748393","70.54016328056963"],
            ["41.07111122126502","70.54024471477965"],
            ["41.07108952851854","70.54035386772759"],
            ["41.07106614680279","70.54049204856614"],
            ["41.07104409360736","70.54107581690786"],
            ["41.07098561630183","70.5417506691563"],
            ["41.07088967694033","70.54187358148472"],
            ["41.07068605773862","70.54216050232276"],
            ["41.07047179447013","70.5424248210274"],
            ["41.07027216857841","70.54249015130179"],
            ["41.06998557450902","70.54258177971991"],
            ["41.06977991286633","70.54263210905198"],
            ["41.06914164435926","70.54267169567629"],
            ["41.06906217104753","70.54272206406736"],
            ["41.06896170756247","70.54286072287032"],
            ["41.06880414151198","70.54300990565"],
            ["41.06872034384016","70.54308710891537"],
            ["41.06852315582549","70.54315867275137"],
            ["41.06826498312694","70.54322426625176"],
            ["41.06809447170051","70.54325624037511"],
            ["41.06805024107408","70.543313584896"],
            ["41.06805199197583","70.54341246155484"],
            ["41.0681191361925","70.54359995840178"],
            ["41.06822510870355","70.54394614746711"],
            ["41.06827954496781","70.54422658875835"],
            ["41.06830433562755","70.54459940455165"],
            ["41.06823958488635","70.54469471093222"],
            ["41.06809410094272","70.54487748626926"],
            ["41.06787812170465","70.54518176634704"],
            ["41.06769309726635","70.54547354723323"],
            ["41.06753533228837","70.54564572349491"],
            ["41.06739093424232","70.54586280081186"],
            ["41.06720323104113","70.54621553624398"],
            ["41.06709089624875","70.54644131464291"],
            ["41.06690394089775","70.54672050724845"],
            ["41.06686941882789","70.54688899257381"],
            ["41.0669598996648","70.54712443759485"],
            ["41.06701878695432","70.54733535473569"],
            ["41.06705930035348","70.54751230590325"],
            ["41.06704691544645","70.5476595209397"],
            ["41.06697228566752","70.54790613546538"],
            ["41.06689050025803","70.54810793354427"],
            ["41.06669597996199","70.54835170521372"],
            ["41.06649981173959","70.54857288673506"],
            ["41.06625421310871","70.54888514515666"],
            ["41.0661017995721","70.54902588089544"],
            ["41.06591829047392","70.54909069878642"],
            ["41.06565966952436","70.54922571701663"],
            ["41.06556404929017","70.54930427256218"],
            ["41.06548633963269","70.54941039921383"],
            ["41.06541948691304","70.54961275748128"],
            ["41.06534160077619","70.54981675881729"],
            ["41.06526081073744","70.54999211301623"],
            ["41.06516271825374","70.55015291468901"],
            ["41.0651199065792","70.55025101514539"],
            ["41.06511657726759","70.55036438869318"],
            ["41.06518592813907","70.55113534358806"],
            ["41.06513794560685","70.55126399696415"],
            ["41.06506329107355","70.55137479395312"],
            ["41.06498808879107","70.5514459036593"],
            ["41.06434508677013","70.55173598549069"],
            ["41.06410236021983","70.55187031364969"],
            ["41.06392559979962","70.55192350629405"],
            ["41.0632855443281","70.5519927194055"],
            ["41.06322566093176","70.5520521042726"],
            ["41.0631504754214","70.55220889330182"],
            ["41.06299462533404","70.55244243632225"],
            ["41.0625377596077","70.55297418136315"],
            ["41.0622624944345","70.55329677551448"],
            ["41.06189965529858","70.55360975693648"],
            ["41.06165420310434","70.55372712922222"],
            ["41.06133693264418","70.55395582996641"],
            ["41.06092811219207","70.55418267811653"],
            ["41.06063086561346","70.55436200600269"],
            ["41.06033269303308","70.55453641778861"],
            ["41.06003116529273","70.5548349577859"],
            ["41.05997690011721","70.55492734729778"],
            ["41.05975271637684","70.55515400372359"],
            ["41.05965248215104","70.55528989105903"],
            ["41.05949964208355","70.55554202094275"],
            ["41.05933228281391","70.55587471113792"],
            ["41.05925291683809","70.55623947857849"],
            ["41.05897230379431","70.55647541559459"],
            ["41.05855692103859","70.55671828726794"],
            ["41.05792701213901","70.55684276634211"],
            ["41.05737815605633","70.55703436414537"],
            ["41.056774295632","70.55717770983784"],
            ["41.0563799943663","70.55725671508907"],
            ["41.05609529448082","70.55730367854962"],
            ["41.05580256326994","70.55733008423988"],
            ["41.05555565561073","70.55735478668304"],
            ["41.05519365519479","70.55720371763864"],
            ["41.05495901565318","70.55714098494801"],
            ["41.05469746133538","70.55695932986301"],
            ["41.05449114649925","70.55677641285425"],
            ["41.05441244187733","70.55665106502761"],
            ["41.05433288519934","70.55665113224315"],
            ["41.05423946452596","70.55670540616684"],
            ["41.05406452545248","70.55685934748037"],
            ["41.05386259755884","70.55700784787648"],
            ["41.05367539527666","70.5570761232072"],
            ["41.05340777677971","70.55719426483996"],
            ["41.053208982013","70.55730996860684"],
            ["41.05303850844754","70.55746850718477"],
            ["41.05291671767122","70.55758631948693"],
            ["41.05279111279613","70.55764071361892"],
            ["41.05258258102194","70.55775541900834"],
            ["41.05197343095908","70.55785416693229"],
            ["41.05144991450573","70.55789525165812"],
            ["41.05103042335741","70.55792823551131"],
            ["41.05075684979438","70.55795793251838"],
            ["41.05062068311055","70.55798138757727"],
            ["41.05050931152177","70.55810627487887"],
            ["41.05035916303096","70.55819727459848"],
            ["41.05016977183073","70.55831348253892"],
            ["41.04994782593328","70.55844817494733"],
            ["41.04977876842875","70.55849726679237"],
            ["41.04934401819798","70.55865166267725"],
            ["41.04908850652245","70.5587852223853"],
            ["41.04858777556861","70.55876382963766"],
            ["41.04796667179021","70.55874215275028"],
            ["41.04737363939892","70.55882928780582"],
            ["41.04706767465115","70.55882291731749"],
            ["41.04677516860302","70.5588179369809"],
            ["41.04628530936684","70.55879456060099"],
            ["41.04612115082406","70.55878853488682"],
            ["41.0459906485904","70.55883056937802"],
            ["41.04586262793684","70.55891476744578"],
            ["41.04576257266498","70.55897146756564"],
            ["41.04560367101292","70.55908148724414"],
            ["41.04546386926489","70.55923883214302"],
            ["41.04528215731067","70.55935680245595"],
            ["41.04507864819883","70.55954312595411"],
            ["41.04494439465486","70.55968325997128"],
            ["41.04481912520978","70.55973485542683"],
            ["41.0445715054688","70.55986164215828"],
            ["41.04442355656987","70.56015364050474"],
            ["41.04426830149151","70.56035974092401"],
            ["41.04412698815304","70.56051943113692"],
            ["41.04387765084275","70.56072093410364"],
            ["41.04346901142986","70.56098640952274"],
            ["41.04327464573897","70.56106161217234"],
            ["41.04305091958203","70.56119531168875"],
            ["41.04284603380336","70.56130931062738"],
            ["41.04270142388725","70.56140104292936"],
            ["41.04253804688575","70.56137126771424"],
            ["41.04226692720404","70.56136182705411"],
            ["41.04202887708291","70.56144813994868"],
            ["41.04174817520638","70.56160738583934"],
            ["41.04152206381605","70.56174007911292"],
            ["41.04140394338688","70.56177793954012"],
            ["41.04122073267252","70.56181540384536"],
            ["41.04100588539998","70.56189524551731"],
            ["41.04073798090931","70.5620692865173"],
            ["41.04053361870771","70.56222527871422"],
            ["41.04033912135482","70.56241339423774"],
            ["41.04009563628001","70.56252168378245"],
            ["41.03990471013957","70.56253636205163"],
            ["41.0391950311752","70.56242938170793"],
            ["41.03895249651644","70.56236982326625"],
            ["41.03853691576428","70.56245800466922"],
            ["41.03830157250781","70.56231895407547"],
            ["41.03795978958995","70.56221988833509"],
            ["41.03766384751427","70.56233010636262"],
            ["41.0373682918444","70.56251592530243"],
            ["41.03689802948428","70.56279596251589"],
            ["41.03676785730764","70.56289857624381"],
            ["41.03661191410176","70.56298836883336"],
            ["41.03624344207154","70.56317412659313"],
            ["41.03599950640486","70.5633171240306"],
            ["41.0357588001747","70.56351013251299"],
            ["41.03546598814727","70.56380262120017"],
            ["41.03523276376252","70.56403511318508"],
            ["41.03502837539035","70.56417575897856"],
            ["41.03481035118715","70.56435558609398"],
            ["41.03464436936841","70.564497064137"],
            ["41.03432455328907","70.5648328260623"],
            ["41.03421266702553","70.56494835559019"],
            ["41.03409199236725","70.56502402620882"],
            ["41.03387543913159","70.56531744510657"],
            ["41.03371476254998","70.56572021485843"],
            ["41.03362616289898","70.56599730264533"],
            ["41.03342672377732","70.56650261777781"],
            ["41.03313615022035","70.56729767257482"],
            ["41.03290807627941","70.56767539300505"],
            ["41.03283369749737","70.56780194067157"],
            ["41.03279797232262","70.5679346741243"],
            ["41.03276772957178","70.56805456079144"],
            ["41.03270160951433","70.56811684395956"],
            ["41.03255637803184","70.56833323749811"],
            ["41.03231548971284","70.56887746956602"],
            ["41.03230085221131","70.5690485779255"],
            ["41.03227770439608","70.56929057346399"],
            ["41.03225408378782","70.56979884946465"],
            ["41.03224963483187","70.57031203706572"],
            ["41.03222821315215","70.57111384623484"],
            ["41.03223949572541","70.57118721895817"],
            ["41.03241523304527","70.5712932259262"],
            ["41.03292059034054","70.57166552646719"],
            ["41.03302205114571","70.57174093860441"],
            ["41.03331567420374","70.57249421239629"],
            ["41.03331844987213","70.57260813320639"],
            ["41.03321427698974","70.57276570729158"],
            ["41.0328420107497","70.57305631770818"],
            ["41.0321242433636","70.57372247390653"],
            ["41.03202634553062","70.57376656190421"],
            ["41.03168297897457","70.57381356283685"],
            ["41.03134890100387","70.5738264775773"],
            ["41.03127616586385","70.57383532926491"],
            ["41.03115509189941","70.57389511619893"],
            ["41.03106425421209","70.57393866022332"],
            ["41.03094307117007","70.57410814145584"],
            ["41.03081827699558","70.57431487737148"],
            ["41.03062619463066","70.57465952865928"],
            ["41.03035221053266","70.57493551221681"],
            ["41.03020517521948","70.57523585002944"],
            ["41.03007191165979","70.57543357254487"],
            ["41.02969723241463","70.57610988010752"],
            ["41.0294125666685","70.57659780145929"],
            ["41.02915198027803","70.57693791123775"],
            ["41.02880358831489","70.5772377102732"],
            ["41.02840683742731","70.57746192340311"],
            ["41.02816280002818","70.57765031098101"],
            ["41.02814811136414","70.57785587882083"],
            ["41.02823421857041","70.57820204132955"],
            ["41.02845690036319","70.57868672356967"],
            ["41.02864595048985","70.57912147404822"],
            ["41.02861442887064","70.57960218763226"],
            ["41.02840328181203","70.58089910889547"],
            ["41.02840628226754","70.58096317428723"],
            ["41.02844451888488","70.58102540024007"],
            ["41.02851805707902","70.58108174374543"],
            ["41.02891643890094","70.58130740626817"],
            ["41.02909410085285","70.58145106589589"],
            ["41.02913985962118","70.5815619420019"],
            ["41.02919204657973","70.58176812965354"],
            ["41.02928136523942","70.58224526601431"],
            ["41.02933650381494","70.58261361331093"],
            ["41.02938626407841","70.58323145755206"],
            ["41.02936167018448","70.58391120157731"],
            ["41.02938348099838","70.58419315088065"],
            ["41.02924404315501","70.58447869043066"],
            ["41.02903186803169","70.5848108637574"],
            ["41.02884260019806","70.58512447684751"],
            ["41.02854841858498","70.58545322851207"],
            ["41.02842221741117","70.58555361712821"],
            ["41.0282685024642","70.58564469705652"],
            ["41.02815004471742","70.58587941250808"],
            ["41.02807859357984","70.58616532710607"],
            ["41.02802132288647","70.58656167136662"],
            ["41.02791535893547","70.58666244119671"],
            ["41.02773996848145","70.58707796683095"],
            ["41.02761725090297","70.5873311951303"],
            ["41.0274942956365","70.58757474055763"],
            ["41.02737573624492","70.58790599364566"],
            ["41.02728171984023","70.58806982372816"],
            ["41.02703608221796","70.58852088163765"],
            ["41.02675828423578","70.58870709411502"],
            ["41.02658610648846","70.58884450276834"],
            ["41.02634239706049","70.58916307040269"],
            ["41.02606814525169","70.58961964898498"],
            ["41.02593554235741","70.58982453411524"],
            ["41.02578437815204","70.5899523111819"],
            ["41.02555307453879","70.59030042114024"],
            ["41.02536774920048","70.59059039255952"],
            ["41.02522428499184","70.59082883935871"],
            ["41.0248305303051","70.59142167550506"],
            ["41.02433155129764","70.59195600264533"],
            ["41.02389380457169","70.59236322814651"],
            ["41.02353961890685","70.59269246041066"],
            ["41.02287475962011","70.5932320488786"],
            ["41.02237091797666","70.59370614668276"],
            ["41.0221450434752","70.59392036500381"],
            ["41.02173188772385","70.59413094281676"],
            ["41.02164158244295","70.59421646189251"],
            ["41.02161797975562","70.59443559654898"],
            ["41.02156394958134","70.59467595860454"],
            ["41.02143623404999","70.59487792891687"],
            ["41.021415243368","70.59508056127106"],
            ["41.02146239912537","70.59525520908893"],
            ["41.02144167879801","70.59543077174625"],
            ["41.021499587488","70.59565817801594"],
            ["41.0215584871828","70.5962073513133"],
            ["41.02155129904969","70.59646990991565"],
            ["41.02158067685222","70.59731110685726"],
            ["41.02159087875469","70.59763316000183"],
            ["41.02152659077235","70.59795762552636"],
            ["41.02141370979356","70.59839434136553"],
            ["41.02124056310264","70.59874975397696"],
            ["41.02090055907202","70.59947686594998"],
            ["41.02068594135815","70.59989445342738"],
            ["41.02002877075127","70.60122721660453"],
            ["41.02006096286603","70.60153793829124"],
            ["41.02012676427374","70.60179083424502"],
            ["41.0202221272151","70.60234705606126"],
            ["41.02018766843024","70.60250758902163"],
            ["41.02002699144812","70.60279089021817"],
            ["41.01976589072729","70.60324533454569"],
            ["41.01939835186382","70.60379867201891"],
            ["41.01882338122552","70.60476258019406"],
            ["41.01837685405252","70.60507295263173"],
            ["41.0180422109652","70.60526254085879"],
            ["41.01789022732672","70.60549499372607"],
            ["41.01778350863484","70.60567771093895"],
            ["41.01764560941136","70.60585605909327"],
            ["41.01739734478706","70.60636505683675"],
            ["41.01709408113384","70.60684441276537"],
            ["41.01692778855839","70.60719982913065"],
            ["41.01665890800068","70.60769772998695"],
            ["41.01584185167061","70.60847638426641"],
            ["41.01552506087647","70.60877165327973"],
            ["41.01507253078808","70.60917623069636"],
            ["41.01458570070817","70.60938767483292"],
            ["41.01444638764655","70.60970407812736"],
            ["41.01439473538715","70.60989073589221"],
            ["41.01428183047526","70.61007466440248"],
            ["41.01409335717268","70.61046610208226"],
            ["41.0140177549722","70.61058578899326"],
            ["41.01383095598383","70.6107352329031"],
            ["41.01316760155964","70.61103852043384"],
            ["41.01258301979858","70.6113644724674"],
            ["41.01206619625612","70.61177351153712"],
            ["41.01201262493348","70.61192700530192"],
            ["41.01193987667504","70.61249745448791"],
            ["41.01183055533936","70.61318067360537"],
            ["41.01174152603463","70.61333691447504"],
            ["41.01113464743087","70.61374064514021"],
            ["41.01078093636617","70.61397099814054"],
            ["41.0103317543635","70.61431266108517"],
            ["41.00995010127716","70.61463980040024"],
            ["41.00968100205223","70.61491302084484"],
            ["41.00923422305695","70.61540003546206"],
            ["41.00893368830423","70.61569197024468"],
            ["41.00825273166013","70.61634060263805"],
            ["41.00732875498208","70.61723178721905"],
            ["41.00671241089364","70.61708142102822"],
            ["41.0063086584498","70.61710550986473"],
            ["41.00545434160393","70.61762039193695"],
            ["41.00479521459616","70.61837082150238"],
            ["41.00451782054601","70.618844160214"],
            ["41.00425896458653","70.61922897306231"],
            ["41.00327665395952","70.62152094172671"],
            ["41.00300292839788","70.62194532116713"],
            ["41.00285185939939","70.62216181141652"],
            ["41.00203745328747","70.62315892130336"],
            ["41.00146753610262","70.6240726994302"],
            ["41.00101245525938","70.62479269949904"],
            ["41.00083078056973","70.62512625566495"],
            ["41.00023938012336","70.62603626698191"],
            ["40.99988903998812","70.62656182822253"],
            ["40.99984251868258","70.62662151770704"],
            ["40.99972879109401","70.62663019294772"],
            ["40.99942824128038","70.6266619566165"],
            ["40.99866006353303","70.62674588624915"],
            ["40.99840026634599","70.62676392828493"],
            ["40.99746133242266","70.62688909365555"],
            ["40.99734916570283","70.62693503721262"],
            ["40.99721975606879","70.62699211851776"],
            ["40.99701465828156","70.62715654171411"],
            ["40.99684716017752","70.62730805819443"],
            ["40.99602029304401","70.62815930832596"],
            ["40.99551136092326","70.6288053734244"],
            ["40.9952608560583","70.62911060237002"],
            ["40.9941190567359","70.63031982567853"],
            ["40.99346531168223","70.63113968213223"],
            ["40.99292878127796","70.63169893682812"],
            ["40.99273685505517","70.63187110500492"],
            ["40.9924821956159","70.63208187097244"],
            ["40.99213066198667","70.63239276487853"],
            ["40.99173951671747","70.63273581494357"],
            ["40.99129010556796","70.63314588017016"],
            ["40.99074676488988","70.6336379675846"],
            ["40.99034930971871","70.63400260201232"],
            ["40.99011700204666","70.63419129795631"],
            ["40.98992846405635","70.63438315646167"],
            ["40.98960783266508","70.63467284397774"],
            ["40.98869731204065","70.63555231233379"],
            ["40.98848304019059","70.63580296561523"],
            ["40.98825973450733","70.6360014822706"],
            ["40.98807369164359","70.636168497737"],
            ["40.98760362324324","70.63658924073502"],
            ["40.98725255439756","70.63687487850301"],
            ["40.9870537089435","70.63708650395077"],
            ["40.98677208926716","70.63735303428717"],
            ["40.98645871279137","70.63763989405415"],
            ["40.98621895275064","70.637887323885"],
            ["40.98586161389342","70.63827242270493"],
            ["40.98518144550059","70.63894719204229"],
            ["40.98486766055049","70.63936434923211"],
            ["40.98476313607543","70.6395477306893"],
            ["40.98441468809836","70.64013747529195"],
            ["40.98407879608683","70.64048248629554"],
            ["40.98357862504721","70.64093639413088"],
            ["40.98305744507145","70.64133592406945"],
            ["40.98245896248665","70.64177164506039"],
            ["40.98201124479324","70.64202790129897"],
            ["40.9816719370675","70.64221894624585"],
            ["40.98136819992045","70.64242091400213"],
            ["40.98072338349298","70.64283687492441"],
            ["40.98009202561389","70.64320614033493"],
            ["40.97961997609681","70.6434706147602"],
            ["40.97864610223171","70.64387689203799"],
            ["40.97853945533422","70.64388264559828"],
            ["40.978022211059","70.64376753238312"],
            ["40.97667989921569","70.64357529860682"],
            ["40.97620520973233","70.64361971328789"],
            ["40.97595742375819","70.64368881502932"],
            ["40.97564775429888","70.64381053009117"],
            ["40.97482705746216","70.64405149673013"],
            ["40.97433626512237","70.6440959543157"],
            ["40.97404270682988","70.64408695751544"],
            ["40.97378984989778","70.64403967671004"],
            ["40.97303760810747","70.64408501358039"],
            ["40.97288886582643","70.6441384720538"],
            ["40.97264073786317","70.64430417542054"],
            ["40.97204246106671","70.64467303535324"],
            ["40.97147332135375","70.6450248738772"],
            ["40.97066970914653","70.64550996056288"],
            ["40.97019052301828","70.64582388253406"],
            ["40.96932193268992","70.64607442242003"],
            ["40.96832311383673","70.64637390822841"],
            ["40.96762150674781","70.64681197040606"],
            ["40.96679116452794","70.64721645226675"],
            ["40.96580997302844","70.64778176774631"],
            ["40.96518269702082","70.64818791435907"],
            ["40.96304975650709","70.64919258035415"],
            ["40.96194006166752","70.64976161248413"],
            ["40.96070713993015","70.65075798873947"],
            ["40.95981793760954","70.65151061082382"],
            ["40.95862944351821","70.65195198199204"],
            ["40.95769284409202","70.65236008828828"],
            ["40.95658878012684","70.65282483255476"],
            ["40.95583728955688","70.65322189062505"],
            ["40.95498909588183","70.6536871829132"],
            ["40.95470862893265","70.65384406169107"],
            ["40.95459437196804","70.65389807084863"],
            ["40.95402830848997","70.6541706445559"],
            ["40.95350975768245","70.65447959366308"],
            ["40.95316711226982","70.65466321492922"],
            ["40.95262432042321","70.65496589739632"],
            ["40.95252130010167","70.65501979855151"],
            ["40.95229007781641","70.65502534219267"],
            ["40.95170529287009","70.65504083937715"],
            ["40.95161677487432","70.6550944346816"],
            ["40.95152853418528","70.65524804404772"],
            ["40.95146292957331","70.65543144715734"],
            ["40.95120596345531","70.6558516005822"],
            ["40.9510170534701","70.65607599456573"],
            ["40.95081710910109","70.65640564591706"],
            ["40.95040542128086","70.65690098387279"],
            ["40.95010524566677","70.65730531849145"],
            ["40.94938062260356","70.65828071700621"],
            ["40.94900942886158","70.65877399240813"],
            ["40.94878144734405","70.65906839020813"],
            ["40.94867216688786","70.65926004279093"],
            ["40.94851920707939","70.6595009471462"],
            ["40.94813866465256","70.65985441919591"],
            ["40.94802111809597","70.66000395654953"],
            ["40.94719352927024","70.66085281870423"],
            ["40.94672154516662","70.66137342524661"],
            ["40.94652438642365","70.66159655425471"],
            ["40.94635337623867","70.66176923894167"],
            ["40.94627245731584","70.66187609346267"],
            ["40.94616658986575","70.66207327763703"],
            ["40.94591498901487","70.66250006110025"],
            ["40.9457415883692","70.66278365931335"],
            ["40.9456262708979","70.66298198230297"],
            ["40.94535015669362","70.66352518946513"],
            ["40.94492824882802","70.66420757586836"],
            ["40.94481760715161","70.66438610448432"],
            ["40.94457133229692","70.66479076201159"],
            ["40.94452111400742","70.6648334458758"],
            ["40.94439457520091","70.66487073592597"],
            ["40.94424347338146","70.66488992099636"],
            ["40.9439588508076","70.66499410970252"],
            ["40.94289125204492","70.6653078785299"],
            ["40.94183100632586","70.66566702593808"],
            ["40.94111640564211","70.66589620536109"],
            ["40.94102729609551","70.66592713346182"],
            ["40.94095374934432","70.66605574244407"],
            ["40.94023179873604","70.66742823449825"],
            ["40.93882926411872","70.66956952055089"],
            ["40.93879441698505","70.669637874861"],
            ["40.93871066621701","70.66963734204072"],
            ["40.93858565657122","70.66957532167658"],
            ["40.93617269557326","70.6685277681361"],
            ["40.93611176600432","70.66850506440001"],
            ["40.93605151687219","70.668503676572"],
            ["40.93595241278034","70.66855895538035"],
            ["40.93554754205697","70.66881275823924"],
            ["40.93534997677633","70.66893371735407"],
            ["40.93490588870617","70.66914030302571"],
            ["40.93407960112416","70.66956230475654"],
            ["40.93338496600026","70.66986369920249"],
            ["40.93254293994038","70.67021063878371"],
            ["40.93059231622476","70.67100425656413"],
            ["40.93042452840022","70.67106894731087"],
            ["40.93021419605462","70.67116806850015"],
            ["40.92915740841951","70.67179395598458"],
            ["40.92785448318813","70.67279284026448"],
            ["40.92625971148153","70.67403349558592"],
            ["40.92562241304579","70.67450802469484"],
            ["40.92553430010138","70.67457904602965"],
            ["40.92540996813971","70.67458971699379"],
            ["40.92370893449631","70.67497347135246"],
            ["40.92262165668744","70.67526643591226"],
            ["40.92121197821715","70.67564220223851"],
            ["40.92115018012252","70.67567613417383"],
            ["40.9210888812594","70.67575535564394"],
            ["40.92088088212449","70.67607738553305"],
            ["40.92036204197709","70.67691602253761"],
            ["40.91925746165696","70.67902334430791"],
            ["40.91886810381784","70.67977179374617"],
            ["40.91882318202915","70.67986332606024"],
            ["40.91879300344933","70.67997891854154"],
            ["40.91857785198585","70.68114135581457"],
            ["40.91818624370745","70.68341871033783"],
            ["40.91802796110166","70.68436209442721"],
            ["40.91788083653","70.6853467760273"],
            ["40.91786690550442","70.68542290642544"],
            ["40.9178286265496","70.68555910504651"],
            ["40.91748529059077","70.68619564676762"],
            ["40.91698101613916","70.68718589583004"],
            ["40.91615016218345","70.68881856031143"],
            ["40.91515884576403","70.69082930043682"],
            ["40.9145096218742","70.69216785198482"],
            ["40.91388195222626","70.69331166060681"],
            ["40.91382143204915","70.6933788544809"],
            ["40.91369336213252","70.69346930185505"],
            ["40.91225766087892","70.69425348994942"],
            ["40.90934042583671","70.69580913401956"],
            ["40.90810850609083","70.69642253053584"],
            ["40.90659492333779","70.69722076906919"],
            ["40.90553545791046","70.69780777973023"],
            ["40.90540223904926","70.69790204622801"],
            ["40.90521759949763","70.69809369115157"],
            ["40.90301699639552","70.70065628560992"],
            ["40.90079014235405","70.70345000531564"],
            ["40.89975522196372","70.70485922571426"],
            ["40.89940218119461","70.70534545639688"],
            ["40.89732429650562","70.70868459181861"],
            ["40.896094940836","70.71068749975896"],
            ["40.89607514359948","70.7107765736627"],
            ["40.89612351520073","70.71086290295096"],
            ["40.89675003813033","70.71211648693688"],
            ["40.89675152484767","70.71219127398369"],
            ["40.89672035198197","70.71229696589677"],
            ["40.8958330372106","70.71416505149922"],
            ["40.89288663672875","70.72070040942056"],
            ["40.8902185553584","70.72630841206352"],
            ["40.88720203813325","70.73151344246733"],
            ["40.88394994796574","70.73705710335921"],
            ["40.88237032457132","70.73967665866019"],
            ["40.88232764916145","70.73975456853248"],
            ["40.8822450219339","70.73979596511852"],
            ["40.88183390305287","70.7399348777636"],
            ["40.88178126531905","70.73997520928211"],
            ["40.88166732751505","70.74010994700959"],
            ["40.8810848288087","70.74079000577338"],
            ["40.88014198362168","70.74190528082052"],
            ["40.87915952465986","70.74315762890659"],
            ["40.87749132159136","70.74520767558283"],
            ["40.87617233291898","70.74684572315259"],
            ["40.87489863919812","70.74837640555454"],
            ["40.87125980976344","70.75285864523218"],
            ["40.86597494356906","70.75933982988074"],
            ["40.86238782992609","70.76382150721398"],
            ["40.86117837963563","70.76523674700728"],
            ["40.86076211348724","70.76603548805306"],
            ["40.8606378301708","70.76671944474808"],
            ["40.86041225591292","70.76732131876679"],
            ["40.85993476539076","70.76791963097236"],
            ["40.85563110957077","70.77140389112829"],
            ["40.85250030893692","70.77384286527032"],
            ["40.85220084106688","70.77365138227364"],
            ["40.85207510142381","70.77359775202898"],
            ["40.85200892322638","70.7736368611141"],
            ["40.85196932041183","70.77367120477132"],
            ["40.85053824164032","70.77484753035206"],
            ["40.8488930331261","70.77618412483064"],
            ["40.84743083669469","70.77734511888454"],
            ["40.8457945583646","70.77867397195752"],
            ["40.84265644939821","70.78115712812966"],
            ["40.84041774017504","70.78294674889966"],
            ["40.83846892823787","70.7845526653784"],
            ["40.83508414352134","70.78719869614253"],
            ["40.83394778800052","70.78812993219864"],
            ["40.83181580471076","70.78982036172229"],
            ["40.83093466206156","70.79057134263189"],
            ["40.83089070267414","70.79063837089478"],
            ["40.83089365045836","70.79080722090156"],
            ["40.83100895154052","70.79211483115934"],
            ["40.83110004814746","70.79301980771997"],
            ["40.83118830630864","70.79549830979205"],
            ["40.83119514340474","70.79653659782238"],
            ["40.83119356625745","70.79661254094677"],
            ["40.83117182295842","70.7966703605266"],
            ["40.83107906946793","70.7967793990569"],
            ["40.827602426533","70.80086342286336"],
            ["40.82448146621569","70.80452664517054"],
            ["40.82096742715451","70.80862397048992"],
            ["40.81818482464634","70.81185757856851"],
            ["40.8155155561975","70.81497843434585"],
            ["40.8130836916516","70.81787616245562"],
            ["40.81266433845047","70.81838972477544"],
            ["40.81225846507987","70.81892850570127"],
            ["40.81086574760464","70.82075631518359"],
            ["40.80854789061468","70.82374248685245"],
            ["40.80730649456729","70.82607489141974"],
            ["40.80646173130383","70.82781258946497"],
            ["40.80643387167464","70.82786470268869"],
            ["40.8063495050248","70.82796862259909"],
            ["40.8042712489281","70.83060537201231"],
            ["40.80070124836657","70.83519886718119"],
            ["40.79951112518381","70.8367566070779"],
            ["40.79672147590929","70.84036426738002"],
            ["40.79228945986903","70.84617382090599"],
            ["40.79063656475884","70.84838034628235"],
            ["40.7888366322159","70.84962634414978"],
            ["40.78802413540823","70.85310180996858"],
            ["40.78703776940198","70.85762255563054"],
            ["40.78861817337149","70.86001669352315"],
            ["40.79159045350547","70.86433568503361"],
            ["40.79173731677608","70.86457162254597"],
            ["40.79174492491454","70.86462552515918"],
            ["40.79171671712675","70.8647173542946"],
            ["40.79167107158159","70.86481305905207"],
            ["40.79080423472301","70.86667347498683"],
            ["40.78911094420364","70.87035282197689"],
            ["40.78651532148042","70.8761801592577"],
        ]},
    {latlngs: [
            ["40.86247668012108","70.76409407746202"],
            ["40.86288038531896","70.76445756666679"],
            ["40.86304192387228","70.76470804101619"],
            ["40.86322380946537","70.76512934530662"],
            ["40.86358165194393","70.76625182355271"],
            ["40.86565998778667","70.77382187860327"],
            ["40.86567139055692","70.7738876079474"],
            ["40.86569984120555","70.77404449147963"],
            ["40.86572798080294","70.77428789552575"],
            ["40.86575990074146","70.77604817141658"],
        ]},
    {latlngs: [
            ["40.96598518412908","69.57064674225457"],
            ["40.96637065525207","69.57349003943091"],
            ["40.96485547708517","69.57546988819894"],
            ["40.9618035537662","69.57800255573582"],
            ["40.9585416787872","69.58073122397811"],
            ["40.95716119783582","69.58187243500763"],
            ["40.95619434883341","69.5847381245744"],
            ["40.9554601629717","69.58747333568326"],
            ["40.95437786766889","69.58996737071323"],
            ["40.95396706374328","69.59130377777046"],
            ["40.95326183921306","69.59320002354116"],
            ["40.95288511432203","69.59427219772763"],
            ["40.95329639916728","69.60039682173924"],
            ["40.95362747311146","69.60378450962395"],
            ["40.95383671954831","69.60711071030856"],
            ["40.95417192721507","69.61135991736467"],
            ["40.95448293135065","69.61522060866497"],
            ["40.95480893074776","69.62044189075327"],
            ["40.95537193783536","69.62613930738749"],
            ["40.95559540956718","69.62962144875134"],
            ["40.95575570700869","69.63118738057335"],
            ["40.95597314771028","69.63320892051557"],
            ["40.95615203338343","69.63577844214052"],
            ["40.95623254682303","69.63663901482315"],
            ["40.95603433028787","69.63686089988035"],
            ["40.95552333846951","69.63761130194959"],
            ["40.95518399203671","69.63806594975419"],
            ["40.95483144327612","69.63861941046811"],
            ["40.95461497220983","69.63904253873486"],
            ["40.95451943983986","69.63958026108656"],
            ["40.95443381829753","69.64044881880177"],
            ["40.95428143437118","69.64195777762077"],
            ["40.95420338230112","69.64303268636732"],
            ["40.95413843047901","69.64359870512622"],
            ["40.95401374948527","69.64462012253831"],
            ["40.95389972795272","69.64560173273506"],
            ["40.95382049481522","69.64657760643109"],
            ["40.9537024395104","69.64767021251872"],
            ["40.953625848032","69.64810623185657"],
            ["40.95359175935968","69.64865976372199"],
            ["40.95353163708793","69.64942763697408"],
            ["40.95342887930109","69.65055777731014"],
            ["40.95350833409166","69.65106395067748"],
            ["40.95363107359287","69.65175554596432"],
            ["40.95377326556388","69.65277744627788"],
            ["40.95392706927961","69.65365513451545"],
            ["40.95407924399735","69.65432250661816"],
            ["40.95421680632128","69.65513350359146"],
            ["40.95435150790274","69.65595302376963"],
            ["40.95446326149916","69.65657759356155"],
            ["40.95462234948852","69.65739778868478"],
            ["40.95477608119246","69.65796978919465"],
            ["40.95499775985705","69.65883528044208"],
            ["40.95518823507929","69.65962903350992"],
            ["40.95540703746828","69.66055516427632"],
            ["40.95552313900088","69.66118421029013"],
            ["40.95562635118441","69.66172245788266"],
            ["40.95580866778589","69.66226085098172"],
            ["40.95606254146749","69.66314064224879"],
            ["40.95609513058544","69.66369965314162"],
            ["40.95596474339819","69.66397879874482"],
            ["40.95570261672456","69.66410494272655"],
            ["40.95527448319511","69.66446919981291"],
            ["40.95488385932476","69.66476172316511"],
            ["40.9545443173036","69.66517488430719"],
            ["40.95457659067631","69.66572276251793"],
            ["40.95463146806304","69.66598367598408"],
            ["40.95457225096212","69.66654029428092"],
            ["40.95454597784664","69.6671848943629"],
            ["40.95450776686791","69.66761865513331"],
            ["40.95442060032506","69.66794508901833"],
            ["40.95418617628249","69.66830266930361"],
            ["40.95366303642587","69.6689634508467"],
            ["40.95318447724955","69.66956677279404"],
            ["40.95254393443742","69.67026523529593"],
            ["40.95254689694099","69.67025374943454"],
            ["40.95217009324789","69.67072401031091"],
            ["40.95176234418308","69.6712223616876"],
            ["40.95137627507864","69.67162637902238"],
            ["40.95109094588818","69.67196474093225"],
            ["40.95017412204388","69.67315245365624"],
            ["40.94916041743301","69.6743866395155"],
            ["40.94833505228714","69.67547357530127"],
            ["40.94776772939745","69.67623352466381"],
            ["40.94776542076097","69.67620946341287"],
            ["40.94709143448696","69.67709115218075"],
            ["40.94652608618157","69.67784876995202"],
            ["40.94617276052043","69.6782997459952"],
            ["40.94557449611277","69.67911548277995"],
            ["40.94500101397059","69.6798382091863"],
            ["40.9445111939861","69.68047518754871"],
            ["40.94408917591427","69.68102976895662"],
            ["40.94374022853317","69.68147081779726"],
            ["40.94319636807311","69.68219056652117"],
            ["40.94259106431633","69.68302514716392"],
            ["40.94189315032858","69.68394370313165"],
            ["40.94148392293101","69.68452905477976"],
            ["40.9407596007203","69.68555082552669"],
            ["40.94041076729904","69.68597590640118"],
            ["40.94016833586234","69.68624516702961"],
            ["40.9396574848742","69.68683164998244"],
            ["40.93920296783631","69.68724724952688"],
            ["40.93864333476765","69.68799312326708"],
            ["40.9380971921402","69.68865820063571"],
            ["40.93759718873895","69.68934760572699"],
            ["40.93712768013145","69.68998962975415"],
            ["40.93648389188405","69.69081244935833"],
            ["40.93621306864277","69.69118234485393"],
            ["40.93514323310389","69.69260214375586"],
            ["40.93478638052428","69.69307047199564"],
            ["40.93411003592696","69.69397100929567"],
            ["40.9336674719415","69.694797483809"],
            ["40.93336562232443","69.69519846929983"],
            ["40.93298964174524","69.69540191692396"],
            ["40.93263383904822","69.69574378779748"],
            ["40.93175632114344","69.69648795164353"],
            ["40.93120153404696","69.69693098259404"],
            ["40.93060030840974","69.69732157622278"],
            ["40.93007895008941","69.6977206661425"],
            ["40.92979703635164","69.69806413609362"],
            ["40.9295668772528","69.69831734804677"],
            ["40.92915380805309","69.69859224669014"],
            ["40.9286703043966","69.69898022743355"],
            ["40.92802370131253","69.6995104398617"],
            ["40.92728882593247","69.70004918417939"],
            ["40.9266017349253","69.70060394559725"],
            ["40.92613552279254","69.70093795677661"],
            ["40.92543873852903","69.70157841837245"],
            ["40.92446328552314","69.70236589063025"],
            ["40.92323994702048","69.7033267994284"],
            ["40.92303745453763","69.70384307545427"],
            ["40.9228149146814","69.70464288647999"],
            ["40.92258598844279","69.70532325176127"],
            ["40.92206067402903","69.70684089476036"],
            ["40.92199129502809","69.70783666917332"],
            ["40.9219502946685","69.70869125812615"],
            ["40.92199970387514","69.71025205557876"],
            ["40.92201744021626","69.71107776962438"],
            ["40.92212925757566","69.71164546954626"],
            ["40.92250405170133","69.71212584843552"],
            ["40.92316348123318","69.71292209350737"],
            ["40.92347054631959","69.71347002248346"],
            ["40.92383053874497","69.71401247929653"],
            ["40.92387441179439","69.71433930686382"],
            ["40.9238014315637","69.71491382505738"],
            ["40.92363833008237","69.7154639365604"],
            ["40.92354860853509","69.7160744387635"],
            ["40.92346234589227","69.71700796988843"],
            ["40.92332452157256","69.71830421874577"],
            ["40.92309637438143","69.72042551762128"],
            ["40.9229701106666","69.72192916871829"],
            ["40.92284882198325","69.72295448329385"],
            ["40.92274161190397","69.72359135034097"],
            ["40.92254301244299","69.72421019834297"],
            ["40.92239809300357","69.72472075838991"],
            ["40.92246786325311","69.72547486226274"],
            ["40.92251745771296","69.72575584294604"],
            ["40.92254524316923","69.72582923811008"],
            ["40.9228652537718","69.72626146344206"],
            ["40.92297053860408","69.72728362110298"],
            ["40.92306611716782","69.72819068596695"],
            ["40.92321264463958","69.72912341002878"],
            ["40.92327094022626","69.72981786884225"],
            ["40.92337207254118","69.73036953513839"],
            ["40.92348102382874","69.73112382777614"],
            ["40.92360379458109","69.73237321860184"],
            ["40.92363123777921","69.7330386754771"],
            ["40.92367456866008","69.73380173412254"],
            ["40.92371958533537","69.73486574211742"],
            ["40.92377138702938","69.73599453670015"],
            ["40.92388141389419","69.73650698303008"],
            ["40.924096141095","69.7373153672152"],
            ["40.92436262679814","69.73815479933197"],
            ["40.92454586296964","69.73898758408632"],
            ["40.92481559836273","69.73996501351625"],
            ["40.92502189790002","69.74057276457769"],
            ["40.92526197449131","69.74126559337998"],
            ["40.92553338205173","69.74202848757022"],
            ["40.92558619629208","69.74231420192864"],
            ["40.92535989719712","69.74292261651415"],
            ["40.92513517637944","69.74356452080151"],
            ["40.92513417434713","69.74356962267325"],
            ["40.92499772354674","69.74391190036394"],
            ["40.92467633554999","69.74470869921888"],
            ["40.92445448706689","69.7452500454018"],
            ["40.92424291795815","69.7457827979961"],
            ["40.92401905632579","69.74647638211727"],
            ["40.92385871712619","69.74698124899164"],
            ["40.92358905631726","69.74776996529589"],
            ["40.92342578696419","69.74851217462287"],
            ["40.92325725997356","69.74914593982176"],
            ["40.92306507817426","69.75003599212637"],
            ["40.92291867696066","69.75057075814382"],
            ["40.92283853851536","69.75082224842076"],
            ["40.92285113014281","69.75108838597244"],
            ["40.92297202357619","69.75139377822678"],
            ["40.92321239878379","69.75215665842769"],
            ["40.92337001110491","69.753015924268"],
            ["40.92359783477158","69.75370801735951"],
            ["40.92387880126528","69.75435314731391"],
            ["40.92400883638415","69.75469479757693"],
            ["40.92429574799793","69.75542973425358"],
            ["40.92456728993011","69.7560394473782"],
            ["40.92474410029531","69.75656864576814"],
            ["40.92487881555383","69.75701398687568"],
            ["40.92491207427879","69.75737151151719"],
            ["40.92493706987267","69.75790851301011"],
            ["40.92498684233465","69.75863704268131"],
            ["40.92500427744617","69.75933728429813"],
            ["40.92503265569076","69.76017860774746"],
            ["40.92509223061273","69.761126367282"],
            ["40.92521381111494","69.76259187433222"],
            ["40.92533172650924","69.76428307492566"],
            ["40.9253088014972","69.7653077209125"],
            ["40.92536861775209","69.76614536402606"],
            ["40.92542041273939","69.76728873913278"],
            ["40.92554596705477","69.76855609328646"],
            ["40.92554993634972","69.76945804946887"],
            ["40.92564791577986","69.77035881550569"],
            ["40.92570933235472","69.77101768823013"],
            ["40.92578944733975","69.7717380487701"],
            ["40.92592140891232","69.77248921269053"],
            ["40.92614051137246","69.77389378483511"],
            ["40.92630630478374","69.77488055400704"],
            ["40.92644347493664","69.77561079500103"],
            ["40.92662280524781","69.77681876353995"],
            ["40.92683991634587","69.77809518675504"],
            ["40.92703450629078","69.77934959068791"],
            ["40.92718857543759","69.78035349501378"],
            ["40.92737207524979","69.78157569385098"],
            ["40.92761294189337","69.78300228872546"],
            ["40.92775517451518","69.78389136471066"],
            ["40.92795142836562","69.7850377303874"],
            ["40.92817132809745","69.78619867770252"],
            ["40.92826549386693","69.78703290018034"],
            ["40.92840969009964","69.78783684729017"],
            ["40.92846477405318","69.78903551850436"],
            ["40.92857011814093","69.79043084713399"],
            ["40.9286282255186","69.79131395005025"],
            ["40.92869379389473","69.79229459865719"],
            ["40.92866144460719","69.79301548306054"],
            ["40.92919211669157","69.794095501827"],
            ["40.92984834847207","69.79512953333843"],
            ["40.93029578425558","69.79563692473104"],
            ["40.93068505469458","69.79603565859858"],
            ["40.93114964548519","69.79911435368231"],
            ["40.93204132390291","69.80268925811097"],
            ["40.93278153296301","69.80626293562466"],
            ["40.9319968281879","69.81036396696341"],
            ["40.93245353336538","69.81459011928438"],
            ["40.93326119667597","69.81847388169672"],
            ["40.93430190634787","69.82378487545948"],
            ["40.93330896598599","69.82571815416053"],
            ["40.93256786426014","69.82675031791452"],
            ["40.93211668841238","69.82768773679285"],
            ["40.9320466674712","69.8290174866772"],
            ["40.93209511575198","69.83074358332971"],
            ["40.932636699545","69.83382285106208"],
            ["40.93314803077252","69.83748536121338"],
            ["40.9338144538932","69.84239627654462"],
            ["40.93470784164667","69.84663059295777"],
            ["40.93566958074597","69.85159306127365"],
            ["40.93693939164017","69.85587755165903"],
            ["40.93777954600286","69.85929300497401"],
            ["40.93833952216625","69.86490654067974"],
            ["40.93816519148862","69.86537368060085"],
            ["40.93717536890105","69.86599553568772"],
            ["40.9362646739566","69.86666824879643"],
            ["40.9352800725041","69.86725836566512"],
            ["40.93377785042967","69.86851090107476"],
            ["40.93235638789177","69.87021568922383"],
            ["40.93150956305471","69.87109853421425"],
            ["40.92988813582544","69.87233212754496"],
            ["40.92837098038415","69.87340459661154"],
            ["40.92788188346227","69.87380740068437"],
            ["40.92676954673117","69.87626808137102"],
            ["40.92611295282435","69.87663774085131"],
            ["40.92553809739948","69.87695391213782"],
            ["40.92547703844255","69.8768460122327"],
            ["40.92435939534751","69.8774111390929"],
            ["40.92438078759481","69.87755909519532"],
            ["40.92320686964832","69.8781509869541"],
            ["40.92312791668817","69.87801545030321"],
            ["40.92211546657056","69.87859230103811"],
            ["40.92215484649127","69.87872976856556"],
            ["40.92103889996317","69.87932738467067"],
            ["40.92097522416664","69.87919653087168"],
            ["40.92035534621334","69.87956158155367"],
            ["40.92038032977988","69.87969210089597"],
            ["40.92036572873315","69.87973750064498"],
            ["40.92032286160919","69.87977520106544"],
            ["40.9199699522627","69.87994071468317"],
            ["40.91981728382454","69.88026751200671"],
            ["40.91968969041064","69.88066110193117"],
            ["40.91912884017078","69.88275281651478"],
            ["40.91822636193987","69.88523681587159"],
            ["40.91774367519384","69.88638045750209"],
            ["40.91672947904349","69.88855742381544"],
            ["40.91406505515305","69.89292381194228"],
            ["40.91210272364242","69.89598237268814"],
            ["40.91141616969761","69.89709635854786"],
            ["40.91059120880571","69.8990125594496"],
            ["40.91007389390206","69.90050207792385"],
            ["40.90942108192941","69.9023917368813"],
            ["40.90900318185036","69.90391002645717"],
            ["40.90901608234681","69.90465414752241"],
            ["40.90967284440468","69.90673320380624"],
            ["40.91090751269085","69.91073961014187"],
            ["40.91173380926446","69.91310168262874"],
            ["40.91216369321947","69.91423427479309"],
            ["40.91270996224715","69.91570215873797"],
            ["40.9132722600321","69.91722095399754"],
            ["40.91426638288142","69.91915106346094"],
            ["40.91484307349229","69.92005499863022"],
            ["40.91594203684868","69.92181894037874"],
            ["40.91841557642059","69.92530155749408"],
            ["40.92491544399941","69.93320493091856"],
            ["40.92250304872171","69.95276204994649"],
            ["40.9301192824348","69.9687001978334"],
            ["40.93404395576543","69.975599794687"],
            ["40.93883277085246","69.98394083646019"],
            ["40.94123029582499","69.98868096922666"],
            ["40.94256100369766","69.99053521980017"],
            ["40.94375370589975","69.99181920847738"],
            ["40.94858486333996","69.99651607818109"],
            ["40.95816537578922","70.00607347495345"],
            ["40.95823913937316","70.00988917548553"],
            ["40.95813730331904","70.01635976776018"],
            ["40.95659081512791","70.02451934119492"],
            ["40.95675623174561","70.02501221297459"],
            ["40.95712081908524","70.02716158617535"],
            ["40.95701537883375","70.03051188920492"],
            ["40.9568989627505","70.03115015455604"],
            ["40.95769612452446","70.03240055811062"],
            ["40.95781718977998","70.03280209900531"],
            ["40.95784343365454","70.03309373708144"],
            ["40.9577046495505","70.0369546036687"],
            ["40.95744696050495","70.04189195909858"],
            ["40.95739792344985","70.04397660642186"],
            ["40.95737263558234","70.04595427750158"],
            ["40.95743734837856","70.04715231866425"],
            ["40.95738361267883","70.04860451024942"],
            ["40.95732616843877","70.04940892298659"],
            ["40.95740083610176","70.05150935142916"],
            ["40.95745720615706","70.05292522521374"],
            ["40.95739450169043","70.05425929943215"],
            ["40.9575694724179","70.05523187206566"],
            ["40.95785837024554","70.05714583817408"],
            ["40.95831839523314","70.05954731507873"],
        ]},
    {latlngs: [
            ["40.98409233804107","70.12245355419381"],
            ["40.98349793954463","70.12755113887222"],
            ["40.98320408669255","70.12921520759005"],
            ["40.98415563976493","70.13576700017275"],
            ["40.98511793876059","70.14284956530149"],
            ["40.9853035167263","70.14392304514507"],
            ["40.98424063904283","70.14730471228137"],
            ["40.98525968742533","70.14990665508617"],
            ["40.98536869466615","70.1564104439525"],
            ["40.98565320868113","70.15851033429361"],
            ["40.98612825734811","70.16011153606993"],
            ["40.98664457685744","70.16260900834769"],
            ["40.98667368596431","70.16384900350002"],
            ["40.98695813343818","70.16492380171609"],
            ["40.98547303678451","70.16665552765642"],
            ["40.98354285775368","70.1677115846563"],
            ["40.98305829369376","70.16800729711474"],
            ["40.9828264099225","70.16855355246263"],
            ["40.98156338383889","70.16964878922285"],
            ["40.98172616378785","70.17211529273686"],
            ["40.9826761031428","70.1739061579658"],
            ["40.98292549332728","70.17495392974003"],
            ["40.98397069210612","70.17642474025583"],
            ["40.98322135646873","70.17716787247514"],
            ["40.98395031159932","70.179247715458"],
            ["40.98490982334862","70.17991479764622"],
            ["40.98553364901119","70.18025383562863"],
            ["40.98661436768022","70.18078755749826"],
            ["40.98704261399928","70.1836996966949"],
            ["40.98851922984367","70.18578608893563"],
            ["40.98821984161405","70.18685090326608"],
            ["40.98795612345393","70.18751621121378"],
            ["40.98772313837689","70.18859355044964"],
            ["40.98738221229368","70.18955829454444"],
            ["40.98715502733332","70.19013394565508"],
            ["40.98685371390783","70.19072772789696"],
            ["40.98672121295179","70.19095461761725"],
            ["40.98655550576297","70.19122640886491"],
            ["40.98625766391422","70.19166644095077"],
            ["40.9858805099327","70.19212899286144"],
            ["40.98532611952462","70.19253741748233"],
            ["40.98484062898866","70.1930075697037"],
            ["40.98422590858188","70.19352728972096"],
            ["40.98382986446642","70.19398764994426"],
            ["40.98276619346181","70.19497453196935"],
            ["40.98252545413315","70.19679228369307"],
            ["40.98136678393193","70.20054772554296"],
            ["40.98064122847678","70.20220529406492"],
            ["40.98055082650583","70.2042311062623"],
            ["40.98023814196782","70.20515670218958"],
            ["40.98090155649206","70.20745983002141"],
            ["40.98145711252386","70.2096343873435"],
            ["40.98259995237359","70.21087010170372"],
            ["40.9843884773607","70.21198786173191"],
            ["40.985169870824","70.21248247503652"],
            ["40.98607108519692","70.21285796494676"],
            ["40.98783532429739","70.21366181901047"],
            ["40.98811492158885","70.21317748393544"],
            ["40.98837769103708","70.21276711858224"],
            ["40.99039847986431","70.21224842750183"],
            ["40.99277490197144","70.2117072894299"],
            ["40.99561593646431","70.21091998537277"],
            ["40.99739095546813","70.21033545340484"],
            ["40.99904046285221","70.20995560893714"],
            ["41.00062359590292","70.20961688286484"],
            ["41.00300350892571","70.20912606840452"],
            ["41.00438974800609","70.20983104148003"],
            ["41.00678007167456","70.20990016338"],
            ["41.00756347857355","70.21073972464501"],
            ["41.00793161944277","70.21128759855129"],
            ["41.00869566305559","70.21191978005677"],
            ["41.00902823152947","70.21316649526611"],
            ["41.00986892127563","70.21406042992976"],
            ["41.00999452840059","70.21537630909414"],
            ["41.01031321126841","70.21794903783058"],
            ["41.01018293436144","70.21921319641142"],
            ["41.00972335326205","70.22007819916841"],
            ["41.01003933048684","70.2206146065954"],
            ["41.01043759422017","70.22085748794278"],
            ["41.01063373144172","70.22112147143629"],
            ["41.01066798221541","70.22120076561272"],
            ["41.01079610142931","70.22137404632377"],
            ["41.01103330322557","70.22137382499186"],
            ["41.01131365442044","70.22118231789048"],
            ["41.01201351864418","70.22105192613859"],
            ["41.01294705664979","70.22094247384959"],
            ["41.01339554746668","70.22071702164855"],
            ["41.01371805725331","70.22060446259631"],
            ["41.01466663699952","70.22038168989313"],
            ["41.01542193191035","70.22070550534853"],
            ["41.01560525151118","70.22087954333156"],
            ["41.01675936355484","70.22136486288045"],
            ["41.0187804751269","70.22247269333356"],
            ["41.01965694596391","70.22275744597327"],
            ["41.02053899512934","70.22317898711972"],
            ["41.02072001610635","70.22293738114698"],
            ["41.02084524318312","70.22274827456164"],
            ["41.02106300291581","70.22246393456281"],
            ["41.0212883390031","70.2220893318125"],
            ["41.02166983115899","70.22139400036072"],
            ["41.02220252197361","70.21987025740741"],
            ["41.02234067346474","70.21899363650388"],
            ["41.02248413918159","70.21833983720406"],
            ["41.02267795786487","70.21746423321989"],
            ["41.02332305819268","70.216835026978"],
            ["41.02380895166985","70.2164305755819"],
            ["41.02364776064619","70.21465406931466"],
            ["41.02379984792403","70.21399471839662"],
            ["41.02405761769266","70.21327147182926"],
            ["41.02431252598971","70.21181214654084"],
            ["41.02592643210501","70.21227052550128"],
            ["41.02654367659086","70.21267656107214"],
            ["41.02759326868793","70.21380473307111"],
            ["41.02921867063908","70.21631006537282"],
            ["41.0302028536072","70.21775940523192"],
            ["41.03027522922952","70.21795481251036"],
            ["41.03003234895611","70.21925127977252"],
            ["41.02977431708291","70.22003525303946"],
            ["41.0297981989469","70.22089834049248"],
            ["41.02998661756213","70.22222139944677"],
            ["41.03000187845946","70.22234453355341"],
            ["41.03020381612961","70.22249213935308"],
            ["41.03037672754589","70.22256776468944"],
            ["41.0306753775665","70.22269628159358"],
            ["41.03086205551997","70.22281446574907"],
            ["41.03146416807621","70.22332430251402"],
            ["41.03175880376537","70.22383707186793"],
            ["41.03242609223255","70.22521039603099"],
            ["41.03330991081467","70.22680666812526"],
            ["41.03358169845477","70.22758870170452"],
            ["41.03392802180113","70.22873953051909"],
            ["41.03401599804204","70.22910768951556"],
            ["41.03392533187288","70.22944999277961"],
            ["41.03441423403752","70.23025297842921"],
            ["41.03486884001021","70.23083710738641"],
            ["41.03501568944048","70.23123125614765"],
            ["41.0352165270449","70.23212847659273"],
            ["41.03556680168607","70.23358193785668"],
            ["41.03577754672462","70.23455387033729"],
            ["41.03594875418083","70.235338000341"],
            ["41.03623494693437","70.23620288951649"],
            ["41.03637442502775","70.23676611880646"],
            ["41.03647530357931","70.2374970787113"],
            ["41.0366859822263","70.23836767080354"],
            ["41.0367475889656","70.23900281245925"],
            ["41.03688594260733","70.23977910105624"],
            ["41.03701450471915","70.24046866732415"],
            ["41.03744344274372","70.2423967499634"],
            ["41.03744852833923","70.24248802691962"],
            ["41.03751971257297","70.2429400701244"],
            ["41.03740418115054","70.24314772093959"],
            ["41.03733292141358","70.24331909624517"],
            ["41.03712118183302","70.24368703291431"],
            ["41.03667762121389","70.24462184231997"],
            ["41.03631473744619","70.24550033409695"],
            ["41.0359290475086","70.24621285953464"],
            ["41.03554735509467","70.24671603537182"],
            ["41.03514402184904","70.2477434381813"],
            ["41.03466433814353","70.24858834997184"],
            ["41.03423236140063","70.24901569346964"],
            ["41.03382779773931","70.24921966076009"],
            ["41.03364547855534","70.24931121685091"],
            ["41.03407167684787","70.24975112428039"],
            ["41.03424144387256","70.25005436958641"],
            ["41.03422513382651","70.25024943570254"],
            ["41.03437020702229","70.25118615353711"],
            ["41.03433332134463","70.25221068202819"],
            ["41.03445033322284","70.25289540413632"],
            ["41.03477079502567","70.25379759606052"],
            ["41.0350418616209","70.25477065887777"],
            ["41.03575140373668","70.25540909506097"],
            ["41.03605638076436","70.25618247871397"],
            ["41.0365015135352","70.25696361848836"],
            ["41.03718563737046","70.25820335538799"],
            ["41.03793979762098","70.26035109324916"],
            ["41.03858296101924","70.26116437724106"],
            ["41.03915840382236","70.26245514541894"],
            ["41.03971364042691","70.2638739656047"],
            ["41.03995862073062","70.26469655124572"],
            ["41.04014772051578","70.26672341657626"],
            ["41.04031526288154","70.26751159706964"],
            ["41.04068223053383","70.26886520475669"],
            ["41.04085714709075","70.26930285244151"],
            ["41.04092204315659","70.2699966920094"],
            ["41.0407870945204","70.27143969856878"],
            ["41.04036751832449","70.27283791016475"],
            ["41.04024278308194","70.2734553871083"],
            ["41.03994529262076","70.27403240411931"],
            ["41.03967780575942","70.27444546431688"],
            ["41.03942087087111","70.27501243851535"],
            ["41.03925109558811","70.27541398675667"],
            ["41.03943145906641","70.27751665015501"],
            ["41.03930189182783","70.27785781882967"],
            ["41.03864811789868","70.2785623968103"],
            ["41.03799388767542","70.27943671586468"],
            ["41.03763642130478","70.28016157330438"],
            ["41.0374873370583","70.2805656546069"],
            ["41.03750592809858","70.28098386333329"],
            ["41.03765697789917","70.28164319242489"],
            ["41.03780593918791","70.28224302536505"],
            ["41.03785815639684","70.28260456535561"],
            ["41.03769965135002","70.28308440687937"],
            ["41.03736632816833","70.28407796019242"],
            ["41.03711265920042","70.28502883903759"],
            ["41.03671849734814","70.28562959366312"],
            ["41.03639075088028","70.28617092481348"],
            ["41.03588519156213","70.2864783837164"],
            ["41.03567654171287","70.2869628648985"],
            ["41.03548307408556","70.28784757622989"],
            ["41.03538139206383","70.28867836220275"],
            ["41.03578651944552","70.28975059388513"],
            ["41.03550176041605","70.29102582486988"],
            ["41.03512707002417","70.29232981993125"],
            ["41.03548160785321","70.29336155688844"],
            ["41.03564113442982","70.29460613382935"],
            ["41.0357407227248","70.29532230835635"],
            ["41.03559601707109","70.29664223868633"],
            ["41.03601264720012","70.29788749432394"],
            ["41.03611275081693","70.29874643171824"],
            ["41.03658414339046","70.29982434757687"],
            ["41.03680147270341","70.30125439319838"],
            ["41.03722995563921","70.30223035323638"],
            ["41.03760608208579","70.30305532011812"],
            ["41.03808304870049","70.30375150721362"],
            ["41.03810529519004","70.3040967797409"],
            ["41.03789988734111","70.30471837982223"],
            ["41.03783733790549","70.30547350944832"],
            ["41.03790514834375","70.30629943215173"],
            ["41.03779818196533","70.30677838650151"],
            ["41.03770782730319","70.30765675561796"],
            ["41.03803593444968","70.30843020534223"],
            ["41.03825722095139","70.30888863069829"],
            ["41.03925987948448","70.30932953439761"],
            ["41.04041145699839","70.30973628946485"],
            ["41.04171117149772","70.30957547432216"],
            ["41.04286901235081","70.30929912853232"],
            ["41.04349807231326","70.30916221584773"],
            ["41.04416901446378","70.30939086836536"],
            ["41.04466814808136","70.30958241739017"],
            ["41.04502853732842","70.31012015309258"],
            ["41.0457890227345","70.31060396464284"],
            ["41.04605356951046","70.31064707647786"],
            ["41.04698299264597","70.31041374583558"],
            ["41.04772057529452","70.31009063196656"],
            ["41.04929615654088","70.31029384493932"],
            ["41.04978299723956","70.30992602848417"],
            ["41.05057687112822","70.30959496677379"],
            ["41.05228736452959","70.30892502118471"],
            ["41.05359313476647","70.30922582867447"],
            ["41.05410475807356","70.3094879609114"],
            ["41.05494645349776","70.30957477943454"],
            ["41.05689891673144","70.3102453034015"],
            ["41.05900777710178","70.31076827298082"],
            ["41.06019259841958","70.31142606538646"],
            ["41.06148538699947","70.31191982960351"],
            ["41.06251891746322","70.31312584149143"],
            ["41.06263003821192","70.31344897621031"],
            ["41.06232269133198","70.31385612433537"],
            ["41.06236169740637","70.31409612274757"],
            ["41.0625837362736","70.3143630602934"],
            ["41.06276102753811","70.31460732595299"],
            ["41.06281682133535","70.31477446877173"],
            ["41.06271550082966","70.31494292433442"],
            ["41.06256875244201","70.31515392066323"],
            ["41.06234066040138","70.31542579397235"],
            ["41.06225238884482","70.31607318391467"],
            ["41.06222122154502","70.31689113199492"],
            ["41.06226051817133","70.31778631471772"],
            ["41.06226056209626","70.31812647718752"],
            ["41.06237521601149","70.31875138576706"],
            ["41.06244517771924","70.31866769359439"],
            ["41.06243668983414","70.31865340257961"],
        ]},
    {latlngs: [
            ["41.06228200386754","70.31816073802675"],
            ["41.06256437363746","70.31868965594387"],
            ["41.06272818976557","70.3189309018333"],
            ["41.06313202118906","70.31953947438848"],
            ["41.06322151378407","70.319660767134"],
            ["41.06329837878408","70.31967474326322"],
            ["41.06339708120517","70.31958852460112"],
            ["41.06364960120692","70.31938600625476"],
            ["41.06372751337165","70.31933539025168"],
            ["41.0637846832364","70.31932407348589"],
            ["41.06383508495188","70.31933700310923"],
            ["41.06389618097027","70.31936015112812"],
            ["41.06456480186012","70.31975890535804"],
            ["41.06463029657018","70.31983499832457"],
            ["41.06474415480459","70.3200363142363"],
            ["41.06505669631186","70.32068880060341"],
            ["41.06519638281638","70.32093141893543"],
            ["41.06600311958663","70.32199513876485"],
            ["41.06646438438381","70.3227228816843"],
            ["41.0667791934033","70.3231995079904"],
            ["41.06688233253865","70.32330753670809"],
            ["41.06737939545188","70.32363768977383"],
            ["41.06771159104644","70.32380975272302"],
            ["41.06786757208884","70.32389441128699"],
            ["41.06883508359394","70.32447084279082"],
            ["41.068970690126","70.3246210128015"],
            ["41.06912177198189","70.32484302519471"],
            ["41.06922104757332","70.32513104505399"],
            ["41.0693444226978","70.32562201013961"],
            ["41.0694741046717","70.32600983119505"],
            ["41.06962239536608","70.3266003257569"],
            ["41.06960777207794","70.32691863713893"],
            ["41.06949997643991","70.32755132687423"],
            ["41.06936955449046","70.32806187669775"],
            ["41.06917114092751","70.32831175637114"],
            ["41.06882093160235","70.3285562177761"],
            ["41.06864516563332","70.32877918094751"],
            ["41.06853792171177","70.32932967961057"],
            ["41.06836029877963","70.329877642309"],
            ["41.06811748044617","70.33038897120984"],
            ["41.06801694302058","70.33077854811029"],
            ["41.067861579222","70.33104170387833"],
            ["41.06769908014182","70.33123998519028"],
            ["41.06758841855018","70.3314230806981"],
            ["41.06742016223607","70.33163071927284"],
            ["41.06735393043726","70.33176109906749"],
            ["41.06702545449054","70.33213806666085"],
            ["41.0668825096519","70.33228567530546"],
            ["41.06660532151827","70.33240390121259"],
            ["41.06640586352825","70.33250482454353"],
            ["41.06633399429533","70.33257259192834"],
            ["41.06631324127561","70.33271594052061"],
            ["41.06625864574316","70.33295032174924"],
            ["41.0660922673942","70.33348421001367"],
            ["41.06595639087149","70.33383425654472"],
            ["41.06581064142297","70.33414748820464"],
            ["41.06564474126483","70.33447685193849"],
            ["41.06551409471903","70.33477793626513"],
            ["41.06539339820851","70.33507352073195"],
            ["41.06532379082587","70.33528144880671"],
            ["41.06518157830077","70.33554936079624"],
            ["41.0650171176145","70.33585141321126"],
            ["41.06498549896247","70.33599002255376"],
            ["41.06490819724536","70.33618735320279"],
            ["41.06483291198128","70.33637964366929"],
            ["41.06479744887288","70.33646433480993"],
            ["41.06477351838908","70.33655621092593"],
            ["41.06459716492221","70.33697733390075"],
            ["41.06440413423463","70.33719559603369"],
            ["41.06429814945615","70.33728571989818"],
            ["41.0638999673192","70.33760559810862"],
            ["41.06362111984179","70.33787375425138"],
            ["41.06342638935361","70.33827410613901"],
            ["41.06336541401339","70.33870181158613"],
            ["41.06331173238188","70.33910933439459"],
            ["41.06327020394181","70.33970729123325"],
            ["41.06322464099543","70.34024109510932"],
            ["41.06314765039498","70.34116159829676"],
            ["41.06298570873816","70.34205274807114"],
            ["41.06285098702863","70.3430663463397"],
            ["41.06282510705947","70.34407404240254"],
            ["41.0627483052435","70.34480376821551"],
            ["41.06269528973893","70.34550668683981"],
            ["41.06267374650647","70.34664877193542"],
            ["41.06267477249665","70.34728038115992"],
            ["41.06290597257356","70.34772368612693"],
            ["41.06326428440117","70.34929676913301"],
            ["41.06338293386892","70.35031614454763"],
            ["41.06304773958305","70.35133275963815"],
            ["41.06286689372686","70.3523019644081"],
            ["41.0625716367443","70.35274548441843"],
            ["41.0626646615778","70.3534599017559"],
            ["41.06247611389045","70.35426313821371"],
            ["41.06219487909997","70.35485530873127"],
            ["41.06190042207566","70.35544811693336"],
            ["41.06168922288262","70.35579638531469"],
            ["41.06168091630426","70.35597023680621"],
            ["41.06181890461917","70.35615636364717"],
            ["41.06208122580978","70.35615897623191"],
            ["41.06255727525468","70.35618991590736"],
            ["41.0635440899045","70.35653721282561"],
            ["41.0644164923012","70.35686621266524"],
            ["41.06497450741572","70.35735014871237"],
            ["41.06554111429197","70.35789076313976"],
            ["41.06590617020744","70.35828308815745"],
            ["41.06612022669371","70.35835787440017"],
            ["41.06654771521588","70.35837863151424"],
            ["41.06689637597047","70.35829728017262"],
            ["41.06704087095195","70.35810700771167"],
            ["41.06751043583092","70.35791501123114"],
            ["41.0689599553458","70.35768176900612"],
            ["41.06923960089154","70.35738252583981"],
            ["41.06961015871343","70.35686644859643"],
            ["41.07033055936214","70.35579089182923"],
            ["41.07050876903541","70.35535660681568"],
            ["41.07079380883884","70.35478234868093"],
            ["41.07125729827843","70.35410652728716"],
            ["41.07187742699018","70.35327347603199"],
            ["41.07289622885277","70.35307893022032"],
            ["41.07438744547176","70.3527337781308"],
            ["41.07602060727425","70.3522947542629"],
            ["41.07709786178828","70.35210292011141"],
            ["41.07977750041543","70.35146132016777"],
            ["41.08010687270441","70.35149748201219"],
            ["41.08125664674553","70.35065189906281"],
            ["41.0817014715895","70.35090355871965"],
            ["41.08193897373153","70.35110399069217"],
            ["41.08265405802229","70.35154843119265"],
            ["41.08271871539805","70.35201502791577"],
            ["41.08289194898606","70.35269392719185"],
            ["41.08309682759111","70.35379139610835"],
            ["41.08320319363274","70.35472489404955"],
            ["41.08352810092637","70.35602942228705"],
            ["41.08374920815627","70.35822858423688"],
            ["41.08385941290641","70.35839797314851"],
            ["41.08393996233073","70.35844907239006"],
            ["41.0840714508889","70.35841273210568"],
            ["41.08431188764685","70.35830795595982"],
            ["41.08446259626494","70.35824133104987"],
            ["41.08451102251361","70.35827749358565"],
            ["41.08457890962984","70.35836189051516"],
            ["41.08460621489353","70.35845351548843"],
            ["41.084666582389","70.35854937043032"],
            ["41.08479103244437","70.35867219583213"],
            ["41.08493628616679","70.35875453544976"],
            ["41.08509202161924","70.35883655184358"],
            ["41.08524544724465","70.3589643692705"],
            ["41.08519457689922","70.35917394913614"],
            ["41.08521038084015","70.3595924486535"],
            ["41.08527390925664","70.36051097785821"],
            ["41.08533578611274","70.36161210764203"],
            ["41.08551541630187","70.36223915227285"],
            ["41.08562383455816","70.36313896441834"],
            ["41.08599091371555","70.36427438529206"],
            ["41.08607264447485","70.36468808529327"],
            ["41.08608378167841","70.36516373642246"],
            ["41.08611757717832","70.36537161254979"],
            ["41.08619421182305","70.36552570218591"],
            ["41.08623516819428","70.36562233348096"],
            ["41.08629258222521","70.36585000113968"],
            ["41.08634451992428","70.36613619865716"],
            ["41.08632974884114","70.36632227435803"],
            ["41.08640418536103","70.36656436921143"],
            ["41.08655976785766","70.36689159510045"],
            ["41.08667137763556","70.36722661997321"],
            ["41.08677029683098","70.36749886414877"],
            ["41.08684650090671","70.36789882308028"],
            ["41.08678426770879","70.36890514671845"],
            ["41.08681582689602","70.36946926166586"],
            ["41.08707683328558","70.37014330751735"],
            ["41.08735797359387","70.37041796168026"],
            ["41.08832492930126","70.37070475164278"],
            ["41.08888351577432","70.37105675102596"],
            ["41.09055881924813","70.37464900253515"],
            ["41.09064791680713","70.37481304703816"],
            ["41.09074590741745","70.37493025797184"],
            ["41.09084294925802","70.37494661439271"],
            ["41.09103984849691","70.37503275225028"],
            ["41.09126026904963","70.3751821923794"],
            ["41.09212425517298","70.37605503482044"],
            ["41.09275498014669","70.37658639210586"],
            ["41.09351847400727","70.37686295094205"],
            ["41.09396544234654","70.37707607437351"],
            ["41.09421271989915","70.37729591758058"],
            ["41.09543932072923","70.37897340411629"],
            ["41.09633215575683","70.3802255341656"],
            ["41.09684223317925","70.38143288507072"],
            ["41.09694162992978","70.38234439775691"],
            ["41.09710580279879","70.38302861041061"],
            ["41.09763329066371","70.38456082372619"],
            ["41.09791769321809","70.38522743872457"],
            ["41.0981187642567","70.38607560385702"],
            ["41.09828308748183","70.38662707388062"],
            ["41.09825650212535","70.38702560244474"],
            ["41.09787883551894","70.38748059683113"],
            ["41.09735550172921","70.3881395775408"],
            ["41.096946903002","70.38870901178441"],
            ["41.09652227518084","70.38926483523883"],
            ["41.09636886852195","70.38950562792769"],
            ["41.09624002710155","70.38984632786061"],
            ["41.09614185228202","70.39023700380623"],
            ["41.09605633954029","70.39043884601799"],
            ["41.09595825635449","70.3906121741054"],
            ["41.0959143672481","70.39077352491617"],
            ["41.09583677916424","70.39086590151564"],
            ["41.09575486555486","70.39108025692818"],
            ["41.09567838721724","70.39128052377946"],
            ["41.09560215049571","70.39143251839293"],
            ["41.09556625928987","70.39157196965219"],
            ["41.09549524464152","70.3916694068251"],
            ["41.09537217047175","70.39189389054238"],
            ["41.09529132185948","70.3920656265942"],
            ["41.09528209321553","70.392216419969"],
            ["41.09537145268207","70.39235440496344"],
            ["41.09546181866293","70.39239622301061"],
            ["41.09555716309887","70.39241350306891"],
            ["41.09573767114248","70.39240390783296"],
            ["41.09586692435172","70.39238945988103"],
            ["41.09606163362296","70.39242514454871"],
            ["41.09632997542297","70.39237296608626"],
            ["41.0966759721379","70.39243054811732"],
            ["41.09705423088298","70.39252352366402"],
            ["41.09730709528478","70.39260353180696"],
            ["41.09755730761883","70.39269326622689"],
            ["41.09774763289","70.39272392048022"],
            ["41.09803180682917","70.39265541164873"],
            ["41.09828538178295","70.39260498021231"],
            ["41.09844987428384","70.39260659296302"],
            ["41.09872127482183","70.39255872915994"],
            ["41.09880591616849","70.39259325353927"],
            ["41.09889862655925","70.39267012560796"],
            ["41.09900781295718","70.39285070313538"],
            ["41.09906333870414","70.3930857483103"],
            ["41.09915241077911","70.39332445737644"],
            ["41.09925156617383","70.39344578055882"],
            ["41.09936554385227","70.39351346828654"],
            ["41.09954688246124","70.39355262842714"],
            ["41.10032539290165","70.39375078492129"],
            ["41.10064978923676","70.39400911889446"],
            ["41.10084418838123","70.39416411029755"],
            ["41.1009682754641","70.39425454201614"],
            ["41.10113318494545","70.39433616396163"],
            ["41.10160192730327","70.39427809520849"],
            ["41.1023016692048","70.39405469076135"],
            ["41.10279295871857","70.39386637175267"],
            ["41.10297655132523","70.39380327046072"],
            ["41.10314265164719","70.3937369234804"],
            ["41.10331209081561","70.39376934278317"],
            ["41.10347805046094","70.39377740711836"],
            ["41.10361639200824","70.39379041094804"],
            ["41.10445848604331","70.39393875538657"],
            ["41.10463938147603","70.39397630234963"],
            ["41.10490071872919","70.39410338778821"],
            ["41.10515822270219","70.39423024271464"],
            ["41.10585602755614","70.39466613015192"],
            ["41.10625082922892","70.39488019483048"],
            ["41.10641257143671","70.39525019034971"],
            ["41.10670443391371","70.39584555657626"],
            ["41.10695345290527","70.39648963023154"],
            ["41.10702006987798","70.39683677994886"],
            ["41.10704487004374","70.39737311073978"],
            ["41.10708096247781","70.39761734362779"],
            ["41.10737313204985","70.39810925421368"],
            ["41.10758247734673","70.39873465625193"],
            ["41.10765254626063","70.39914455991976"],
            ["41.10774276313536","70.39953879567985"],
            ["41.10797247943868","70.40043614630177"],
            ["41.10774717253801","70.4007348123488"],
            ["41.10696174400786","70.40166485120284"],
            ["41.10675968368148","70.40187901148978"],
            ["41.10640868099171","70.40200185832758"],
            ["41.10597676220255","70.40195177287427"],
            ["41.1051994471454","70.4022917792107"],
            ["41.10486443726396","70.40248625433992"],
            ["41.10478736956024","70.40271946107742"],
            ["41.104833096483","70.40309690608483"],
            ["41.10498352421042","70.40352122002325"],
            ["41.1050460532103","70.40373661543128"],
            ["41.10503172558933","70.40398281657798"],
            ["41.10505784266565","70.4043183644242"],
            ["41.10516479967433","70.40488482996319"],
            ["41.10530202443309","70.40538799882606"],
            ["41.10528204260913","70.4058736897048"],
            ["41.1053190825013","70.40635100524894"],
            ["41.10536557981901","70.40652400726449"],
            ["41.10545326131408","70.40663157596133"],
            ["41.10565010855655","70.40667846174294"],
            ["41.10587427531775","70.40665327626661"],
            ["41.10614515762276","70.40663038193776"],
            ["41.10625091308148","70.40666428582209"],
            ["41.10630853801826","70.40677755346375"],
            ["41.10630463228944","70.40688889198807"],
            ["41.10632039611306","70.40698558600576"],
            ["41.10643394457261","70.40745014135325"],
            ["41.10645689047897","70.40767317759833"],
            ["41.10650318359848","70.40788024280235"],
            ["41.10660918147243","70.40826669706783"],
            ["41.10674234895372","70.40864152710574"],
            ["41.10694300605224","70.40921089556917"],
            ["41.10713763164011","70.40959701420942"],
            ["41.10762894217218","70.41005286962458"],
            ["41.10826238616808","70.4103904650328"],
            ["41.10865446440307","70.41057848871139"],
            ["41.10920906522519","70.4106868286743"],
            ["41.11044813534554","70.41174159611114"],
            ["41.11089606121008","70.41212666034747"],
            ["41.11134333409857","70.41280639331505"],
            ["41.11162838710315","70.41333072011645"],
            ["41.11169468633366","70.41355084525887"],
            ["41.11156809079842","70.41402087868103"],
            ["41.11148727703724","70.4144484198432"],
            ["41.11142872376662","70.41472895938954"],
            ["41.11140081836435","70.41488109253604"],
            ["41.11134661337967","70.41508634614235"],
            ["41.11118169108604","70.41529627782015"],
            ["41.11097435562225","70.41555109319305"],
            ["41.11075649469252","70.4157299020971"],
            ["41.10996066884819","70.41601932809225"],
            ["41.10967549975944","70.41618127331762"],
            ["41.109513124328","70.41635443490317"],
            ["41.10919341356313","70.41663157443381"],
            ["41.10906649146298","70.41674241729686"],
            ["41.1087796285785","70.41679285287586"],
            ["41.10837629723118","70.41701242914262"],
            ["41.10811124867967","70.41726779582523"],
            ["41.10796116674658","70.41745463997859"],
            ["41.10795959620663","70.41799633793921"],
            ["41.10792133143482","70.41827377932026"],
            ["41.10796371545794","70.41851420987169"],
            ["41.10788858906851","70.41872634551642"],
            ["41.10772221509985","70.41896222763368"],
            ["41.10761801470945","70.4191704862404"],
            ["41.10741140441176","70.41957758614684"],
            ["41.10729399970584","70.41986130021164"],
            ["41.10691790831423","70.42013071854092"],
            ["41.10676941934527","70.42030795241227"],
            ["41.10664776048976","70.42049676424986"],
            ["41.10653063405166","70.42083241833623"],
            ["41.10640481681922","70.42129096971664"],
            ["41.10635725911873","70.42173882662374"],
            ["41.10624411300307","70.42292420749099"],
            ["41.10606594535468","70.42327872435007"],
            ["41.10589057067854","70.4237739729018"],
            ["41.10577461374305","70.42415471625898"],
            ["41.10561125136788","70.42468814621664"],
            ["41.10546102205066","70.42499847523493"],
            ["41.10529017510657","70.42520551791246"],
            ["41.1051317341385","70.42553085163699"],
            ["41.10508608727636","70.42595518890957"],
            ["41.10518091309595","70.42659831676144"],
            ["41.10532180515742","70.42791719809917"],
            ["41.10549794346876","70.42935723453309"],
            ["41.10550828487199","70.43030198292881"],
            ["41.10540390634481","70.43118622100756"],
            ["41.10520610179864","70.43168213469792"],
            ["41.10502908699035","70.43217377065183"],
            ["41.10493476162927","70.43258472212001"],
            ["41.10494511133861","70.43283797749929"],
            ["41.10496444481711","70.43368282409703"],
            ["41.10497098727954","70.43438059513011"],
            ["41.10498501838256","70.43457367624433"],
            ["41.10507218147068","70.43472665838797"],
            ["41.10527520646281","70.4350139452433"],
            ["41.10557192481613","70.4352858989291"],
            ["41.10586390194518","70.43564366601349"],
            ["41.10603096615121","70.43614021080899"],
            ["41.10623158346117","70.43622832718353"],
            ["41.10659856375289","70.43639220708317"],
            ["41.10684515247895","70.43661358370201"],
            ["41.10702385889664","70.43666434001068"],
            ["41.1072295712475","70.43676104984985"],
            ["41.10746165987494","70.43691438629327"],
            ["41.10770076452661","70.4371334953962"],
            ["41.10781936457614","70.43746450692382"],
            ["41.10793212758401","70.4380891249031"],
            ["41.10787783275382","70.43878172677967"],
            ["41.10777656362107","70.43935637611666"],
            ["41.10760732460533","70.43977886518873"],
            ["41.10722203641342","70.44030069613645"],
            ["41.10663487044116","70.44098642249398"],
            ["41.10598647938507","70.44145098748442"],
            ["41.1054074229346","70.44199497309975"],
            ["41.10462046401994","70.44280503213325"],
            ["41.10423355183465","70.44309664277267"],
            ["41.10358641629341","70.44370412521263"],
            ["41.10260077858297","70.44484885643587"],
            ["41.10246449940964","70.44499624102947"],
            ["41.10239038106761","70.44521198819595"],
            ["41.10237009320012","70.44547856555461"],
            ["41.10237180304409","70.44580109426417"],
            ["41.10238917055933","70.44610123468293"],
            ["41.10260510845309","70.44683826725323"],
            ["41.10268502597375","70.44702690552826"],
            ["41.10274635801677","70.44726281364029"],
            ["41.10280809766369","70.44774832021211"],
            ["41.10274714713164","70.44803234851059"],
            ["41.10267428566997","70.44838030805352"],
            ["41.10264909743171","70.44872505954008"],
            ["41.10265895707019","70.44913909072372"],
            ["41.10270234959632","70.44966092460888"],
            ["41.10266545264631","70.45002863315345"],
            ["41.10264782258869","70.45101514100736"],
            ["41.10268638926489","70.45174933016722"],
            ["41.10269105280932","70.45214706213642"],
            ["41.10263926451823","70.4523458662693"],
            ["41.10257316756349","70.45246133411784"],
            ["41.10241473986147","70.45254201734026"],
            ["41.10225774370659","70.45267177949553"],
            ["41.10201986234699","70.45291738635396"],
            ["41.10187829339146","70.45313297370835"],
            ["41.10182425099129","70.45329618068766"],
            ["41.1017855188955","70.45350293174981"],
            ["41.1017355301115","70.45369339603528"],
            ["41.101767986314","70.45393795949582"],
            ["41.10179032466317","70.4542361269183"],
            ["41.10175500155039","70.45440990538778"],
            ["41.1016182679018","70.4546192038529"],
            ["41.10156344736888","70.45483083876429"],
            ["41.10152743414128","70.45497147888399"],
            ["41.10150593649399","70.45514748610255"],
            ["41.10146185906376","70.45533379130147"],
            ["41.10144273247945","70.45552027428097"],
            ["41.10138315772836","70.45600233929798"],
            ["41.10131268488431","70.45611904123719"],
            ["41.10121033739307","70.45618531424016"],
            ["41.10111779794125","70.45629191763716"],
            ["41.10101481161795","70.45645108920931"],
            ["41.10088780568335","70.45665839093495"],
            ["41.10076693866616","70.45680867565731"],
            ["41.10070689955528","70.45686557688423"],
            ["41.10062491368461","70.4570591438918"],
            ["41.10055256032239","70.45720966482249"],
            ["41.10047441884402","70.45739209980232"],
            ["41.10038191204756","70.45763786477252"],
            ["41.1002975052416","70.4578343736349"],
            ["41.10021747805238","70.45806236313815"],
            ["41.10014276816484","70.45824826947479"],
            ["41.10008514133059","70.45842021270973"],
            ["41.09996156929454","70.45862476907928"],
            ["41.09985442728952","70.45879426350436"],
            ["41.09969625703327","70.45918138144465"],
            ["41.09964252724641","70.45936350525848"],
            ["41.09958037797947","70.45951974747116"],
            ["41.09949654938883","70.45973757873465"],
            ["41.09941088000997","70.45992351062144"],
            ["41.09932795738201","70.4600880302719"],
            ["41.09922276208355","70.46028850783819"],
            ["41.09912713559747","70.46054117757336"],
            ["41.09904970127253","70.46074419556895"],
            ["41.09897977816552","70.46092817429891"],
            ["41.09890021562363","70.461122816781"],
            ["41.09870134184826","70.46143551872152"],
            ["41.09857798723828","70.46169113859793"],
            ["41.09843030126672","70.46191160962104"],
            ["41.09828470534976","70.46218125402487"],
            ["41.09826798002104","70.46242644654245"],
            ["41.09823478528028","70.46260312298917"],
            ["41.09808736260127","70.46292517492705"],
            ["41.09802052708565","70.46313389860816"],
            ["41.09801402591784","70.46331993189588"],
            ["41.09797288915537","70.46354793290664"],
            ["41.09792636009671","70.46368279250453"],
            ["41.09789205282601","70.46393674928034"],
            ["41.09788424468467","70.46414653192004"],
            ["41.09797560880286","70.46432022392517"],
            ["41.09816581590973","70.46453469194951"],
            ["41.09830905257978","70.46473543954794"],
            ["41.09840368030873","70.46489503785223"],
            ["41.09850637508092","70.46507011488228"],
            ["41.09861657952498","70.46561963993366"],
            ["41.0986801303676","70.46578449209346"],
            ["41.09872371627267","70.46590002584287"],
            ["41.09881543428421","70.46599690784198"],
            ["41.09889646610572","70.46607342418073"],
            ["41.09898494622561","70.4661730494994"],
            ["41.09906449178904","70.46626879428374"],
            ["41.09916691585624","70.4663601123704"],
            ["41.09920636720897","70.46649013394195"],
            ["41.09919129176859","70.46664691602849"],
            ["41.09916845814071","70.46684482707255"],
            ["41.09915743556132","70.467014562274"],
            ["41.09914387767195","70.46745458272396"],
            ["41.09915942417511","70.46757595255764"],
            ["41.09918677871018","70.46774590743489"],
            ["41.09923748266753","70.46785223776109"],
            ["41.09929613322579","70.46797565519887"],
            ["41.09948761543067","70.46822368261346"],
            ["41.09954278062975","70.46835648087318"],
            ["41.09961786342328","70.46854412278219"],
            ["41.09968741765376","70.46887517099832"],
            ["41.09973800041871","70.46902233495761"],
            ["41.09983005634733","70.46939404647198"],
            ["41.09986492797751","70.46945959707176"],
            ["41.10006461295555","70.46983839638233"],
            ["41.10018993590698","70.47014723224422"],
            ["41.10028773703738","70.47035398804755"],
            ["41.10043238769343","70.47057513901605"],
            ["41.10048953932321","70.47095185450323"],
            ["41.10053775647842","70.47138378738575"],
            ["41.10055272403114","70.47179411329175"],
            ["41.10053368702246","70.47193403527729"],
            ["41.10048310032428","70.47206102939757"],
            ["41.1004040627269","70.4722167766181"],
            ["41.10035545907727","70.47231833682395"],
            ["41.10028362926494","70.4724803760669"],
            ["41.1001401609552","70.47276296518963"],
            ["41.09996807968545","70.4731146931117"],
            ["41.09989661028195","70.47321692869468"],
            ["41.09973968811024","70.47332407655101"],
            ["41.09958426543655","70.47349619664998"],
            ["41.09957527418495","70.47368639867202"],
            ["41.09956398579054","70.47385621746953"],
            ["41.09955769802975","70.47391667941601"],
            ["41.09948137416239","70.47404912055366"],
            ["41.09939977308883","70.47414505857375"],
            ["41.09921028623698","70.4745408562671"],
            ["41.09916045190423","70.47458866539137"],
            ["41.09902263768908","70.47480997952036"],
            ["41.09891897042348","70.47498661963095"],
            ["41.09879319508502","70.47515053360328"],
            ["41.09875214555926","70.47524873990538"],
            ["41.09863670869873","70.47540655385531"],
            ["41.09849798385456","70.47559119411363"],
            ["41.09839889801298","70.47581579236686"],
            ["41.09832852077011","70.47593079147958"],
            ["41.09813923474603","70.47624646241775"],
            ["41.09796352395037","70.47650307839952"],
            ["41.09785560600377","70.47658667443544"],
            ["41.09754924796262","70.47696435164863"],
            ["41.09745126191628","70.47707386235388"],
            ["41.09731007775336","70.47728007320138"],
            ["41.09725842332178","70.47743383601261"],
            ["41.09714149074407","70.47762064797314"],
            ["41.09710304765388","70.47797563527834"],
            ["41.09708362872841","70.47829295215654"],
            ["41.09702021021885","70.47851695857689"],
            ["41.09694701046922","70.47866804727812"],
            ["41.09686561268671","70.47882308344657"],
            ["41.09675438705563","70.47899660479645"],
            ["41.09669206147762","70.47908127982893"],
            ["41.09641672938521","70.47926494652008"],
            ["41.09627936160064","70.47933247182416"],
            ["41.09618413101841","70.47947530401778"],
            ["41.09607292796702","70.47960603500633"],
            ["41.09602687774438","70.47965675445779"],
            ["41.09594529750925","70.47978654817203"],
            ["41.09583546540215","70.47989544182332"],
            ["41.09569173767911","70.47999836817401"],
            ["41.09558791583669","70.48005496773692"],
            ["41.09543680489296","70.4801575939963"],
            ["41.09529042910507","70.48022080008583"],
            ["41.09511342612458","70.48030420603412"],
            ["41.09504390594788","70.48034891281939"],
            ["41.09496621289042","70.48038633008244"],
            ["41.09482547890441","70.480498984302"],
            ["41.09465009070431","70.48062312557838"],
            ["41.09448317650003","70.48072375239376"],
            ["41.09437438388869","70.48083354238766"],
            ["41.09398166202186","70.48103267733885"],
            ["41.09383821692445","70.48105367971144"],
            ["41.09374906161479","70.48111969306056"],
            ["41.09362638971751","70.48115012103054"],
            ["41.09347515362837","70.4811739153612"],
            ["41.09335455796214","70.48122099587826"],
            ["41.09324345303164","70.48131573330257"],
            ["41.09311832266766","70.48135992856957"],
            ["41.09312170633625","70.48136698400107"],
            ["41.09283111087854","70.48142007046955"],
            ["41.09247444855063","70.48146057590739"],
            ["41.09226170992454","70.48151057951493"],
            ["41.09197682690841","70.48153588899874"],
            ["41.09171009473049","70.48156202863565"],
            ["41.0915557318757","70.48158803750681"],
            ["41.09139555282638","70.48159569050027"],
            ["41.09135325575632","70.48175429535544"],
            ["41.09127954994424","70.48188624064925"],
            ["41.09121111446074","70.48209279252737"],
            ["41.09107788160022","70.48245074486871"],
            ["41.09096630300924","70.48270309860345"],
            ["41.09091722482673","70.48283082535536"],
            ["41.09079121482569","70.48302892561082"],
            ["41.0906715014246","70.48331828704856"],
            ["41.09057943325777","70.48350723041916"],
            ["41.09055490308273","70.48371510509618"],
            ["41.09047867309336","70.48418727521394"],
            ["41.09049828423088","70.48465294579368"],
            ["41.09056169122424","70.48590206562798"],
            ["41.09061486852507","70.48692315549485"],
            ["41.09052985690983","70.4872713019402"],
            ["41.09047967590903","70.48756506645817"],
            ["41.09041048605429","70.4877698168532"],
            ["41.09027927781339","70.48789410620404"],
            ["41.09023876902297","70.48799721281966"],
            ["41.09010941594701","70.48812950136083"],
            ["41.09006553127869","70.48818407105171"],
            ["41.08998753484411","70.48831538507189"],
            ["41.08988413805659","70.48861270942089"],
            ["41.08976325814139","70.48902705453975"],
            ["41.08964576059298","70.48947072457398"],
            ["41.08960474691772","70.4896044025827"],
            ["41.08964967002675","70.48992675646727"],
            ["41.08962879794598","70.49016648947952"],
            ["41.0896185322534","70.49064033148758"],
            ["41.08965300663861","70.49108433955475"],
            ["41.08970671118644","70.49143286006039"],
            ["41.0897341572428","70.49179553094592"],
            ["41.08969667579465","70.4919634620978"],
            ["41.08959406889845","70.49220681195811"],
            ["41.08937942656528","70.49242772676061"],
            ["41.08905183308937","70.4928043514208"],
            ["41.08890599041456","70.49333716555326"],
            ["41.08887189943481","70.49364488517183"],
            ["41.08896195386627","70.49433268163637"],
            ["41.08906830840866","70.49470575287553"],
            ["41.08932218747444","70.4950299147518"],
            ["41.08973550330242","70.49561475853204"],
            ["41.08977365408049","70.49576816170294"],
            ["41.08973438654563","70.49595683717376"],
            ["41.08966188047101","70.49632568491984"],
            ["41.08964688671188","70.49659530600748"],
            ["41.08970537012571","70.49688810022162"],
            ["41.08970316686983","70.49725263422648"],
            ["41.08976399771682","70.49757634170157"],
            ["41.08993401392397","70.49859089016907"],
            ["41.09016911010269","70.49935647205116"],
            ["41.09037101372557","70.50000189075864"],
            ["41.0905930150916","70.50060175132157"],
            ["41.09064145061636","70.50111350434067"],
            ["41.09065965655027","70.50158339249145"],
            ["41.0906987185142","70.50202480910575"],
            ["41.0908311108898","70.50232023312562"],
            ["41.09100593708744","70.50253635623021"],
            ["41.09123705982909","70.50283346509778"],
            ["41.09140995016971","70.50314683927141"],
            ["41.09163788408142","70.50363598765628"],
            ["41.09197544485004","70.50421249645458"],
            ["41.09222388514691","70.5047229908604"],
            ["41.09242272025513","70.5050472744034"],
            ["41.09260441058394","70.50533193831249"],
            ["41.09285415285472","70.50544411055787"],
            ["41.09353944124126","70.50565987490099"],
            ["41.09402878887808","70.50601259073055"],
            ["41.09440932027154","70.50649845773671"],
            ["41.09446533667977","70.50676851058395"],
            ["41.09440252442286","70.50712477343399"],
            ["41.09435394378888","70.50772399112499"],
            ["41.09431662185173","70.5083465452893"],
            ["41.09422731753955","70.50942141455636"],
            ["41.09416727233753","70.51003564832247"],
            ["41.09413155273675","70.51035919870793"],
        ]},
    {latlngs: [
            ["40.95831800727692","70.0595499809439"],
            ["40.95855613231208","70.06058925276359"],
            ["40.958771873609","70.06141378246812"],
            ["40.95911752178626","70.06264776335233"],
            ["40.95937424228428","70.06367317965784"],
            ["40.95955971400704","70.06434288623511"],
            ["40.95975840168953","70.06516043960204"],
            ["40.95994846594414","70.06592322229405"],
            ["40.96009444635233","70.06662450030002"],
            ["40.96037220245451","70.06792204850794"],
            ["40.96054355806331","70.06857987965908"],
            ["40.96071015069819","70.06932930636194"],
            ["40.96091574306868","70.07010187624316"],
            ["40.96109699008804","70.07090569452073"],
            ["40.96125780546844","70.07162330560527"],
            ["40.96140445528752","70.07233380405039"],
            ["40.96152903752363","70.07285085061866"],
            ["40.96164201232899","70.07331885581007"],
            ["40.96179346268701","70.07393803447512"],
            ["40.9619208085772","70.07432729699016"],
            ["40.96204935374428","70.07478134123097"],
            ["40.9622495056128","70.07542646256226"],
            ["40.96243797196632","70.07602341392325"],
            ["40.96291216948706","70.07708858293765"],
            ["40.96381107979943","70.07796841317477"],
            ["40.96404393033959","70.0782402412957"],
            ["40.96438120409582","70.07860632333617"],
            ["40.96467886768216","70.07903106507386"],
            ["40.96497066728408","70.07978455228157"],
            ["40.96503718892067","70.08059333103594"],
            ["40.96507329846228","70.08105540645879"],
            ["40.96514450505128","70.0813872894109"],
            ["40.96542978827308","70.08263231240437"],
            ["40.96568981016165","70.08348708093716"],
            ["40.96615660430485","70.08490345243426"],
            ["40.96639406574411","70.0856612124073"],
            ["40.96689487698116","70.08714691914415"],
            ["40.9674415881369","70.08859935335646"],
            ["40.96768496085043","70.08925024169633"],
            ["40.96794035493006","70.08997084323012"],
            ["40.96819873529559","70.09067379717521"],
            ["40.96845829656508","70.09138629242601"],
            ["40.96870625009252","70.09204240617473"],
            ["40.9689427212029","70.09268943551403"],
            ["40.96917957887648","70.09333471023169"],
            ["40.96938762661896","70.0939110542412"],
            ["40.96964942892084","70.09458418756567"],
            ["40.96990626943483","70.09523227794865"],
            ["40.97015020400481","70.09595592833051"],
            ["40.97011952332376","70.09670341601931"],
            ["40.97022315819516","70.09722112227611"],
            ["40.97039122872735","70.0976621284663"],
            ["40.97049037846265","70.09812974380519"],
            ["40.97059651062504","70.09857274228364"],
            ["40.97068397670852","70.09898041223934"],
            ["40.97083525045203","70.09944218615071"],
            ["40.97096210713342","70.10005031165994"],
            ["40.97111498782775","70.10066772024682"],
            ["40.97136317847977","70.10124428142466"],
            ["40.97175525330646","70.10207318305062"],
            ["40.97196195061643","70.10250606297031"],
            ["40.97224180709428","70.10295774171531"],
            ["40.97245955614861","70.10330459263662"],
            ["40.97278269622795","70.10380702913277"],
            ["40.97301446149869","70.10421791767918"],
            ["40.97333511456063","70.10472369763947"],
            ["40.97359614139624","70.10527625000829"],
            ["40.97387903044385","70.1058167835742"],
            ["40.9742108605014","70.10646076277546"],
            ["40.97449188974161","70.10704458887001"],
            ["40.97481148495583","70.10743353822849"],
            ["40.975224743596","70.10789655099204"],
            ["40.9756016592181","70.10842756262076"],
            ["40.97603820253713","70.10884959172202"],
            ["40.97643405411078","70.10932126029709"],
            ["40.97686099290485","70.10978729702499"],
            ["40.97721442690358","70.11026312305248"],
            ["40.9775630496523","70.11080733663911"],
            ["40.97793608468859","70.11131427139037"],
            ["40.97829546909782","70.11183659339126"],
            ["40.97865621153486","70.11233636439722"],
            ["40.97898718957624","70.11285937458652"],
            ["40.97937590189322","70.11335247966885"],
            ["40.97971085798944","70.11389200456819"],
            ["40.98004639409282","70.11434230533301"],
            ["40.98047327009081","70.11475129172034"],
            ["40.98094179570052","70.1151462973436"],
            ["40.98138697417626","70.11553348876353"],
            ["40.98181831000557","70.11589313770422"],
            ["40.98228685385735","70.11629041638977"],
            ["40.98270706331445","70.11676290399393"],
            ["40.98320579820805","70.11747784320862"],
            ["40.98369143479414","70.11817871300183"],
            ["40.98384496985029","70.11844853328749"],
            ["40.98397747592724","70.11879931643165"],
            ["40.98410003987106","70.11912696690216"],
            ["40.98423609487617","70.11949081850331"],
            ["40.98433149078231","70.11974811018284"],
            ["40.98432198367193","70.11992584742121"],
            ["40.98428597179883","70.12022870302859"],
            ["40.98425031836062","70.12062844662753"],
            ["40.98421873572119","70.12108885159287"],
            ["40.98418319004657","70.12167058656283"],
            ["40.98409253641551","70.1224515417788"],
        ]},
    {latlngs: [
            ["40.57349049303703","72.19334653067175"],
            ["40.58317153621153","72.20328878128234"],
            ["40.58583210601527","72.20559727394448"],
            ["40.58746302401972","72.20808051942016"],
            ["40.58993378895326","72.21207799796112"],
            ["40.59363102568","72.21609945009746"],
            ["40.5970737745788","72.21976176244476"],
            ["40.59897318051024","72.2231687039889"],
            ["40.59969803081727","72.22596173649754"],
            ["40.60024620755627","72.22952264421598"],
            ["40.60139349298118","72.23415906074223"],
            ["40.60273736154009","72.23700529558924"],
            ["40.60384572090777","72.23888586727486"],
            ["40.60431096890485","72.24009345877987"],
            ["40.60513507058323","72.24262069124811"],
            ["40.60506835468965","72.24577085200562"],
            ["40.60514605833087","72.24744770292544"],
            ["40.60523013785758","72.2482104938013"],
            ["40.60608312381014","72.2502255275166"],
            ["40.60690981170629","72.25184960163665"],
            ["40.6083740803826","72.25504407269031"],
            ["40.60891287171245","72.25547740577709"],
            ["40.61000356161469","72.25613598068584"],
            ["40.61048346561667","72.25665618680092"],
            ["40.61081008294412","72.25773805149531"],
            ["40.61117713787419","72.2590354832864"],
            ["40.61199358499249","72.26152078935378"],
            ["40.61243104868436","72.26224934669901"],
            ["40.61371981350874","72.26350935576282"],
            ["40.61562464048018","72.26355548773184"],
            ["40.61611343695392","72.26409459923052"],
            ["40.61625116342282","72.26464259396721"],
            ["40.61709017594475","72.26652881747184"],
            ["40.61761219642806","72.26875705353149"],
            ["40.61762445374126","72.27100478480025"],
            ["40.61755726693317","72.27228855498629"],
            ["40.61838674671376","72.27446988863315"],
            ["40.61924404729238","72.2760628246442"],
            ["40.62012183302123","72.27737327629538"],
            ["40.621233775788","72.27833529623371"],
            ["40.62199163782647","72.27897436986257"],
            ["40.62308193434126","72.27994256580754"],
            ["40.62496787755425","72.28177246789441"],
            ["40.62589431362517","72.28286489976232"],
            ["40.6264902971378","72.28346577506186"],
            ["40.62765232394923","72.28417870483104"],
            ["40.6282732736065","72.28453462977475"],
            ["40.62890519873733","72.28460710447996"],
            ["40.62955829704536","72.28453977369053"],
            ["40.6312346186815","72.28470523337911"],
            ["40.63207654628371","72.28519149982287"],
            ["40.63284904204136","72.28566855572809"],
            ["40.63352462039741","72.28573787523302"],
            ["40.63374145462615","72.28577365471409"],
            ["40.63556922538977","72.28489739488317"],
            ["40.63585635215913","72.28538454295546"],
            ["40.6360769929446","72.28626501115241"],
            ["40.6365837272139","72.28775953323148"],
            ["40.63720070231715","72.28885727841994"],
            ["40.63814289217427","72.29008060493742"],
            ["40.63872539223535","72.29083926918437"],
            ["40.63936149173951","72.29158122203444"],
            ["40.64026558510498","72.29290520950116"],
            ["40.64115835872823","72.29358198778574"],
            ["40.64167298129908","72.29375535131838"],
            ["40.64218100964033","72.29367405757718"],
            ["40.64369251435112","72.29346272383627"],
            ["40.64484967037774","72.29315567738161"],
            ["40.64660920919765","72.29311252711075"],
            ["40.64747660943863","72.29289853995508"],
            ["40.6503239385434","72.29238804550492"],
            ["40.65115382009544","72.29224653502486"],
            ["40.65189972884032","72.29223401246161"],
        ]},
    {latlngs: [
            ["40.57440941580467","72.19364414161936"],
            ["40.58339601104603","72.2019141425103"],
            ["40.58598364970956","72.20504481391069"],
            ["40.5904071899061","72.21096708209868"],
            ["40.5942295602588","72.21508232433146"],
            ["40.59622061866786","72.21722996105045"],
            ["40.59698832011297","72.21807059829928"],
            ["40.59934266818451","72.21916013354277"],
            ["40.60203970463046","72.22035787784782"],
            ["40.60577704398787","72.22198729251434"],
            ["40.60775168645962","72.22414922888488"],
            ["40.60992522805609","72.22764962245959"],
            ["40.61025739984792","72.22895288631437"],
            ["40.61059430954731","72.23143003696202"],
            ["40.61100041363269","72.23422462258256"],
            ["40.61148970907144","72.23756701550219"],
            ["40.61318636048414","72.24217786429274"],
            ["40.6144868887793","72.24527661655671"],
            ["40.61622539832079","72.25026508142379"],
            ["40.61688318714754","72.25244578052605"],
            ["40.61930574720641","72.25727380014305"],
            ["40.62044932537925","72.25995198983495"],
            ["40.62344276856193","72.26343532991105"],
            ["40.62372802277314","72.26359929845938"],
            ["40.62383161909816","72.26381591461336"],
            ["40.62379054695442","72.26428486285668"],
            ["40.62355444409465","72.26459645298424"],
            ["40.62323256736838","72.26476235327966"],
            ["40.62305524708658","72.26485291122748"],
        ]},
    {latlngs: [
            ["40.62308667742888","72.26492216519402"],
            ["40.62315912091217","72.26701296398002"],
            ["40.62278875725882","72.2704852170713"],
            ["40.62264001172089","72.27146557045363"],
            ["40.62327256334724","72.27237745822826"],
            ["40.62426982055699","72.27388260441307"],
            ["40.62469520824097","72.27546009270603"],
            ["40.62437726657956","72.2766707459329"],
            ["40.62424171423066","72.27746997466129"],
            ["40.62525287823578","72.2792312787588"],
            ["40.62635108264585","72.2812463552072"],
            ["40.62760223421245","72.2819452043187"],
            ["40.62876364149925","72.28261135108023"],
            ["40.63034199204674","72.2836062492399"],
            ["40.63174141991257","72.28480439383181"],
            ["40.63300152319602","72.28557227446605"],
            ["40.63480328083709","72.28456591990339"],
            ["40.63560567967374","72.28414794932458"],
            ["40.63573334960254","72.28490991510627"],
            ["40.63583515657093","72.28535643165293"],
        ]},
    {latlngs: [
            ["40.63576705226623","72.28448197277214"],
            ["40.63617734108355","72.28373120542773"],
            ["40.63657592954023","72.28291227264243"],
            ["40.6370017770591","72.2822994262917"],
            ["40.63747914636799","72.28247883256552"],
            ["40.63792100771472","72.28265526453892"],
            ["40.63833045846379","72.28277064393278"],
            ["40.63877097550108","72.28252862593592"],
            ["40.63921713904045","72.28223237494119"],
            ["40.64134068575214","72.28113048976994"],
            ["40.64245903325204","72.28039008597842"],
            ["40.64383033506706","72.27875653112284"],
            ["40.64491635233766","72.27716543879924"],
            ["40.64560613035279","72.27593473782102"],
            ["40.64633496808813","72.27500256617373"],
            ["40.64635205101566","72.27467649261123"],
            ["40.64608692718993","72.27402725849188"],
            ["40.64595680226535","72.27363992164868"],
        ]},
    {latlngs: [
            ["40.65189312642826","72.29223019017776"],
            ["40.65409885940428","72.29192312593361"],
            ["40.65669163960587","72.29193798537591"],
            ["40.65820282591974","72.29138636414633"],
            ["40.65982626070439","72.2900332932513"],
            ["40.6600894139039","72.28971800939625"],
            ["40.66074758158812","72.28933700445046"],
            ["40.66207852062799","72.28757707300048"],
            ["40.66302723849632","72.28641329799049"],
            ["40.66404980489948","72.28510404958226"],
            ["40.66578475240953","72.28302930241"],
            ["40.66709999929177","72.28206518810163"],
            ["40.66796280975768","72.28139612661569"],
            ["40.6684085678873","72.28081722896734"],
            ["40.66906673635147","72.28125886522368"],
            ["40.67009008690422","72.28278343917007"],
            ["40.67065914390615","72.28363189993988"],
            ["40.67142346708582","72.28513577794541"],
            ["40.67222682919486","72.28647777211557"],
            ["40.67299862075844","72.28789163188361"],
            ["40.67451780011331","72.289846768047"],
            ["40.67590070533927","72.29081277115358"],
            ["40.67767766099389","72.29223060988724"],
            ["40.67850183724873","72.29329608064697"],
            ["40.67888445704877","72.29423491979637"],
            ["40.67942488022335","72.29501604126823"],
            ["40.67971599510016","72.29535888593425"],
            ["40.68018438765515","72.29554608063471"],
            ["40.6819216694495","72.29790107429517"],
            ["40.68286442062671","72.2993262836342"],
            ["40.68487814669724","72.30128844765706"],
            ["40.68576387207872","72.30188692315642"],
            ["40.68711375784609","72.30517477577497"],
            ["40.68815606318745","72.30734831809713"],
            ["40.68932726175144","72.30927044184509"],
            ["40.69031448888936","72.30999445129328"],
            ["40.69121989525954","72.31134641938826"],
            ["40.69193613202125","72.3127351027494"],
            ["40.69252280518764","72.31358923703176"],
            ["40.69324811861672","72.31461301792676"],
            ["40.69423343870968","72.31604713557718"],
            ["40.69448560275562","72.31736198568319"],
            ["40.69472002553976","72.31865651038794"],
            ["40.69504116608373","72.32061262909126"],
            ["40.69524275953699","72.32166974403428"],
            ["40.6952320297123","72.32206460964193"],
            ["40.69511111904472","72.32300954696785"],
            ["40.69464217825352","72.32396584396169"],
            ["40.69437214142572","72.32525487531522"],
            ["40.6939565903875","72.32614791818357"],
            ["40.69369151439438","72.32710142701036"],
            ["40.69381205201355","72.32913619333071"],
            ["40.69386861228668","72.32954569962357"],
            ["40.6939118242059","72.32998547279794"],
            ["40.69392997798621","72.3306685294698"],
            ["40.69428280827399","72.332194703894"],
            ["40.6946809545158","72.33313246392504"],
            ["40.69498124219268","72.33467358459757"],
            ["40.69510767934691","72.33558717513799"],
            ["40.69518655462546","72.33632785636398"],
            ["40.69539396388766","72.33818292640336"],
            ["40.69562614297661","72.33956491565722"],
            ["40.69567472089867","72.33996944665545"],
            ["40.6959572113641","72.34164492285421"],
            ["40.6961543757972","72.34279641506261"],
            ["40.69681069808434","72.34384717476593"],
            ["40.69691995633418","72.34499544016532"],
            ["40.69693374139314","72.34643862584436"],
            ["40.69673309811783","72.34716641927756"],
            ["40.69609156609791","72.34990007660312"],
            ["40.69633654216698","72.35096343007626"],
            ["40.69660787505541","72.35196938798113"],
            ["40.69683365145179","72.35323221996485"],
            ["40.69705225947003","72.35417249005279"],
            ["40.69740112493783","72.35691848366459"],
            ["40.69676634757476","72.35779910173243"],
            ["40.69633023249656","72.35834617959428"],
            ["40.6947476726294","72.36140183639222"],
            ["40.69453758326475","72.36192329252923"],
            ["40.69373282524379","72.36487491528985"],
            ["40.69332994691341","72.36588298467836"],
            ["40.69325937057825","72.36609821812122"],
            ["40.6924488114145","72.36795168486337"],
            ["40.69217943744397","72.36904724068229"],
            ["40.69209947294733","72.36953206795646"],
            ["40.69184178671722","72.37067232889773"],
            ["40.69158357962981","72.37119313524232"],
            ["40.69116217901738","72.37170434517168"],
            ["40.69013821290797","72.37327546012509"],
            ["40.68981101163058","72.3736855437927"],
            ["40.68851680417325","72.37648305118846"],
            ["40.68841802326659","72.37695874375467"],
            ["40.68754556032923","72.37909406437196"],
            ["40.6870303826589","72.38015796544164"],
            ["40.68654281672969","72.38097947979696"],
            ["40.68577597409499","72.38323649156962"],
        ]},
    {latlngs: [
            ["40.69535191460167","72.32223287846689"],
            ["40.69565461367919","72.32341165346739"],
            ["40.69701764618608","72.32621421092185"],
            ["40.6973391664103","72.32678959166849"],
            ["40.69769587232854","72.32786740798846"],
            ["40.69833703350803","72.32943930053133"],
            ["40.69864763753924","72.33119814405514"],
            ["40.69866426112611","72.3346740687134"],
            ["40.69852034634749","72.33554738487413"],
            ["40.6981678831721","72.33700215811041"],
            ["40.69787736941448","72.33901586057677"],
            ["40.69806489031326","72.34018836886764"],
            ["40.69812983455346","72.34178952342708"],
            ["40.69820695735167","72.34305758167666"],
            ["40.69823445864476","72.34419286062079"],
            ["40.69806152008154","72.34511924938386"],
            ["40.69757416373582","72.34627162634747"],
            ["40.69712521033955","72.34715799535162"],
            ["40.69678211232191","72.34769551290027"],
            ["40.69653046183539","72.34835013628447"],
            ["40.69634971011755","72.3494006585298"],
            ["40.6964780500415","72.35000810968566"],
            ["40.69670188813043","72.35091455220577"],
            ["40.69701049982746","72.35215042875866"],
            ["40.69713593150055","72.35279993695117"],
            ["40.69724025062661","72.35340177800497"],
            ["40.69739888851532","72.35437424998713"],
            ["40.69776372632776","72.35602842488976"],
            ["40.69774227994488","72.35711317998776"],
            ["40.69765879253695","72.3573413474334"],
            ["40.69729617909906","72.35815798880853"],
            ["40.6970553119866","72.35847279629981"],
            ["40.69680183531771","72.35878034227977"],
            ["40.69590831257127","72.35996258693959"],
            ["40.69551662676567","72.36056099609752"],
            ["40.69531603555166","72.36093029809977"],
            ["40.69494382068833","72.36187168399688"],
            ["40.69479566216388","72.36223633692201"],
            ["40.69462257129712","72.36292814828047"],
            ["40.6943631001216","72.36416138716291"],
            ["40.69430226816979","72.36449973933193"],
            ["40.69422434289042","72.36501548014492"],
            ["40.69369840010857","72.36619674716343"],
            ["40.69346294197569","72.3666609833782"],
            ["40.69324276789168","72.36773994574662"],
            ["40.69316060373126","72.36821171145043"],
            ["40.69296129171033","72.36904526562603"],
            ["40.69275102791262","72.37004931411305"],
            ["40.69217159855343","72.37120184618607"],
            ["40.69147989460011","72.37203068695244"],
            ["40.69114905111543","72.37243068495586"],
            ["40.69050288915599","72.37316265105963"],
            ["40.69010569125363","72.37375708962348"],
            ["40.68978032206618","72.37448929149008"],
            ["40.68900728431169","72.37641126518037"],
            ["40.68857434183482","72.37718958589454"],
            ["40.68721117927286","72.38025055763795"],
            ["40.68671816133636","72.38115022152552"],
            ["40.68633312414419","72.38212246044597"],
            ["40.68605513423758","72.38343035276714"],
        ]},
    {latlngs: [
            ["40.68577167042542","72.38323289252436"],
            ["40.68558234650122","72.38409292574522"],
            ["40.68558668192362","72.38485759773681"],
            ["40.68568761475589","72.3856233047498"],
            ["40.68580841936652","72.38660014702987"],
            ["40.68603354980915","72.38725290290925"],
            ["40.68644812938263","72.38835424069933"],
            ["40.68669342475106","72.38870937657332"],
            ["40.6871389643441","72.38932634489854"],
            ["40.68746586934077","72.389793544151"],
            ["40.68762408465948","72.39006299031307"],
            ["40.68790753510614","72.3911813609494"],
            ["40.68813097214734","72.39185780678531"],
            ["40.68834289718736","72.39243764115447"],
            ["40.68858257253137","72.3931249627848"],
            ["40.68874765675067","72.39376997380256"],
            ["40.68890815505198","72.3944341549237"],
            ["40.68913696016178","72.39523440253514"],
            ["40.68930188667918","72.39577517898444"],
            ["40.6895472177725","72.39662328704736"],
            ["40.68957902977438","72.39692467177177"],
            ["40.68950887907339","72.39745557519446"],
            ["40.68955193784804","72.39804834660499"],
            ["40.68947352498772","72.39839088867646"],
            ["40.68947435875116","72.39918112022022"],
            ["40.68941455803522","72.39952410983378"],
            ["40.68945869171216","72.39989472881977"],
            ["40.68946570077127","72.40021070764422"],
            ["40.68950585153897","72.40076719928975"],
            ["40.68926509971405","72.4016539840872"],
            ["40.68909534278674","72.40200613185328"],
            ["40.6889429916965","72.40246743425747"],
            ["40.68883953095431","72.40282199323882"],
            ["40.68864666744162","72.40331095402861"],
            ["40.68850727525305","72.40411310507018"],
            ["40.68839776307141","72.40460581444638"],
            ["40.68828307427551","72.40554554560613"],
            ["40.68820872202588","72.40612789446868"],
            ["40.68803975639319","72.40705929743974"],
            ["40.68795180595058","72.40737614469474"],
            ["40.68794401641622","72.40772198239017"],
            ["40.6879595496271","72.40814923196649"],
            ["40.68803949722103","72.40879634614956"],
            ["40.68814541788824","72.40920145611993"],
            ["40.68812171614432","72.40967098021554"],
            ["40.68793394379274","72.41073088337572"],
            ["40.68782255666875","72.41099982069485"],
            ["40.68768362327916","72.41148860160168"],
            ["40.68747668342813","72.4121105785748"],
            ["40.68730335129417","72.41247372432436"],
            ["40.68618887784059","72.41433534777318"],
            ["40.68459693107113","72.41812727545896"],
            ["40.68406365024581","72.419044422008"],
            ["40.68362761331478","72.42055876947197"],
            ["40.68354703112698","72.42096452061018"],
            ["40.68340813189928","72.42151445765616"],
            ["40.68286274843682","72.42317502115795"],
            ["40.68263923054596","72.42435450157858"],
            ["40.68243558272516","72.42548141502829"],
            ["40.68239131932284","72.4260011363987"],
            ["40.68230916916725","72.42967162348866"],
            ["40.68238941341941","72.4305441917592"],
            ["40.68244143119928","72.43136862731329"],
            ["40.68256483375646","72.43290493834206"],
            ["40.68263099467114","72.43412530899251"],
            ["40.68237619094793","72.43538735360855"],
            ["40.68171846461633","72.43708189621522"],
            ["40.68115171469811","72.43816778646891"],
            ["40.68058199423074","72.43909650494915"],
            ["40.67913748663901","72.44136713791225"],
            ["40.6784712375371","72.44232270347484"],
            ["40.67745795072889","72.44391661838861"],
            ["40.67687009783607","72.44503423552"],
            ["40.67613943781978","72.44694096455942"],
            ["40.67581801340688","72.4480568395736"],
            ["40.67561407132703","72.44890226119624"],
            ["40.67543562499238","72.44997095431017"],
            ["40.67550036838318","72.45027747861624"],
            ["40.67559152142826","72.45141616605376"],
            ["40.67574340586344","72.45267734364998"],
            ["40.67458425777011","72.45319041893508"],
            ["40.67396892320313","72.45409534583729"],
            ["40.67364321611407","72.45462964531704"],
            ["40.6732781798463","72.45513690967192"],
            ["40.6723414248703","72.45590270468864"],
            ["40.67022264165342","72.45848152633793"],
            ["40.66942552618939","72.46006071007949"],
            ["40.66899848963723","72.46103846569959"],
            ["40.66908856066361","72.46214498583623"],
            ["40.66928763798762","72.46307913983046"],
            ["40.66944316194045","72.46414742405761"],
            ["40.66994379203358","72.4667195540717"],
            ["40.67010247992354","72.46863024287035"],
            ["40.66942254118305","72.470727958178"],
            ["40.66862493175947","72.47419087601749"],
            ["40.66843368254294","72.47564035740808"],
            ["40.66845001904678","72.47838937284308"],
            ["40.66840141403111","72.47997818687223"],
            ["40.6682366561315","72.48338956626796"],
            ["40.66833130720353","72.48521776084934"],
            ["40.66834673138343","72.48547205367379"],
            ["40.66913986765569","72.49054661038954"],
            ["40.6696433962025","72.49206869252377"],
            ["40.66924801635527","72.49338069219388"],
            ["40.66859508007566","72.49750589731802"],
            ["40.66692149235877","72.50176184254168"],
            ["40.66573797161651","72.50816781293031"],
            ["40.66520183247918","72.51566773624216"],
            ["40.6652471068538","72.52262139979035"],
            ["40.66491050424176","72.52415402669772"],
            ["40.66416734948884","72.52615486982006"],
            ["40.66331802857734","72.52710053663813"],
            ["40.66023072018594","72.5293087278136"],
            ["40.65884695362091","72.53037797021325"],
            ["40.65377525576376","72.5339964947165"],
            ["40.64910572346847","72.53702672123927"],
            ["40.6459836293069","72.53725266355301"],
            ["40.64579135897689","72.53734324301595"],
            ["40.64401068903587","72.53845213090315"],
            ["40.64342365252837","72.53921609228878"],
            ["40.64409461664506","72.54242382091363"],
            ["40.64437871228819","72.54380268829699"],
            ["40.64475981584877","72.54627914405529"],
            ["40.64555747718599","72.55234916344452"],
            ["40.64484658489223","72.55685842658978"],
            ["40.64396580089339","72.56170134203029"],
            ["40.64373941787117","72.56288915498588"],
            ["40.64357685760717","72.56335711286953"],
            ["40.64289759225797","72.56579574880035"],
            ["40.6427298284785","72.5670316450279"],
            ["40.64259398087282","72.56838702915933"],
            ["40.64237355483048","72.57112267843856"],
            ["40.64208621458103","72.57495844777851"],
        ]},
    {latlngs: [
            ["40.68605327171776","72.38343755343784"],
            ["40.68587964464432","72.38425989985089"],
            ["40.68592114026507","72.38492251824394"],
            ["40.68608073775838","72.38601631620946"],
            ["40.68618087922531","72.38665374647454"],
            ["40.68671710064849","72.38812865748876"],
            ["40.68704780877226","72.38862847812293"],
            ["40.68757548002986","72.38947837512949"],
            ["40.68785489419375","72.38994781173896"],
            ["40.68826576469833","72.39098471863471"],
            ["40.68848074568081","72.39150185746547"],
            ["40.68873353846288","72.39204985595212"],
            ["40.68941033743828","72.39376853096319"],
            ["40.68949327987423","72.39405288286679"],
            ["40.68945017084678","72.3946597394154"],
            ["40.68940516275227","72.39493942591542"],
            ["40.68954998150156","72.3956799701471"],
            ["40.68975854962582","72.39655731810296"],
            ["40.68976188655297","72.3967437512821"],
            ["40.68975675302801","72.39806030761312"],
            ["40.6897536289011","72.39915957390751"],
            ["40.68973616956806","72.39932987073281"],
            ["40.68972829198552","72.40010964774754"],
            ["40.68966652220765","72.40063198192682"],
            ["40.68967914964786","72.40080592054073"],
            ["40.6897388652617","72.40140385861918"],
            ["40.68980912354881","72.40245024159407"],
            ["40.68985300254847","72.40273774718678"],
            ["40.68984153213795","72.40335167759775"],
            ["40.68985150322469","72.40354500906794"],
            ["40.68988555170256","72.40412185071321"],
            ["40.68993583403488","72.40511253673911"],
            ["40.68997001228077","72.40598877142486"],
            ["40.68992660227663","72.40604458344548"],
            ["40.68930525589049","72.40687938286531"],
            ["40.68924277474176","72.40705097660707"],
            ["40.68913892080568","72.40722004558546"],
            ["40.68829831282822","72.40938455941688"],
            ["40.68824030758702","72.40975134739323"],
            ["40.68818943368701","72.41131493410968"],
            ["40.68815181849475","72.41205169353444"],
            ["40.68814147276398","72.41330969574196"],
            ["40.68814398635546","72.41376760173313"],
            ["40.68808304711763","72.41436918453635"],
            ["40.68804010207712","72.41465490427069"],
            ["40.68798380413019","72.4147461483085"],
            ["40.68630449102184","72.41717867930684"],
            ["40.68450589791505","72.4193725533044"],
            ["40.68431303971744","72.41959856486523"],
            ["40.68400233772449","72.42037937141005"],
            ["40.68383112751496","72.42098437010431"],
            ["40.68366236693477","72.42158460937583"],
            ["40.68315808235722","72.42300183709138"],
            ["40.68288011728666","72.4243056182582"],
            ["40.68263739666099","72.42548520821842"],
            ["40.68271758488592","72.42617537029844"],
            ["40.683073793878","72.42894916112739"],
            ["40.68316613912236","72.42960283469236"],
            ["40.68317999722428","72.42977094515159"],
            ["40.6832242055979","72.4309779272773"],
            ["40.68325166186319","72.43170314928847"],
            ["40.68323920196634","72.43206359204709"],
            ["40.68324597253491","72.43307424640665"],
            ["40.68324324284649","72.43373371677558"],
            ["40.68326964573064","72.43395752024459"],
            ["40.68317823504397","72.43419118172091"],
            ["40.68199006000659","72.43677085216369"],
            ["40.68178386612595","72.43718337126664"],
            ["40.68120213849656","72.43831034453429"],
            ["40.68073727719575","72.43910079915676"],
            ["40.67918563697992","72.44156397900453"],
            ["40.67860493234641","72.44249223411893"],
            ["40.67755854859602","72.44429167855792"],
            ["40.67703442922551","72.44525136458726"],
            ["40.67642068495293","72.44714547410314"],
            ["40.67616205834938","72.44804376638402"],
            ["40.67604352487594","72.44898200788157"],
            ["40.67602925086918","72.44927780370423"],
            ["40.6759532840196","72.45010657549543"],
            ["40.67578360742427","72.45073599162866"],
            ["40.67590400928282","72.45257343671194"],
        ]},
    {latlngs: [
            ["40.67575418601252","72.45269025119923"],
            ["40.67661382523987","72.45315214604398"],
            ["40.67747943895873","72.45379718705553"],
            ["40.67809988468911","72.45400538772766"],
            ["40.67835033050792","72.45334431274323"],
            ["40.67851280105487","72.45220147613566"],
        ]},
    {latlngs: [
            ["40.67604754357662","72.44930525622406"],
            ["40.67807705348756","72.44912014300738"],
            ["40.67865583756954","72.4491544884845"],
            ["40.68009048348215","72.44887851318163"],
            ["40.68107547927721","72.44866935907405"],
            ["40.68220704926947","72.44851448460774"],
            ["40.68520277874987","72.44806994847497"],
            ["40.68639730890489","72.44802083116794"],
            ["40.68787678312769","72.44781375545044"],
            ["40.68892872259224","72.4476372827134"],
            ["40.68975658465341","72.44756918945491"],
            ["40.69371842916407","72.4471455487499"],
            ["40.69470203579631","72.44714101990539"],
            ["40.69561717658981","72.44700476937777"],
            ["40.69694022490636","72.44656079832845"],
            ["40.69761930840026","72.44656241790918"],
            ["40.69919502577403","72.44638000690867"],
            ["40.7005222676812","72.4460358481922"],
            ["40.70092797055266","72.44582600343666"],
            ["40.70183636760609","72.4454485106517"],
            ["40.70264007649864","72.4453315325716"],
            ["40.70349885897816","72.44567684653346"],
            ["40.7047266496663","72.44568434904029"],
            ["40.70545340860819","72.44568438307384"],
            ["40.70614055425998","72.44607381175338"],
            ["40.70655710421767","72.44646435944321"],
            ["40.70705749094129","72.44683803858049"],
            ["40.70791538697397","72.44755572950709"],
            ["40.7085957939184","72.4481131937724"],
            ["40.70945078296945","72.44856240699937"],
            ["40.71024763104115","72.44899136387373"],
            ["40.71113012851335","72.44957106309201"],
            ["40.71178471893385","72.45026068965706"],
            ["40.71266306327915","72.4511039495393"],
            ["40.71349666712876","72.45181448189695"],
            ["40.71389118830758","72.45237823593712"],
            ["40.71509477815421","72.45188769473005"],
            ["40.71563449896811","72.45171192926222"],
            ["40.71718188107103","72.45191690706713"],
            ["40.71784879122751","72.45206815125044"],
            ["40.71893903699347","72.45177230447904"],
            ["40.71925264589945","72.45148279974634"],
            ["40.71969874782376","72.45089625625948"],
            ["40.72039931937682","72.44992037769613"],
            ["40.72112558474844","72.44911998738597"],
            ["40.7221779518886","72.44834096589616"],
            ["40.72279960057266","72.44828875415834"],
            ["40.72306305667477","72.44879826239635"],
            ["40.72318501962764","72.45052265337871"],
            ["40.72328833322475","72.45164115283502"],
            ["40.72348144970185","72.45237581550182"],
            ["40.72432211457905","72.45416212522609"],
            ["40.72510474377069","72.45608056775214"],
            ["40.72600682468504","72.45866310645879"],
            ["40.72645327392242","72.46012255893706"],
            ["40.72736997095777","72.46144528874672"],
            ["40.72786056478664","72.46197315116991"],
            ["40.72817051709603","72.4619901540418"],
            ["40.72973400753595","72.46155952624194"],
            ["40.73019950539891","72.46139450561209"],
            ["40.73208920391996","72.46049649873798"],
            ["40.73235943611819","72.46025983725229"],
            ["40.73366356466625","72.45967019940832"],
            ["40.73536994040197","72.45903419120592"],
            ["40.73681725132815","72.45847423578543"],
            ["40.738100346745","72.45798633710415"],
            ["40.73915935156786","72.45763229836007"],
            ["40.73977183917334","72.45739748656985"],
            ["40.74346492654468","72.45590863985173"],
            ["40.74377002860327","72.45586644588433"],
            ["40.74594534655844","72.45359464997011"],
            ["40.74710867899378","72.45183238758219"],
            ["40.74786945651328","72.44970788684628"],
            ["40.74941419020327","72.44614493835032"],
            ["40.75047067398042","72.4432007949887"],
            ["40.7522480910257","72.43830716544844"],
            ["40.75323290563782","72.43588621237458"],
            ["40.75415050457966","72.4323516470638"],
            ["40.75571875149252","72.42930156408462"],
            ["40.75671575576278","72.42659850625932"],
            ["40.75731610362634","72.42491933107267"],
            ["40.75875452011709","72.42249515881032"],
            ["40.7601840466621","72.42006787623603"],
            ["40.7621615949349","72.41737087570195"],
            ["40.76355757886331","72.41537377639023"],
            ["40.76444125528931","72.41371431240788"],
            ["40.76516350144259","72.40972955962242"],
            ["40.7657617782578","72.40635025599613"],
            ["40.76612335811213","72.40379679774163"],
            ["40.7663159381936","72.40203999451637"],
        ]},
    {latlngs: [
            ["40.64208427610113","72.57487196279621"],
            ["40.64207516165241","72.57679971208616"],
            ["40.64203640793464","72.58009938559043"],
            ["40.64033748177323","72.58364883443622"],
            ["40.63858025908358","72.58934796993464"],
            ["40.63705099026943","72.59246135193652"],
            ["40.63530089133653","72.5957195294073"],
            ["40.63368960534211","72.59852155721858"],
            ["40.63021819244651","72.60692091137544"],
            ["40.62847419368386","72.61156185229332"],
            ["40.62717882727875","72.61610003499251"],
            ["40.62613462811287","72.61995981285226"],
            ["40.62429722064779","72.62709302496707"],
            ["40.62330590884956","72.63072448608212"],
            ["40.62089550656609","72.63879795915308"],
            ["40.6201104499748","72.64098551082125"],
            ["40.61845556646208","72.64569991431244"],
            ["40.61820144971364","72.64705575372224"],
            ["40.61803344157178","72.65128059488006"],
            ["40.61653363963568","72.65861824881078"],
            ["40.61623358859042","72.6593858925188"],
            ["40.61483309871565","72.66235366466329"],
            ["40.61034801864336","72.67212934063161"],
            ["40.60333107631359","72.67199019604583"],
            ["40.60255117685696","72.67250860923572"],
            ["40.60272503399074","72.67922283965285"],
            ["40.60276373782224","72.68268898054303"],
            ["40.6026890040481","72.68389502746902"],
            ["40.6027259973023","72.68601474235372"],
            ["40.60129269643684","72.69408699720222"],
            ["40.60216502793891","72.69444202776384"],
            ["40.60284730155816","72.69481968056954"],
            ["40.601545861439","72.69903100074971"],
            ["40.60063732147795","72.70325142766974"],
            ["40.60043372412416","72.70446662308004"],
            ["40.60001614580469","72.70892814397408"],
            ["40.59966775446985","72.71097131339327"],
            ["40.59899361738375","72.71119242267336"],
            ["40.59802188174599","72.71143722419373"],
            ["40.59698543451005","72.71165916378854"],
            ["40.59570209007848","72.71197488766255"],
            ["40.59470966507877","72.71215509431389"],
            ["40.59390213271477","72.71216181569226"],
            ["40.5938233953808","72.71216015176303"],
            ["40.59184681588606","72.71225494966581"],
            ["40.59057889515143","72.7123072842936"],
            ["40.58865289057039","72.71234162058495"],
            ["40.58759479566471","72.71214556696813"],
            ["40.58646122130836","72.71177947807413"],
        ]},
    {latlngs: [
            ["40.61804201681647","72.65110348505624"],
            ["40.61890548135344","72.65182821352965"],
            ["40.62027465950187","72.65237481495809"],
            ["40.62088079160291","72.65272702069178"],
            ["40.62130651401919","72.653058770722"],
            ["40.62289663350001","72.65442914095196"],
            ["40.62407432744436","72.65555595101404"],
            ["40.62516879590424","72.65675706189793"],
            ["40.62563221541115","72.65736693516668"],
            ["40.62644218428597","72.6584009323584"],
            ["40.62687495952552","72.65893791183764"],
            ["40.62716297876892","72.65920830256525"],
            ["40.62731011677175","72.65941484471033"],
            ["40.62751099834121","72.65977844299009"],
            ["40.62777420689856","72.66004783381044"],
            ["40.62811275188915","72.66059766448598"],
            ["40.62877977162962","72.66144175815909"],
            ["40.62937428435588","72.66211055062664"],
            ["40.63101994798629","72.66341878268736"],
            ["40.63239723381719","72.66451177359774"],
            ["40.63413018013789","72.66617811999491"],
            ["40.63856728248381","72.67142196333532"],
            ["40.63936763979603","72.67235363470066"],
            ["40.64049994030839","72.67365074181244"],
            ["40.64192030873821","72.67530560179834"],
            ["40.64426522859098","72.67811940216842"],
            ["40.64471250498701","72.67868822839215"],
            ["40.64571142997534","72.67966818482893"],
            ["40.64681901794097","72.6802471340263"],
            ["40.64766254331839","72.68048231224991"],
            ["40.64871684466949","72.68077198845018"],
            ["40.64931733358672","72.68093403284449"],
            ["40.64999705716949","72.68178941673263"],
            ["40.65054739472538","72.6828461272258"],
            ["40.65109218657916","72.68384058274248"],
            ["40.65219586681208","72.68578244148428"],
            ["40.65330184104717","72.68784372014309"],
            ["40.65447291880677","72.68980685175816"],
            ["40.65561266829339","72.69188476755509"],
            ["40.65660775828906","72.69393968871761"],
            ["40.65760176085477","72.69592801146702"],
            ["40.65872847833041","72.69821399512307"],
            ["40.65982610551811","72.70022321832113"],
            ["40.66136780161516","72.70324593860074"],
            ["40.66250743793127","72.70535244958016"],
            ["40.66334090569794","72.70744883246493"],
            ["40.66499061452502","72.71213764931933"],
            ["40.66621967846009","72.71563335437898"],
            ["40.66786014289884","72.71998139029097"],
            ["40.66973792789379","72.72456203444547"],
            ["40.67016523851112","72.72559203980552"],
            ["40.67109636596527","72.72788973878249"],
            ["40.67209594189444","72.7300689801673"],
            ["40.6724794866948","72.7316829028878"],
            ["40.67362743509302","72.73342010743229"],
            ["40.67706685501999","72.73361471422042"],
            ["40.68145288812214","72.73367761213777"],
            ["40.68600090729865","72.73365383600731"],
            ["40.68865496073762","72.73371825663595"],
            ["40.68948132874517","72.73517998364539"],
            ["40.69011079550285","72.73705673362032"],
            ["40.69001707014921","72.73959613146148"],
            ["40.69034515368934","72.74259575643509"],
            ["40.69051366331367","72.74504099036113"],
            ["40.69086242390007","72.75004430057014"],
            ["40.6910282189099","72.75423651743918"],
            ["40.69084055517653","72.7566077964001"],
            ["40.69077349187749","72.75913386720734"],
            ["40.69076938037641","72.7623784857296"],
            ["40.69051725964653","72.76405270700194"],
            ["40.69014778854846","72.76579271797638"],
            ["40.68959740746327","72.76755653120233"],
            ["40.68937166494634","72.76904913142599"],
        ]},
    {latlngs: [
            ["40.67251175684574","72.73161063946239"],
            ["40.67283786470689","72.73157228267019"],
            ["40.67467948390934","72.72993210097269"],
            ["40.67694460439576","72.72767812550676"],
            ["40.67876768811142","72.72585812916712"],
            ["40.68111617807599","72.72368508362457"],
            ["40.68241651266027","72.7225253663164"],
            ["40.68381871023237","72.72131558051481"],
            ["40.68519761252319","72.7201877591807"],
            ["40.68610624741923","72.71940754853692"],
            ["40.6871537527284","72.71839974613354"],
            ["40.68884841854552","72.71652709161552"],
            ["40.69193203450148","72.7130509193433"],
            ["40.69155466909032","72.71215622067351"],
            ["40.69483857986398","72.7086615907407"],
            ["40.69753156463067","72.70574642221538"],
            ["40.71026454270591","72.69182507167268"],
            ["40.71120618825033","72.69122223059564"],
            ["40.71392855134384","72.69169801854801"],
            ["40.71859557119743","72.69318412118872"],
        ]},
    {latlngs: [
            ["40.66841556247472","72.28083709586208"],
            ["40.66899175606227","72.27985826003432"],
            ["40.66971062702817","72.28031623933512"],
            ["40.67103194326921","72.28205260256713"],
            ["40.67171037332767","72.28111167351298"],
            ["40.67231370604982","72.28024932542274"],
            ["40.67290013480876","72.27955098193902"],
            ["40.67342814682197","72.27879584561092"],
            ["40.67382722053349","72.27720179937971"],
            ["40.67425075153473","72.27552611529805"],
            ["40.67484214298849","72.27336452652084"],
            ["40.67537654700167","72.27211014765857"],
            ["40.67618759011709","72.27137638768012"],
            ["40.67788351894819","72.27043634196617"],
            ["40.67936195529813","72.26929690328399"],
            ["40.68052877732986","72.26791798566364"],
            ["40.6826348560149","72.26662696702853"],
            ["40.68502956832823","72.26540175934811"],
            ["40.68706382558386","72.26393627594317"],
            ["40.68835150966424","72.26324888315334"],
            ["40.69189692600632","72.26032373185706"],
            ["40.6930999094782","72.25939393821203"],
            ["40.69379892813301","72.25873170548789"],
            ["40.69524642481347","72.25771968263733"],
            ["40.69618283252694","72.25684234264072"],
            ["40.69826279983824","72.25512725648183"],
            ["40.69992046164109","72.25368341163518"],
            ["40.70143458747511","72.2522607577786"],
            ["40.70252579773914","72.25101813681394"],
            ["40.70289800068237","72.24809893694629"],
            ["40.7031038101176","72.2473045664495"],
            ["40.70381245150161","72.24665465571312"],
            ["40.70465470635404","72.24566866000373"],
            ["40.70526713032498","72.2451116797517"],
            ["40.70574274477352","72.24464014271415"],
            ["40.70677203361477","72.24348955172449"],
            ["40.70767562482053","72.24225480574042"],
            ["40.70852779772256","72.24126546746294"],
            ["40.7097854887017","72.24094818839804"],
            ["40.71072353168219","72.24085767768513"],
            ["40.71214609936763","72.24073107760309"],
            ["40.71328065523995","72.24053733343537"],
            ["40.71503118764841","72.24053850420123"],
            ["40.71816127259386","72.24081770768926"],
            ["40.7185198980915","72.24090370448192"],
            ["40.72223380087558","72.24078983558441"],
            ["40.72625673034917","72.24117912562014"],
            ["40.7277506845582","72.24143255381148"],
            ["40.73244811377347","72.24229093447418"],
            ["40.73563068954021","72.2426247354182"],
            ["40.74069931860427","72.2423073104755"],
            ["40.74130586622056","72.24243842994414"],
            ["40.74514734780421","72.24240149200755"],
            ["40.74734877171966","72.2427652324253"],
            ["40.74849562527564","72.24260225075106"],
            ["40.74960249288397","72.24224007151585"],
            ["40.75124816999568","72.24190500585158"],
            ["40.75316221760871","72.24147289587053"],
            ["40.75416575705849","72.24115592584502"],
            ["40.75688820277332","72.24248846404784"],
            ["40.75871008262377","72.24236780293002"],
            ["40.76032464712539","72.24286250155723"],
            ["40.76185041785335","72.24350186459797"],
            ["40.76261861348852","72.24358675725659"],
            ["40.76500071775148","72.24472011271121"],
            ["40.76828602027043","72.24622828105316"],
            ["40.7701564856508","72.24729390700941"],
            ["40.77357379840451","72.249361427214"],
            ["40.77589010047573","72.25159777674459"],
            ["40.77713834035541","72.25369591841709"],
            ["40.7784152761537","72.2554143210938"],
            ["40.77851671783787","72.25659738993618"],
            ["40.77756772653881","72.25765354323002"],
            ["40.77767706759872","72.2593419898557"],
            ["40.77758377837243","72.26030428691114"],
            ["40.77775968890312","72.26386174347428"],
            ["40.77803088788973","72.26879124850899"],
            ["40.77806611625029","72.27218929526792"],
        ]},
    {latlngs: [
            ["40.77865530546174","72.25599656204001"],
            ["40.77983732119144","72.25117378372248"],
            ["40.78030162322721","72.24706928717413"],
            ["40.78015228702062","72.24576588620553"],
            ["40.77966564945914","72.24290833112472"],
            ["40.77973929105263","72.23952868607554"],
            ["40.77996473185603","72.23269555143328"],
            ["40.77923427946263","72.23055128582949"],
            ["40.77910602594963","72.22946912017193"],
            ["40.77933021426352","72.22226943315135"],
            ["40.77941270857294","72.22023472472755"],
            ["40.7791180137953","72.21622663820503"],
            ["40.77870114689946","72.21047867444048"],
            ["40.77817200838777","72.20483370815947"],
            ["40.77789742746462","72.20019963871326"],
            ["40.77754017581858","72.19760058820023"],
            ["40.77707980570954","72.19311339429944"],
            ["40.77673431820199","72.18928658836931"],
            ["40.77630655317588","72.18581832436935"],
            ["40.77602716489831","72.18243563503353"],
            ["40.77546149874925","72.17586969646999"],
            ["40.77498193796352","72.1723251144787"],
            ["40.77574499299014","72.16876476089598"],
            ["40.77659073150904","72.16598750571387"],
            ["40.77798713541672","72.16032536067488"],
            ["40.77812945948598","72.15697336684634"],
            ["40.77828249070939","72.15569461973401"],
            ["40.77841938671385","72.15257658701717"],
            ["40.77864926911624","72.14620335553481"],
            ["40.78058725254296","72.1403846209548"],
            ["40.78235756689553","72.13450345709104"],
            ["40.78355815101375","72.1294781799338"],
            ["40.7863407500182","72.11777445974703"],
            ["40.78692316612376","72.1142382900665"],
            ["40.78727815956371","72.10959627295848"],
            ["40.78828955305729","72.09629638680673"],
            ["40.78823330076631","72.09442070875865"],
            ["40.78850464634802","72.08608627231949"],
            ["40.78929846393162","72.07870607483713"],
            ["40.79065968825024","72.07235949146053"],
            ["40.79247677400559","72.06686369250696"],
            ["40.79354031951809","72.06081742245422"],
            ["40.794291464848","72.05465082533317"],
            ["40.7942571960866","72.05335318557439"],
            ["40.79500027264751","72.05025005755066"],
            ["40.79514050022193","72.0483464802405"],
            ["40.79476555756782","72.04592247357338"],
            ["40.79434411528334","72.04260478252567"],
            ["40.79353390437564","72.03865957621282"],
            ["40.79333022641919","72.03493392189682"],
            ["40.79314000240847","72.03138480868682"],
            ["40.79327659255775","72.02876678325219"],
            ["40.79235266549344","72.02267156816579"],
            ["40.79234737254409","72.02015740069221"],
            ["40.79213879417159","72.01233771642751"],
            ["40.79288816628492","72.00829031653983"],
            ["40.79429367907639","72.00460291273865"],
            ["40.79531000492931","72.0005365811847"],
            ["40.79576694478792","71.99884221577781"],
            ["40.79684332876507","71.99456983473334"],
            ["40.79806631610939","71.99023797191947"],
            ["40.79846846691583","71.98851707382093"],
            ["40.79867137002068","71.98728033760872"],
            ["40.79891181026265","71.9862166162043"],
            ["40.79962552182658","71.98468279542888"],
            ["40.80039182092649","71.98152511417661"],
            ["40.80034578161899","71.980605299695"],
            ["40.79985812821086","71.97637703341064"],
            ["40.8000906455753","71.97558983542922"],
            ["40.80127477100796","71.9746385659242"],
            ["40.8023118115868","71.97370519195809"],
            ["40.80301134835921","71.97232057343398"],
            ["40.8043529181314","71.96812493230183"],
            ["40.80650997929862","71.96484674930301"],
            ["40.80749369994187","71.96327004217156"],
            ["40.80797131920893","71.96183061895115"],
            ["40.80813181771931","71.96128117478248"],
            ["40.80914721326742","71.95862542700142"],
            ["40.80956602606452","71.95769447928022"],
            ["40.80947564101881","71.95466946353348"],
            ["40.80932917284974","71.95172079327473"],
            ["40.80867728931651","71.94842037239576"],
            ["40.80884631663907","71.94473913471585"],
            ["40.80892723958387","71.93957640436707"],
            ["40.8082569594079","71.93476017147752"],
            ["40.80744494418011","71.92850743725707"],
            ["40.80742576112839","71.92653544402792"],
            ["40.80659177562334","71.92320477868603"],
            ["40.80492315266611","71.91525624948888"],
            ["40.80352412781212","71.90789340482158"],
            ["40.80319416923416","71.90529255003648"],
            ["40.80518300487756","71.90183442788832"],
            ["40.80888632934683","71.89595552808849"],
            ["40.80968633453681","71.89437841561083"],
            ["40.80955077488773","71.89204858315594"],
            ["40.80781942620955","71.8848953909572"],
            ["40.80776483740721","71.88344674407074"],
            ["40.80683434585139","71.88086991011119"],
            ["40.80586839836037","71.87712709212305"],
            ["40.80624135550497","71.86991847585089"],
            ["40.80587227478325","71.86617767825629"],
            ["40.80520676567547","71.8625141374218"],
            ["40.80512370977137","71.86141568929098"],
            ["40.80505827498595","71.85996817448654"],
            ["40.8049458283116","71.85850239894843"],
            ["40.8047767758366","71.85395715776815"],
            ["40.80474462510965","71.85339843652119"],
            ["40.80441854312089","71.85183287882927"],
            ["40.80388355640156","71.83944527094476"],
            ["40.80405564534951","71.83080595465125"],
            ["40.80419082880019","71.82378059025118"],
            ["40.80420069551515","71.81885692283346"],
            ["40.8046015571004","71.81298604688182"],
            ["40.8050408923204","71.8075505087013"],
            ["40.80601782340516","71.80579344928728"],
            ["40.80571137312577","71.80391729502179"],
            ["40.80543306203014","71.80312338809887"],
            ["40.80526400652557","71.80225551245874"],
            ["40.80462263349352","71.7995228880756"],
            ["40.80435695038953","71.79806848486142"],
            ["40.8039235610905","71.79611167839209"],
            ["40.80279138968441","71.79401459649775"],
            ["40.8004810336577","71.79142701170805"],
            ["40.79952559873228","71.79038487838241"],
            ["40.79765187442522","71.78727365981668"],
            ["40.79607187396365","71.78449958932927"],
            ["40.79516680344222","71.78224611506704"],
            ["40.79559415546897","71.7771914987503"],
            ["40.79553956855831","71.77533138021089"],
            ["40.79625332129355","71.77270706703236"],
            ["40.79667851224003","71.77002502614941"],
            ["40.79698283293926","71.7668544416116"],
            ["40.79744168402796","71.76340103142482"],
            ["40.79891331817743","71.75859728935959"],
            ["40.79934195054658","71.75135816877635"],
            ["40.80125602684584","71.72924912197344"],
            ["40.80206684372656","71.72355797402103"],
            ["40.80323846404996","71.72178684598386"],
            ["40.80408083018921","71.71715445633055"],
            ["40.80508230015646","71.70945650954872"],
            ["40.80592910354774","71.70161693933829"],
            ["40.80668649515535","71.69375082905316"],
            ["40.80729606216134","71.68557146511691"],
            ["40.80715884179196","71.6816787386411"],
            ["40.81040134747069","71.65615148126811"],
            ["40.81240352007971","71.64336789297298"],
            ["40.81329356925688","71.642566420842"],
            ["40.81318966563415","71.64190153655832"],
            ["40.81555503002453","71.64002400151932"],
            ["40.81971643647373","71.63645563980103"],
            ["40.82317651534355","71.63313166212409"],
            ["40.82393183760259","71.63274491356091"],
            ["40.8251153814497","71.63241362352183"],
            ["40.82615998819692","71.63200438877521"],
            ["40.82946603234004","71.63146797429995"],
            ["40.83329519821608","71.63010490995551"],
            ["40.83733326578262","71.621710815244"],
            ["40.84114739622076","71.6129304086805"],
            ["40.84512380603344","71.60352226592425"],
            ["40.84621370053304","71.6022042051322"],
            ["40.84692816543313","71.60121562072638"],
            ["40.847243436467","71.6009333519586"],
            ["40.84957279571856","71.59875281570217"],
            ["40.84940575117888","71.59731784630316"],
            ["40.85362754032597","71.59564498025721"],
            ["40.8534484521316","71.59011449919396"],
        ]},
    {latlngs: [
            ["40.81231087586566","71.64382345475832"],
            ["40.81128083848659","71.6436290564163"],
            ["40.80474755618327","71.6367021834315"],
            ["40.7998405512694","71.63389262922705"],
            ["40.79625313654181","71.63620355052089"],
            ["40.79391918685775","71.6376894171475"],
            ["40.79199146602614","71.63869805105982"],
            ["40.78836120193728","71.64068328370864"],
            ["40.78592820177368","71.64211176271742"],
            ["40.78138215642303","71.64516172053416"],
            ["40.78058176647493","71.64568797411542"],
            ["40.77277581665966","71.64855160864391"],
            ["40.76784937423255","71.65010400184694"],
            ["40.76323086200202","71.65071925061348"],
            ["40.76064726342543","71.65175175106343"],
            ["40.75816868180116","71.65490058675883"],
            ["40.75636234165872","71.6571025871344"],
            ["40.75159965450806","71.65788798878887"],
            ["40.74727702344121","71.65772339526704"],
            ["40.74167027101976","71.66249812356526"],
            ["40.73862952065401","71.66523556654475"],
            ["40.73482886207935","71.66894152390988"],
            ["40.73309281113892","71.67043212824751"],
            ["40.73040771384329","71.67344342631455"],
            ["40.72763924557139","71.67642532577307"],
            ["40.72387205128491","71.68208946349075"],
            ["40.71790226289505","71.68448463232735"],
            ["40.71701686952031","71.68577081296162"],
            ["40.71592048375076","71.69050860944969"],
            ["40.71536384348305","71.69264901058712"],
            ["40.71441571048604","71.69558295167479"],
        ]},
    {latlngs: [
            ["40.80774968352313","71.88346196344813"],
            ["40.8102482111053","71.88164045276"],
            ["40.81416216953003","71.87811834576665"],
            ["40.81603615159386","71.87674908905254"],
            ["40.81839159581759","71.87472526382004"],
            ["40.82157935741526","71.87246895810345"],
            ["40.82596000699459","71.86943198234772"],
            ["40.82766851505375","71.86802383079382"],
            ["40.82826933331259","71.86764038717492"],
            ["40.82881021309962","71.86742572502119"],
            ["40.82925248714761","71.86720921878661"],
            ["40.832685751779","71.86547746622921"],
            ["40.83376430489275","71.86539331696858"],
            ["40.83560155773919","71.86561882504759"],
            ["40.83775758479671","71.86599225672612"],
            ["40.84266389791996","71.86657095519168"],
            ["40.84685978224491","71.86736459055928"],
            ["40.85104137098106","71.86852301548173"],
            ["40.85326235560074","71.86884253611899"],
            ["40.85614867078902","71.86918703313009"],
            ["40.86375967405247","71.86991024688308"],
            ["40.87096854143886","71.86964058333011"],
            ["40.87476372495723","71.86876130677962"],
            ["40.87513126020154","71.8683517450652"],
            ["40.87529939699694","71.86802606709264"],
            ["40.87646797710222","71.86559319080348"],
        ]},
    {latlngs: [
            ["40.80655431402643","71.964882239502"],
            ["40.81052017803777","71.96983603895623"],
            ["40.81123429350944","71.97083777733839"],
            ["40.81240086340544","71.97088801237899"],
            ["40.81306409127129","71.97075093239619"],
            ["40.81949603664377","71.96894820891042"],
            ["40.82054010028987","71.96809023638018"],
            ["40.82316482403154","71.9654893392009"],
            ["40.82584414780847","71.96288798088494"],
            ["40.82941425423248","71.96149368205798"],
            ["40.83296497522057","71.96083447720599"],
            ["40.83558528348014","71.96087738166337"],
            ["40.83807369525789","71.96220353206699"],
            ["40.83851550940111","71.96288639220363"],
            ["40.83984581413055","71.96635627069814"],
            ["40.84309096542443","71.96851149639816"],
            ["40.85030368516058","71.97059022175682"],
            ["40.85545674635689","71.96785470453929"],
            ["40.85594690065031","71.96748989149212"],
            ["40.85844064246489","71.9663104234115"],
            ["40.8595979819085","71.96812576814781"],
            ["40.86052222419838","71.96950322055872"],
            ["40.86166027083956","71.9714230561093"],
            ["40.86226848613487","71.97201270403897"],
            ["40.86268355523179","71.97235034493418"],
            ["40.86356551186486","71.97299835602807"],
            ["40.86434816434566","71.9738248471029"],
            ["40.86460279468309","71.97438688436999"],
            ["40.86487119593212","71.97581452433188"],
            ["40.86530640299291","71.97792482264995"],
            ["40.8654072015704","71.97831213839311"],
            ["40.86621924927949","71.9803844164516"],
            ["40.86634887270051","71.98049381776103"],
            ["40.86678622217776","71.98043546864218"],
        ]},
    {latlngs: [
            ["40.85844885980165","71.96628832347238"],
            ["40.85860477505771","71.96606654196762"],
            ["40.85871525024518","71.9656334105062"],
            ["40.85885979543899","71.96516594757398"],
            ["40.85896340105116","71.96481914046964"],
            ["40.85903294241486","71.96448812290423"],
            ["40.85880619721451","71.96203442161881"],
            ["40.85864244511167","71.95914850811631"],
            ["40.85870264471066","71.95442312924862"],
            ["40.85885323964597","71.95097596737182"],
            ["40.85889332166997","71.95020229745418"],
            ["40.85889878973241","71.94924654922239"],
            ["40.8591065606759","71.94551406579313"],
            ["40.85916418938609","71.94542659926883"],
            ["40.8599651856845","71.94452915270926"],
            ["40.86008941297251","71.94442322558615"],
            ["40.86199836999047","71.94260796093921"],
            ["40.86396337286166","71.94218246312946"],
            ["40.86603344561268","71.94167552940769"],
            ["40.86708258181343","71.94147653063118"],
            ["40.86880821604003","71.94103979120113"],
            ["40.86942340940604","71.94088308272276"],
            ["40.87067391176377","71.94056153406629"],
            ["40.87339534788362","71.93980512252098"],
            ["40.87744470598929","71.93930543601603"],
            ["40.87760339165988","71.93937657877119"],
            ["40.87788048728865","71.93948816779599"],
            ["40.87958979573781","71.94024825350937"],
            ["40.88149576278884","71.94130733070436"],
            ["40.8816878665602","71.94145337872703"],
            ["40.88396882372492","71.94298572458393"],
            ["40.88422510669297","71.94315749449157"],
            ["40.88528839473369","71.94382472904603"],
            ["40.88726599335278","71.94502152483639"],
            ["40.8874485292823","71.94511048853094"],
            ["40.88867785688153","71.94578159718729"],
            ["40.88900110989287","71.9459419127809"],
            ["40.89014884388586","71.94651550039646"],
            ["40.89079045060331","71.9465584211724"],
            ["40.89152623075829","71.94648104640814"],
            ["40.89202272451315","71.94640751549586"],
            ["40.89220285595685","71.94580535728744"],
            ["40.89274732285842","71.94366671890215"],
            ["40.89296944268672","71.94283615055538"],
            ["40.89306995280533","71.94249257814026"],
            ["40.89314654439753","71.94233383511175"],
            ["40.89418902910769","71.93975705072164"],
            ["40.89532280608034","71.93706629473415"],
            ["40.89558952031525","71.93635697078466"],
            ["40.89632040627114","71.9348892291077"],
            ["40.89720761887624","71.93524327157864"],
        ]},
    {latlngs: [
            ["40.61009998633713","72.22833499580355"],
            ["40.61005023620703","72.2284860668928"],
            ["40.60908243072936","72.23261521698794"],
            ["40.60851748984008","72.23464437030721"],
            ["40.60844814110901","72.2346641827401"],
            ["40.60785566075553","72.23505198168414"],
            ["40.60748764315337","72.23562947862916"],
            ["40.60696199949059","72.23643679111707"],
            ["40.60649828371692","72.23668954247921"],
            ["40.60567231322493","72.23706799427869"],
            ["40.60541308591174","72.23722200507298"],
            ["40.60482051816138","72.23758367245573"],
            ["40.604305647546","72.23784215986362"],
            ["40.60401124130012","72.23803579780193"],
            ["40.60337948666231","72.23844807779469"],
            ["40.60280363639016","72.23880380259529"],
            ["40.60229732880431","72.23937030782993"],
            ["40.60191314349382","72.23992102778467"],
            ["40.60129084776197","72.24090255395117"],
            ["40.60039238920972","72.24246445598082"],
            ["40.59960811058655","72.24346782508449"],
            ["40.59927391912853","72.24396669362834"],
            ["40.59846004931399","72.2452529940965"],
            ["40.59759604044787","72.24649556150277"],
            ["40.59587138458605","72.248042634058"],
            ["40.59434945192393","72.2489300595406"],
            ["40.59402946060414","72.24910811369095"],
            ["40.59343365015318","72.24952362628609"],
            ["40.59270408743765","72.25006245298822"],
            ["40.59229625189079","72.25041869141791"],
            ["40.59101171514104","72.25144798419535"],
            ["40.59008931262738","72.25205831692548"],
            ["40.58684850741335","72.25478805249713"],
            ["40.58562898980981","72.25525744897291"],
            ["40.58461213474544","72.25583195891558"],
            ["40.58329249075285","72.25733708407934"],
            ["40.58246742707122","72.25862749343356"],
            ["40.58139963947675","72.25987771208439"],
            ["40.58085883275059","72.26037493083089"],
            ["40.57979090687319","72.26122040522409"],
            ["40.57850564526204","72.26224514580628"],
            ["40.57711218368615","72.26354923895781"],
            ["40.57686372936993","72.26375432364156"],
            ["40.57652539249494","72.26403656943233"],
            ["40.57628643056255","72.26440116217543"],
            ["40.57547432253331","72.26584438061327"],
            ["40.57510160543907","72.26674263322312"],
            ["40.57303263160234","72.26923948047384"],
            ["40.5710132588634","72.27138494193034"],
            ["40.56936493190833","72.27326809112243"],
            ["40.56822500791452","72.27485909801764"],
            ["40.56746127653266","72.27565796406769"],
            ["40.56718259367518","72.27592177837981"],
            ["40.56691138760598","72.27625968850877"],
            ["40.56655022704451","72.27676724256048"],
            ["40.56630690780418","72.27707877265578"],
            ["40.56589441542869","72.27765776576437"],
            ["40.56432965393443","72.27951000950814"],
            ["40.5639048022059","72.28010363328801"],
            ["40.56274654441117","72.2816627989903"],
            ["40.56152624415925","72.28420407886843"],
            ["40.56150256024402","72.28614990369893"],
            ["40.5615140585342","72.28835151097226"],
            ["40.55959024437809","72.28990593858278"],
            ["40.55899123018371","72.29067655370066"],
            ["40.55555477733955","72.29447680274606"],
            ["40.55168015464895","72.29871201725106"],
            ["40.54788410877438","72.30260601522535"],
            ["40.54291094696754","72.31000831612792"],
            ["40.53733741710411","72.31774877270787"],
            ["40.53420273577176","72.32226864678429"],
            ["40.53053616911895","72.32764343378673"],
            ["40.52748167806587","72.33210772623107"],
            ["40.52611818621381","72.33419573416403"],
            ["40.52489484912067","72.33601182566947"],
            ["40.52333157418728","72.33838250818688"],
        ]},
    {latlngs: [
            ["40.76493671858132","72.24469203079046"],
            ["40.76482887615216","72.24494830124411"],
            ["40.76424072886771","72.24566014384347"],
            ["40.76315002423573","72.24683545763041"],
            ["40.76285331046971","72.24868655744211"],
            ["40.76126297032852","72.25884040578931"],
            ["40.76015406945787","72.26606382265098"],
            ["40.75945715960698","72.27026968879792"],
            ["40.76030342858288","72.2755086686322"],
            ["40.76155282298226","72.27972306851188"],
            ["40.76151710935847","72.28018102093432"],
            ["40.75839596931922","72.28764349770775"],
            ["40.75831435562854","72.2882719882238"],
        ]},
    {latlngs: [
            ["40.77706959244389","72.19314950534671"],
            ["40.77758715155965","72.19300628210048"],
            ["40.78143951738897","72.19170957463457"],
            ["40.78319805318666","72.19149389229511"],
            ["40.78458724219209","72.19146390281911"],
            ["40.78527716772243","72.19154129044192"],
            ["40.78801625201663","72.19199113223274"],
            ["40.78918605390097","72.19261287156574"],
            ["40.79325706614084","72.1950591640431"],
            ["40.79555418667915","72.19646081560299"],
            ["40.79925748803599","72.19798262732054"],
            ["40.79969302925875","72.19799249724223"],
            ["40.80025186996937","72.19792164898045"],
            ["40.80050179350359","72.19777318476373"],
            ["40.80080178766039","72.19756361867422"],
            ["40.8017389769393","72.19659724541675"],
            ["40.80194398879018","72.19627994422869"],
            ["40.80219090198619","72.19606970413093"],
            ["40.80256859696415","72.19586474101536"],
            ["40.80373951796999","72.19487620226288"],
            ["40.80657095761907","72.19253578111116"],
            ["40.80807096982186","72.1914490517218"],
            ["40.8089590488737","72.19111511867054"],
            ["40.80982036122917","72.19107868352053"],
            ["40.81212627328227","72.19140647897062"],
            ["40.81296107577452","72.19196117059046"],
            ["40.81335772724277","72.19227016085173"],
            ["40.81453724263731","72.19309656876973"],
            ["40.81584034097055","72.19393607525848"],
            ["40.8175020955786","72.19513395244537"],
            ["40.81809394308245","72.19585536552687"],
            ["40.81797213908074","72.19652483793486"],
            ["40.81806189586631","72.19732859359706"],
            ["40.81873968250617","72.198803320707"],
            ["40.81971264698867","72.19864139883398"],
            ["40.82075068263052","72.19840561848937"],
            ["40.82201324158299","72.19819953665645"],
            ["40.82325725636974","72.19796584558705"],
            ["40.82428853147788","72.19781688240721"],
            ["40.82437065733046","72.1978176035506"],
            ["40.8254453683093","72.19745864813363"],
        ]},
    {latlngs: [
            ["40.8187379959183","72.19881232278584"],
            ["40.81841044625217","72.19898765656265"],
            ["40.81850985352109","72.20065007704447"],
            ["40.81876511572842","72.2007337925947"],
            ["40.81939337177492","72.20095431566342"],
            ["40.81950095593231","72.20137203284813"],
            ["40.81969191851449","72.2030945153351"],
            ["40.81981337304664","72.20408781685055"],
            ["40.81952374464854","72.20470188118509"],
            ["40.81864323954201","72.20657646288986"],
            ["40.81817748898797","72.20751544085356"],
            ["40.81819625397568","72.20809559622049"],
            ["40.81849599513263","72.20881029853716"],
            ["40.81875793818557","72.20952233282327"],
            ["40.82015892239158","72.2134824050304"],
            ["40.82064403978664","72.21478548928516"],
            ["40.82077798667802","72.21491583443395"],
            ["40.8219600747946","72.21616604966425"],
            ["40.82417170128872","72.21846679770363"],
            ["40.8266269843951","72.22115222704301"],
            ["40.82786871149354","72.22325979378819"],
            ["40.82794072875181","72.22438120897685"],
            ["40.82786487859428","72.22539024609124"],
            ["40.82812588454165","72.22920439668286"],
            ["40.82780889983114","72.23219913418984"],
            ["40.8275616193566","72.23420323114021"],
            ["40.82761285442935","72.23514340405239"],
            ["40.82782344159107","72.23709352270741"],
            ["40.82801099240926","72.24026899316186"],
            ["40.82806023414828","72.24158972148767"],
            ["40.82843989515175","72.24366683230527"],
            ["40.82862116174156","72.24571306027293"],
            ["40.82888500868712","72.24851229714821"],
            ["40.82900898309271","72.25002117624624"],
            ["40.82923308328162","72.25222830322258"],
            ["40.82941540168687","72.25434765501946"],
            ["40.82966989883559","72.25674203057196"],
            ["40.83031314876339","72.25931245740517"],
            ["40.83105466624058","72.26144525618236"],
            ["40.83226255525567","72.26495025021133"],
            ["40.83401056726564","72.26995142113073"],
            ["40.8350890036966","72.27347955889867"],
            ["40.83615718026803","72.27716993766386"],
            ["40.83693260569976","72.27967006263383"],
            ["40.83704134750937","72.28322411030906"],
            ["40.83703940201207","72.28492301498771"],
            ["40.83703910806868","72.28591228298281"],
            ["40.83708408081223","72.28636614394554"],
            ["40.83713436010842","72.28674477705249"],
            ["40.8373048261945","72.287331916212"],
            ["40.83739674903502","72.28752906788401"],
            ["40.8376546301927","72.2879321924073"],
            ["40.8381445987574","72.28847957310595"],
            ["40.83892330456658","72.28895275846439"],
            ["40.83888594172202","72.28900001534615"],
            ["40.83864253325019","72.28918116523407"],
            ["40.83851866526297","72.2892722962996"],
            ["40.83835583294832","72.28940059182571"],
            ["40.83821705192055","72.28967158773338"],
            ["40.83745029754916","72.29135962557093"],
            ["40.83630436340089","72.29385602040716"],
            ["40.83614739456799","72.29412951118735"],
            ["40.83589110963894","72.29467594596058"],
            ["40.83581051801178","72.29490721551214"],
            ["40.8363692825283","72.29563825662635"],
            ["40.83658644293165","72.29585950389678"],
        ]},
    {latlngs: [
            ["40.83892096561171","72.28895045787974"],
            ["40.83900307543657","72.28892093272036"],
            ["40.83917681662022","72.28888666594784"],
            ["40.84028772238435","72.28869078517971"],
            ["40.84271871702155","72.2879519278694"],
            ["40.84863566967336","72.28667855946182"],
            ["40.84886105316563","72.2867134077468"],
            ["40.85244333786989","72.28650282022156"],
            ["40.85381223997184","72.28678367910193"],
            ["40.85455270408303","72.28732097387537"],
            ["40.85663723348487","72.2907475875209"],
            ["40.86155108249228","72.2953050789578"],
            ["40.86823713030973","72.29762444452916"],
            ["40.86874534557953","72.29823031290019"],
            ["40.87247256773875","72.30282532286797"],
            ["40.87406451670358","72.30481327033989"],
            ["40.875340695514","72.30623989141472"],
            ["40.87598291983258","72.30674036257962"],
            ["40.87767891061493","72.30781093782241"],
            ["40.88089784683584","72.30976842639704"],
            ["40.88243409080386","72.31062803109674"],
            ["40.88438698574105","72.31125059967431"],
            ["40.88632350834926","72.31143038606547"],
            ["40.88997396915656","72.31105750924823"],
            ["40.89399994064587","72.3104297301104"],
            ["40.89505442422197","72.31017803121833"],
            ["40.89691349041495","72.30981409801109"],
            ["40.89869975021262","72.30939663768217"],
            ["40.90233834879393","72.30874275425859"],
            ["40.90466800657374","72.30841413294299"],
            ["40.90597351823924","72.30822651531351"],
            ["40.90674782483674","72.30799801285777"],
            ["40.90716589219939","72.30788109095178"],
            ["40.90837454556674","72.3091591982826"],
            ["40.90889731820412","72.30949197960059"],
            ["40.91195047498138","72.31145638648344"],
            ["40.9137560270679","72.31259389959919"],
            ["40.91703361435935","72.31468871451156"],
            ["40.91903350641613","72.31593729654514"],
            ["40.91912717582227","72.31641633872484"],
            ["40.91961496166391","72.3185885348141"],
            ["40.91991684377987","72.31968402590921"],
            ["40.920128181875","72.32030703147542"],
            ["40.92029811466251","72.32045399618374"],
            ["40.92085576144517","72.3205452551845"],
            ["40.92631369220478","72.32372995779205"],
            ["40.92805117389322","72.32469004210202"],
            ["40.93164565395435","72.32500772157825"],
            ["40.93333309638287","72.3257214969363"],
            ["40.93374539969119","72.32596913829035"],
            ["40.93442533381914","72.32634598932498"],
            ["40.93630042708973","72.32730314592675"],
            ["40.93767945245936","72.32807331010378"],
            ["40.93910029224524","72.3287764930471"],
            ["40.94209793576839","72.33031964616801"],
            ["40.9440484974899","72.33136124131882"],
            ["40.94651406642701","72.332548950998"],
            ["40.95079680617963","72.33471973531911"],
            ["40.95424242044632","72.3361983212139"],
            ["40.95439276226093","72.33661459853245"],
            ["40.95459158354527","72.33762980432253"],
            ["40.95501570621185","72.3379473968424"],
            ["40.95630871669061","72.33742982404793"],
            ["40.95987687532008","72.33656439894129"],
            ["40.96012078562104","72.33666070510296"],
            ["40.96254430941464","72.33736570425799"],
            ["40.96398194205705","72.33782787066377"],
            ["40.96609548984247","72.33844457015022"],
            ["40.96793838400169","72.33906278118562"],
            ["40.97196328073777","72.33985128257326"],
            ["40.9725779813485","72.33988735262814"],
            ["40.97400255878458","72.34045614104478"],
            ["40.97593482936263","72.34094751182737"],
            ["40.97741825682358","72.34135763593665"],
            ["40.98113758608802","72.34235383409585"],
            ["40.98452651264468","72.34333421016235"],
            ["40.98549235243746","72.34460301837416"],
            ["40.98756147502784","72.34876310421978"],
            ["40.98906630320042","72.35029912669626"],
            ["40.99134103459124","72.35127183475463"],
            ["40.99260518370537","72.35155921706443"],
            ["40.99563124313401","72.35195999816"],
            ["40.99721713910397","72.3522019890041"],
            ["41.00081800402301","72.35270071662769"],
            ["41.00248096212297","72.35296479867799"],
            ["41.00621719963699","72.35343788070978"],
            ["41.00947183441849","72.35297359953167"],
            ["41.01182182574325","72.35309410722824"],
            ["41.01298289886146","72.35433392970542"],
            ["41.01408260979433","72.3566207285066"],
            ["41.01490383115327","72.35803975715257"],
            ["41.01627763761859","72.3591417286694"],
            ["41.01724548150548","72.35949495066185"],
            ["41.0185927736924","72.36244985600879"],
            ["41.01934041527254","72.361974111818"],
            ["41.02108332824705","72.36088990076307"],
            ["41.02347786900838","72.35944294346716"],
            ["41.02573591514478","72.35812357777354"],
            ["41.02634167096887","72.35772915596867"],
            ["41.02754917844822","72.3569488741493"],
        ]},
    {latlngs: [
            ["40.79236824216507","72.02264388004605"],
            ["40.79176212485278","72.0227569567934"],
            ["40.78537883120946","72.02217515510583"],
            ["40.77778876290043","72.02796322327946"],
            ["40.77293548179344","72.02857619479188"],
            ["40.7695067966127","72.02951839983959"],
            ["40.76533560382361","72.03013940401098"],
            ["40.7607664815484","72.03291381175842"],
            ["40.75797514105648","72.03412478047046"],
            ["40.75551612348789","72.03491008862926"],
            ["40.75444030380199","72.03336411345637"],
        ]},
    {latlngs: [
            ["40.79052656014469","72.07298880643449"],
            ["40.79309461721075","72.07276932143613"],
            ["40.79386068576559","72.07264063669609"],
            ["40.79637477571401","72.07319149641127"],
            ["40.80080114493099","72.07322269203125"],
            ["40.80271765857519","72.07364900394703"],
            ["40.80453412575763","72.07398775468364"],
        ]},
    {latlngs: [
            ["40.69521800776218","72.25773620379817"],
            ["40.6953952694773","72.25808490662439"],
            ["40.69545711000028","72.25825588367711"],
            ["40.69595181247354","72.25889063171216"],
        ]},
    {latlngs: [
            ["40.71499392475992","72.24052816423119"],
            ["40.71529085182926","72.24105258827858"],
            ["40.71574788374279","72.24405999660475"],
        ]},
    {latlngs: [
            ["40.7494027404934","72.24230261661204"],
            ["40.74922525542959","72.24094289203369"],
        ]},
    {latlngs: [
            ["40.77624900520161","72.18511175494096"],
            ["40.77452021165861","72.18581598485306"],
        ]},
    {latlngs: [
            ["40.87394270203936","72.30467646303777"],
            ["40.87009612288664","72.3056744431136"],
        ]},
    {latlngs: [
            ["40.89505311831118","72.31014171698385"],
            ["40.89580176786243","72.30848417641089"],
            ["40.89637250572238","72.30720579824556"],
        ]},
    {latlngs: [
            ["40.69773927125615","72.3571321307105"],
            ["40.70181753071201","72.35805866026634"],
        ]},
    {latlngs: [
            ["40.68786805151415","72.30682793612797"],
            ["40.687303178733","72.30738362488891"],
            ["40.68668055049525","72.30817861657036"],
        ]},
    {latlngs: [
            ["40.71304783255732","72.4514239027974"],
            ["40.71212164056898","72.45237396656806"],
        ]},
    {latlngs: [
            ["40.71301546945674","72.45142351595307"],
            ["40.71379000905664","72.45010051463456"],
        ]},
    {latlngs: [
            ["40.71389544271685","72.45237731914821"],
            ["40.71429849640197","72.45291862282356"],
            ["40.71469625822677","72.45388884952891"],
            ["40.71455491865134","72.45449604743897"],
        ]},
    {latlngs: [
            ["40.64583034338494","72.53730853672835"],
            ["40.6457291558918","72.53664167010585"],
            ["40.64521890368236","72.5354831739577"],
            ["40.64496349368185","72.53496008673804"],
        ]},
    {latlngs: [
            ["40.62435490101337","72.62692087183015"],
            ["40.62582284433207","72.62761849994119"],
        ]},
    {latlngs: [
            ["40.96792415969068","71.24955582151223"],
            ["40.96764441009678","71.25895906699874"],
            ["40.96786054428095","71.26560365752249"],
            ["40.96737020777246","71.27615179981285"],
            ["40.96753681873292","71.28238315050609"],
            ["40.96746229023744","71.28406582794901"],
            ["40.96834416966636","71.28912075204387"],
            ["40.96873300772503","71.29375339343142"],
            ["40.9687778083204","71.29865823618256"],
            ["40.96926751093084","71.30293666540803"],
            ["40.96968918609458","71.30835574958158"],
            ["40.969784364761","71.31041326022473"],
            ["40.96863822242625","71.31446645521828"],
            ["40.9678399513107","71.31654127382096"],
            ["40.96662770823661","71.3195586205765"],
            ["40.96503180690912","71.32385279095524"],
            ["40.96429682626091","71.32660107322179"],
            ["40.96335130485309","71.3302503026359"],
            ["40.96263595939669","71.33285040175852"],
            ["40.96204719899507","71.33506554307961"],
            ["40.96096001206396","71.33903940690045"],
            ["40.96103874542879","71.34155988687267"],
            ["40.96096585513954","71.34349081955274"],
            ["40.96097927119163","71.37983247197116"],
            ["40.9634202958167","71.40231653324017"],
        ]},
    {latlngs: [
            ["40.96341931154619","71.40232227161566"],
            ["40.96877784614231","71.4066434371681"],
            ["40.97001151553306","71.41116633934519"],
            ["40.97496197034114","71.41950239903018"],
            ["40.98019642517237","71.42785407370485"],
            ["40.9852124968287","71.43902599718687"],
            ["40.98639842043909","71.44411752698571"],
            ["40.98670729386809","71.44650587354526"],
            ["40.98916298953975","71.455088981868"],
            ["40.99078056895426","71.45638275704123"],
            ["40.99261777230825","71.4604507226654"],
            ["40.99442942887244","71.46393197944498"],
            ["40.99651231586679","71.46732404988182"],
            ["41.00244361459006","71.4758794123363"],
            ["41.01366601099404","71.48711499308284"],
            ["41.02032353350152","71.4985625899068"],
            ["41.02190809014661","71.5016933386639"],
            ["41.02320337282325","71.5061753449363"],
            ["41.0240278969348","71.50856418244682"],
            ["41.0252645797695","71.51764033337636"],
            ["41.02646994397297","71.52687311018649"],
            ["41.02655441121637","71.52823704202316"],
            ["41.02799036804726","71.54345522994011"],
            ["41.03026299044945","71.54568117159569"],
            ["41.03185721231412","71.55252031270034"],
            ["41.03081448526949","71.5552645927521"],
            ["41.03025782491017","71.56079111001917"],
            ["41.03009413498582","71.5674682676497"],
            ["41.02867447484984","71.57894516754695"],
            ["41.02783619241048","71.58465948416142"],
            ["41.0270502901782","71.58852805900013"],
            ["41.01888135246348","71.59604259878155"],
        ]},
    {latlngs: [
            ["40.7322560509026","70.84033043761653"],
            ["40.73407443106514","70.84079701016417"],
            ["40.73784717988192","70.84082789074846"],
            ["40.7395130296637","70.84587515806527"],
            ["40.74315702031072","70.85641256779444"],
            ["40.75059153079599","70.8786417009355"],
            ["40.75272259235903","70.88348312441151"],
            ["40.75925149262195","70.87898186077068"],
            ["40.76569234570064","70.8741007270629"],
            ["40.77185998342966","70.87127195565361"],
            ["40.77621175457718","70.8756058267448"],
            ["40.77958356319654","70.8780341143859"],
            ["40.78043422078276","70.87802888836805"],
            ["40.78140366381074","70.87886538014497"],
            ["40.7821901100768","70.87841418088207"],
            ["40.78425750753124","70.87498200846036"],
            ["40.7871815531612","70.87616802701885"],
            ["40.81190433808309","70.89595136294399"],
            ["40.81456482217388","70.90014341981608"],
            ["40.81703932648868","70.90953374469727"],
            ["40.81800941250273","70.91387764399676"],
            ["40.82115615399379","70.91875016080235"],
            ["40.82688162991119","70.92232488286713"],
            ["40.84351562302962","70.9512864396164"],
            ["40.85127299286957","70.98325951349111"],
            ["40.85586046475076","70.98259430174471"],
            ["40.87251074370408","71.03611423478999"],
            ["40.88970602342287","71.06774737008085"],
            ["40.89637492515603","71.07842716864744"],
            ["40.90473764905813","71.09840889869743"],
            ["40.9083244511448","71.11050058839335"],
            ["40.91817206695914","71.13789031688532"],
            ["40.92218387012888","71.14884376160632"],
            ["40.92385503745584","71.15079683923595"],
            ["40.92587498277375","71.1532115336931"],
            ["40.92479625254862","71.1592672810247"],
            ["40.92528805229719","71.16458599513543"],
            ["40.92607614555939","71.1668055059547"],
            ["40.92784682273923","71.16926814421549"],
            ["40.92877361134139","71.17079159905457"],
            ["40.93135531500339","71.17469461863909"],
            ["40.93696322466725","71.18250987191605"],
            ["40.94253282961641","71.19054559196483"],
            ["40.94810773359542","71.19764479610176"],
            ["40.94460952517201","71.20121416437709"],
            ["40.94253881487065","71.20889528708504"],
            ["40.94116014632232","71.21853230242286"],
            ["40.96572760923949","71.23133415716454"],
            ["40.96607513834261","71.23182152169214"],
            ["40.96609574297933","71.23287859085185"],
            ["40.96638965211859","71.23532250835392"],
            ["40.96668325876477","71.24292977191146"],
            ["40.96794292303172","71.24817712596345"],
            ["40.96793907946504","71.24865581547992"],
            ["40.96792415969068","71.24955582151223"],
        ]},
    {latlngs: [
            ["40.94823003541152","71.19777135742014"],
            ["40.95205072666589","71.20468490545838"],
            ["40.96618557841357","71.2215791323034"],
            ["40.9654884660252","71.22616479141401"],
            ["40.96583672788903","71.23138792912968"],
        ]},
    {latlngs: [
            ["40.96344295520328","71.40252480155266"],
            ["40.96351926881292","71.40355211866553"],
            ["40.96400975187863","71.40976864688183"],
            ["40.96385130217016","71.41390587355615"],
            ["40.96403986858248","71.41476227117531"],
            ["40.96509328252934","71.41586632417228"],
            ["40.97283017303395","71.41626509817121"],
            ["40.97312643490421","71.4164539383069"],
        ]},
    {latlngs: [
            ["40.99477249415517","71.46459522266149"],
            ["40.99265898335081","71.46532039030782"],
            ["40.98365324294301","71.47279187881136"],
            ["40.97644121006679","71.47820480405417"],
            ["40.97236514008266","71.48454167166135"],
            ["40.97277962850027","71.49062043834098"],
            ["40.96527942635486","71.50021604350356"],
            ["40.95996593717902","71.51254174016552"],
            ["40.96026322811107","71.51827173637017"],
            ["40.94787871524118","71.52633882500373"],
            ["40.9420952722222","71.53305972568714"],
            ["40.94144666939021","71.53329528107525"],
            ["40.93373261643607","71.54915014939559"],
            ["40.9242053325761","71.55603246741923"],
            ["40.9148138565625","71.56126355759473"],
            ["40.91234494121179","71.57289118660037"],
            ["40.90810517128578","71.5808012493255"],
            ["40.9002376982188","71.58592715635221"],
            ["40.89617316740925","71.58484999725758"],
            ["40.89414298162941","71.58425933902409"],
            ["40.88866569037444","71.58327337420205"],
            ["40.88328659641485","71.58226053947753"],
            ["40.882652278357","71.58248460129848"],
            ["40.88276659480248","71.58323531407461"],
            ["40.88124435666303","71.58386594694311"],
            ["40.88041996254018","71.58412633900927"],
            ["40.85970799317658","71.58573808515447"],
            ["40.85342033971529","71.58757748610643"],
            ["40.85342286362323","71.58999472808578"],
        ]},
    {latlngs: [
            ["41.03116083343465","71.55411274024915"],
            ["41.02483673106562","71.55484435252829"],
            ["41.01570445366228","71.55432654605724"],
            ["41.01559781972286","71.55432654715634"],
            ["41.01205648043405","71.55412403159151"],
            ["41.00899983881376","71.55395359084169"],
            ["41.0077126944471","71.55425893386511"],
            ["41.0067450889533","71.55421753963422"],
            ["40.99962611609185","71.55451406517533"],
            ["40.99177831656991","71.5512992707914"],
            ["40.99206726546312","71.54093228913528"],
            ["40.99077882681318","71.53745878857059"],
            ["40.98948919207545","71.53527026704688"],
            ["40.98208540129362","71.52943016970112"],
            ["40.97528143599195","71.52696904360153"],
            ["40.97146744645615","71.52436647694067"],
            ["40.96036892621864","71.51837587818163"],
        ]},
    {latlngs: [
            ["40.96261866978598","71.21705248647136"],
            ["40.97248824007853","71.20723119875279"],
            ["41.00561805003494","71.1844919712383"],
            ["41.01872986414351","71.18418436825661"],
            ["41.03894463340116","71.20934248947218"],
            ["41.05803272302223","71.20699359390953"],
        ]},
    {latlngs: [
            ["40.96601113611552","71.22297953839472"],
            ["40.97567505475597","71.2295010366648"],
        ]},
    {latlngs: [
            ["41.03177629110425","71.55189694544445"],
            ["41.03412937289289","71.55296541625869"],
            ["41.03448784858053","71.55295817628273"],
            ["41.07147955479493","71.57617407756578"],
            ["41.0821914579868","71.59426759805913"],
            ["41.12826898355281","71.65457045382541"],
            ["41.18159009709599","71.65929610307116"],
            ["41.2194806083531","71.65989677945288"],
            ["41.27245233768571","71.67335218240824"],
        ]},
    {latlngs: [
            ["41.26117603312432","71.67047060136643"],
            ["41.26009280130059","71.67023179267997"],
            ["41.26389037558526","71.60113581927176"],
        ]},
    {latlngs: [
            ["40.90004910274327","71.5859028895884"],
            ["40.90003917078662","71.58691518612122"],
            ["40.91356693436049","71.60850657570072"],
            ["40.92772299965137","71.65090133486019"],
            ["40.93498411703775","71.67346323921124"],
            ["40.94061783927243","71.68085821442128"],
            ["40.94619724084973","71.68643295312755"],
            ["40.95843478845195","71.69621292923438"],
            ["40.96161190154532","71.6999726750431"],
            ["40.96739771331787","71.70511573493879"],
        ]},
    {latlngs: [
            ["40.92856225193469","71.6535502344766"],
            ["40.95548697544657","71.63717186260975"],
        ]},
    {latlngs: [
            ["40.89722555701393","71.93539173204911"],
            ["40.89817917891092","71.93584833606874"],
            ["40.90696840430751","71.9439930004055"],
            ["40.91074298362521","71.94342264461629"],
            ["40.91380383475757","71.94633560470054"],
            ["40.94321352342739","71.94321139030765"],
            ["40.9470385459495","71.94753837222997"],
            ["40.95365479871241","71.96307893494384"],
            ["40.96401368572909","71.97053282992766"],
            ["40.97652321122926","71.97921164893525"],
            ["40.97718384576633","71.99361140081633"],
            ["40.98888721692207","72.0109447574266"],
            ["40.995690050412","72.02317757378346"],
        ]},
    {latlngs: [
            ["41.03032943548755","71.87982177723381"],
            ["40.99612679686685","71.93235115848111"],
            ["40.99384874450723","71.93793636954935"],
            ["40.98329876370178","71.95965308705503"],
            ["40.98022877539182","71.962234546036"],
            ["40.97596464795632","71.966802731755"],
            ["40.97230257087772","71.97630284500974"],
        ]},
    {latlngs: [
            ["40.90687978709026","71.10564059375579"],
            ["40.89908178235834","71.10964749041527"],
            ["40.89509783693691","71.11187597108866"],
            ["40.8943769133508","71.11172270744754"],
        ]},
    {latlngs: [
            ["40.81390277184633","70.89911059061959"],
            ["40.81230131407003","70.90091432764324"],
        ]},
    {latlngs: [
            ["40.96976913589894","71.31014982095842"],
            ["40.97056063070266","71.31008936573561"],
        ]},
    {latlngs: [
            ["40.96090718565986","71.37866192675916"],
            ["40.95268180006047","71.37847763400531"],
        ]},
    {latlngs: [
            ["40.97450777185558","71.41878310673327"],
            ["40.97339914139778","71.42028260681894"],
            ["40.97248394227547","71.42367769182222"],
        ]},
    {latlngs: [
            ["40.99480655189499","71.46457507473581"],
            ["40.99679443485325","71.4642104254863"],
        ]},
    {latlngs: [
            ["41.01156025904051","71.48501288844272"],
            ["41.0037529375721","71.49168721204686"],
            ["41.00388729101784","71.49249964513012"],
        ]},
    {latlngs: [
            ["41.00733650139786","71.55424097628025"],
            ["41.0073101144983","71.55537011974744"],
        ]},
    {latlngs: [
            ["41.03180433014219","71.55261257821083"],
            ["41.02971896211132","71.55193474266304"],
            ["41.0296431347772","71.55169983405655"],
        ]},
    {latlngs: [
            ["40.99199073023194","71.54334293586987"],
            ["40.9933352229997","71.54327459399487"],
        ]},
    {latlngs: [
            ["41.07171103302763","71.57651995701193"],
            ["41.07477681320311","71.57435318300347"],
        ]},
    {latlngs: [
            ["41.21503511168405","71.65980183206914"],
            ["41.21502088619656","71.66317454173426"],
        ]},
    {latlngs: [
            ["40.94121657173916","71.533715369361"],
            ["40.94285748109077","71.53650225397442"],
        ]},
    {latlngs: [
            ["40.91385764256319","71.56578249115822"],
            ["40.91538891481737","71.57066129470834"],
        ]},
    {latlngs: [
            ["40.94454866570643","71.68478840454686"],
            ["40.94350025773998","71.68854145417387"],
        ]},
    {latlngs: [
            ["40.99848284319207","71.928787247985"],
            ["41.00058025993547","71.93084040059588"],
        ]},
    {latlngs: [
            ["40.94096796347744","71.94347459082849"],
            ["40.94182383069256","71.94714118185958"],
        ]},
    {latlngs: [
            ["40.94316520455577","71.94321727624263"],
            ["40.94382362948107","71.93424984337506"],
        ]}
]

export default {
    polylines_c
}
