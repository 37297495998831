<template>
  <div style="height: 100%">
    <CRow style="height: 100%">
      <CCol lg="12" style="height: 100%">
        <l-map style="height: 100%" :zoom="zoom" :center="center">
          <l-tile-layer :url="url"></l-tile-layer>
<!--          <Vue2LeafletMarkerCluster>-->
            <l-marker v-for="(item, index) in markers" :key="index + Math.random()" :lat-lng="item.markerLatLng">
              <l-tooltip :options="{ permanent: true, interactive: true, direction: 'top' }">
                <div @click="innerClick" class="">
                  <b>{{item.name}}</b>
                  <h5 class="mb-0 mt-1" v-show="showParagraph">
                    {{item.value}}
                  </h5>
                </div>
              </l-tooltip>
            </l-marker>
<!--          </Vue2LeafletMarkerCluster>-->
          <l-polyline v-for="(polyline, index) in polylines" :key="index"
            :lat-lngs="polyline.latlngs"
          />
        </l-map>
      </CCol>
    </CRow>
  </div>
</template>

<script>

    import { Icon } from 'leaflet';
    import polylines_c from './polylines';

    delete Icon.Default.prototype._getIconUrl;
    Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    });
    import { LMap, LMarker, LTileLayer, LPolyline, LTooltip } from 'vue2-leaflet';
    // import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';
    import 'leaflet/dist/leaflet.css';
    import axios from "axios";

    export default {
        name: "Map",
        components: {
            LMap,
            LMarker,
            // LPopup,
            LTileLayer,
            LPolyline,
            LTooltip,
            // Vue2LeafletMarkerCluster
        },
        data () {
            return {
                url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                zoom: 11,
                center: [40.381675, 71.640187],
                markers: [],
                polylines: polylines_c.polylines_c,
                showParagraph: true,
                timer: '',
            };
        },
        created () {
            this.fetchData();
            this.timer = setInterval(this.fetchData, 900000);
        },
        methods: {
            innerClick() {
                console.log('log')
            },
            fetchData() {
                this.markers.length = 0;
                // Получаем нитки, привязанные к областям
                axios
                    .get(`${axios.defaults.baseURL}?action=get_markers`)
                    .then((response) => {
                        response.data.forEach((el) => {
                            let elva = el.value === null ? '0' : el.value;
                            this.markers.push({
                                name: el.name,
                                value: elva,
                                markerLatLng: el.s_cord
                            });
                        })
                    })

            },

            cancelAutoUpdate () {
                clearInterval(this.timer);
            },
        },
        beforeDestroy() {
            this.cancelAutoUpdate();
        },
    }
</script>
<style>
  @import "~leaflet.markercluster/dist/MarkerCluster.css";
  @import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
  .c-main > .container-fluid {
    height: 100%;
    padding: 0;
  }
  .c-main {
    padding: 0;
  }
  .leaflet-tooltip p {
    margin-bottom: 0;
  }
</style>
